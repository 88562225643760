import { Card } from "react-bootstrap";
import { TeacherCurator } from "../../../models/teacher-curator";
import styles from "../../../styles/TeacherCuratorsGrid.module.css";

interface ProjectTeacherCuratorCardPreloadedProps {
    teacherCurator: TeacherCurator,
    onTeacherCuratorClicked: (value:string) => void,
    onFinish: (b:boolean)=> void,
    className?: string,
}

const ProjectTeacherCuratorCardPreloaded = ({teacherCurator, onTeacherCuratorClicked, onFinish, className}: ProjectTeacherCuratorCardPreloadedProps) => {
    return(
        <Card 
            className={`${styles.teacherCuratorsModule} ${className}`}
            onClick={(e) => {
                onTeacherCuratorClicked(teacherCurator._id);
                onFinish(true);
            }}
            >
            <Card.Body>
                <Card.Text>
                    {teacherCurator.fullName}
                </Card.Text>
                <Card.Text>
                    {"Должность: "+teacherCurator.position}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default ProjectTeacherCuratorCardPreloaded;