import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PersonalTask } from "../../models/personal-task";
import * as NotificationsApi from "../../network/notifications-api";
import * as PersonalTaskFilesApi from "../../network/personal-task-files-api";
import * as PersonalTasksApi from "../../network/personal-tasks-api";
import styles from "../../styles/ChangeStatusDialog.module.css";

interface ChangeStausDialogProps{
    task: PersonalTask,
    newStatus: string,
    onStatusUpdated: (task: PersonalTask) => void
}

const PersonalTaskChangeStatusDialog = ({task, newStatus, onStatusUpdated}: ChangeStausDialogProps) => {
    interface changeStatusFormProps{
        taskId: string,
        status: string,
        statusDescription: string,
        statusFile: File[],
    }
    const { register, handleSubmit, formState : {errors, isSubmitting} } = useForm<changeStatusFormProps>({
        defaultValues: {
            taskId: task._id,
            status: newStatus,
            statusDescription: "",
        }
    });

    async function onSubmit(input:changeStatusFormProps) {
        try {
            await PersonalTaskFilesApi.postPersonalTaskFile({taskId: input.taskId, file: input.statusFile[0]});
            const updatedTask = await PersonalTasksApi.updatePersonalTaskStatus(input.taskId, {status: input.status, statusDescription: input.statusDescription});
            if (input.status === "Нужна помощь") {
                const notification = {
                    administratorId: task.administratorId, 
                    type: "32",
                    userId: task.userId,
                }
                await NotificationsApi.createNotification(notification);
            } else if (input.status === "Готово") {
                const notification = {
                    administratorId: task.administratorId, 
                    type: "33",
                    userId: task.userId,
                }
                await NotificationsApi.createNotification(notification);
            }
            onStatusUpdated(updatedTask);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return(
        <Modal.Footer className={styles.changeStatusDialog}>
            <Form id="changeStatus" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                <h1>Изменение статуса</h1>
                <Form.Group className="mb-3" controlId="statusDescription-input">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3} 
                        placeholder="Описание"
                        {...register("statusDescription")} 
                        isInvalid={!!errors.statusDescription}
                    />
                </Form.Group>
                <Form.Group controlId="statusFile-input">
                    <Form.Label>
                        Файл
                    </Form.Label>
                    <FormControl 
                        type="file"
                        multiple={false}
                        {...register("statusFile")} 
                        isInvalid={!!errors.statusFile}
                    />
                </Form.Group>
            </Form>
            <Button 
            type="submit"
            form="changeStatus"
            disabled={isSubmitting}>
                Подтвердить
            </Button>
        </Modal.Footer>
    );
}

export default PersonalTaskChangeStatusDialog;