import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ProjectTask as ProjectTaskModel } from "../../../models/project-task";
import * as ProjectTasksApi from "../../../network/project-tasks-api";
import styles from "../../../styles/AllProjectTasksPage.module.css";
import stylesUtils from "../../../styles/utils.module.css";
import AddSeveralProjectTasksDialog from "./AddSeveralProjectTasksDialog";
import TaskFromProject from "./TaskFromProject";
import TaskFromProjectInfo from "./TaskFromProjectInfo";

interface AllProjectTasksPageViewProps {
    role: string,
}

const AllProjectTasksPageView = ({role}: AllProjectTasksPageViewProps) => {
    const [projectTasks, setProjectTasks] = useState<ProjectTaskModel[]>([]);
    const [tasksLoading, setTasksLoading] = useState(true);
    const [showTasksLoadingError, setShowTasksLoadingError] = useState(false);

    const [showAddProjectTaskDialog, setShowAddProjectTaskDialog] = useState(false);

    const [taskToShow, setTaskToShow] = useState<ProjectTaskModel|null>(null);

    useEffect(() => {
        async function loadTasks() {
          try {
            setShowTasksLoadingError(false);
            setTasksLoading(true);
            const projectTasksData = await ProjectTasksApi.fetchProjectTasksByUser({role});
            setProjectTasks(projectTasksData);
          } catch (error) {
            console.error(error);
            setShowTasksLoadingError(true);
          } finally {
            setTasksLoading(false);
          }
        }
        loadTasks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let navigate = useNavigate();

    const projectTasksGrid = 
        <Row xs={1} md={2} xl={2} className={`g-4 ${styles.tasksGrid}`}>
          {projectTasks.map(projectTask => (
            <Col key={projectTask._id}>
              <TaskFromProject
                role={role}
                task={projectTask} 
                className={styles.task}
                onTaskClicked={setTaskToShow}
                />
            </Col>
          ))}
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                  className={`mb-1`}
                  onClick={() => navigate(-1)}
                  >
                  Назад
              </Button>
            </div>
            <h1>Задачи из проектов</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={() => setShowAddProjectTaskDialog(true)}>
                <FaPlus />
                Добавить задачу
            </Button>
            {tasksLoading && <Spinner animation='border' variant='primary' />}
            {showTasksLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!tasksLoading && !showTasksLoadingError && 
                <>
                    {
                    projectTasks.length > 0
                        ? projectTasksGrid
                        : <p>Задачи ещё не добавлены</p>
                    } 
                </>
            }
            {showAddProjectTaskDialog &&
            <AddSeveralProjectTasksDialog
              role={role}
              onDismiss={() => setShowAddProjectTaskDialog(false)}
              onTasksSaved={(newProjectTasks) => {
                setProjectTasks([...projectTasks, ...newProjectTasks]);
                setShowAddProjectTaskDialog(false);
            }}
            />}
            { (taskToShow) &&
                <TaskFromProjectInfo
                    projectTask={taskToShow}
                    role={role}
                    onDismiss={() => setTaskToShow(null)}
                />
            }
        </>
    );
}

export default AllProjectTasksPageView