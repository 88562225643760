import { Container } from "react-bootstrap";
import NotFoundPage from "./NotFoundPage";
import { Administrator } from "../models/administrator";
import styles from "../styles/NotificationsPage.module.css";
import NotificationsPageView from "../components/AdministratorsComponents/NotificationsPageView";

interface AdministratorNotificationsPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorNotificationsPage = ({loggedInAdministrator}: AdministratorNotificationsPageProps) => {
    return (
        <Container className={styles.notificationsPage}>
            <>
            { loggedInAdministrator
                ? <NotificationsPageView role={loggedInAdministrator.role}/>
                : <NotFoundPage/>
            }
            </>
        </Container>
    );
}

export default AdministratorNotificationsPage