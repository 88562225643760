import { ProjectTaskFile } from "../models/project-task-file";
import { fetchFile, fetchData } from "../utils/fetchData";

interface fetchProjectTaskFileProps{
    taskId: string,
}

export async function fetchProjectTaskFile(requestProps: fetchProjectTaskFileProps){
    await fetchFile("/api/project-task-files/"+requestProps.taskId, { method: "GET" });
}

interface postProjectTaskFileProps{
    taskId: string,
    file: File,
}

export async function postProjectTaskFile(requestProps: postProjectTaskFileProps): Promise<ProjectTaskFile> {
    const formData = new FormData();
    formData.append("taskId", requestProps.taskId);
    formData.append("projecttaskfile", requestProps.file);
    const response = await fetchData("/api/project-task-files/",
    {
        method: "POST",
        body: formData,
    });
    return response.json();
}