import { Card } from "react-bootstrap";
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import { Project } from "../../../models/project";

interface ProjectCardProps{
    project: Project,
    chosenProjects: Project[],
    onProjectClicked: (project: Project) => void,
    className?: string,
}

const ProjectCard = ({project, chosenProjects, onProjectClicked, className}: ProjectCardProps) => {
    return (
        <Card onClick={() => {onProjectClicked(project)}}>
            <Card.Body className={className}>
                {project.title}
                {(chosenProjects.includes(project))? 
                    <FaRegCheckSquare/>
                    :
                    <FaRegSquare/>}
            </Card.Body>
        </Card>
    );
}

export default ProjectCard