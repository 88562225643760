import { Container } from "react-bootstrap";
import NotificationsPageView from "../components/ObserverCuratorsComponents/NotificationsPageView";
import { ObserverCurator } from "../models/observer-curator";
import styles from "../styles/NotificationsPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface ObserverCuratorNotificationsPageProps {
    loggedInObserverCurator: ObserverCurator | any,
}

const ObserverCuratorNotificationsPage = ({loggedInObserverCurator}: ObserverCuratorNotificationsPageProps) => {
    return (
        <Container className={styles.notificationsPage}>
            <>
            { loggedInObserverCurator
                ? <NotificationsPageView role={loggedInObserverCurator.role}/>
                : <NotFoundPage/>
            }
            </>
        </Container>
    );
}

export default ObserverCuratorNotificationsPage;