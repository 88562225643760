import { PersonalTaskFile } from "../models/personal-task-file";
import { fetchFile, fetchData } from "../utils/fetchData";

interface fetchPersonallTaskFileProps{
    taskId: string,
}

export async function fetchPersonalTaskFile(requestProps: fetchPersonallTaskFileProps){
    await fetchFile("/api/personal-task-files/"+requestProps.taskId, { method: "GET" });
}

interface postPersonallTaskFileProps{
    taskId: string,
    file: File,
}

export async function postPersonalTaskFile(requestProps: postPersonallTaskFileProps): Promise<PersonalTaskFile> {
    const formData = new FormData();
    formData.append("taskId", requestProps.taskId);
    formData.append("personaltaskfile", requestProps.file);
    const response = await fetchData("/api/personal-task-files/",
    {
        method: "POST",
        body: formData,
    });
    return response.json();
}
