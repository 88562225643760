import { useEffect, useState } from "react";
import * as ProjectsApi from "../../network/projects-api";
import * as TeacherCuratorApi from "../../network/teacher-curator-api";
import * as ObserverCuratorApi from "../../network/observer-curator-api"
import { Project } from "../../models/project";
import { Button, Card, Spinner } from "react-bootstrap";
import styles from "../../styles/ProjectInfoPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import { useNavigate } from "react-router-dom";
import { TeacherCurator } from "../../models/teacher-curator";
import { ObserverCurator } from "../../models/observer-curator";

interface ProjectPageViewProps {
    role: string,
    projectId: string,
}

const ProjectPageView = ({role, projectId}: ProjectPageViewProps) => {
  const [project, setProject] = useState<Project|null>(null);
  const [teacherCurator, setTeacherCurator] = useState<TeacherCurator|null>(null);
  const [univerCurator, setUniverCurator] = useState<ObserverCurator|null>(null);
  const [roivCurator, setRoivCurator] = useState<ObserverCurator|null>(null);
  const [regionCurator, setRegionCurator] = useState<ObserverCurator|null>(null);
  const [projectLoading, setProjectsLoading] = useState(true);
  const [showProjectLoadingError, setShowProjectsLoadingError] = useState(false);

  let navigate = useNavigate();

  const goToTasks = () => {
    let path = `tasks`;
    navigate(path);
  };

  const goToStages = () => {
    let path = `stages`;
    navigate(path);
  }

  useEffect(() => {
      async function loadProject()
      {
          try {
              setShowProjectsLoadingError(false);
              setProjectsLoading(true);
              const projectData = await ProjectsApi.fetchProject({role: role, projectId: projectId});
              const teacherCuratorData = await TeacherCuratorApi.getTeacherCurator(projectData.teacherCuratorId);
              const univerCuratorData = await ObserverCuratorApi.getObserverCurator(projectData.univerObserverCuratorId);
              const roivCuratorData = await ObserverCuratorApi.getObserverCurator(projectData.roivObserverCuratorId);
              const regionCuratorData = await ObserverCuratorApi.getObserverCurator(projectData.regionObserverCuratorId);
              setProject(projectData);
              setTeacherCurator(teacherCuratorData);
              setUniverCurator(univerCuratorData);
              setRoivCurator(roivCuratorData);
              setRegionCurator(regionCuratorData);
            } catch (error) {
              console.error(error);
              setShowProjectsLoadingError(true);
            } finally {
              setProjectsLoading(false);
            }
      }
      loadProject();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return(
      <>
        <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                  className={`mb-1`}
                  onClick={() => navigate(-1)}
                  >
                  Назад
              </Button>
          </div>
        {projectLoading && <Spinner animation='border' variant='primary' />}
        {showProjectLoadingError && <p>Something went wrong. Please refresh the page.</p>}
        {!projectLoading && !showProjectLoadingError && 
          <>
            <h1 className="mb-3">
              {project?.title}
            </h1>
            <Button className={`mb-3 ${stylesUtils.flexSizeButton}`} onClick={goToStages}>
              Этапы
            </Button>
            <div>
              <Card className={`mb-3 ${stylesUtils.flexTextCard}  ${stylesUtils.textarea}`}>
                <Card.Body>{project?.text}</Card.Body>
              </Card>
            </div>
              <Button className={`mb-3 ${stylesUtils.flexSizeButton}`} onClick={goToTasks}>
                Задачи
              </Button>
            <h1 className="mb-3">Педагог-куратор</h1>
            <div className="mb-3">
              <Card className={`${styles.curatorField}`}>
                <Card.Body>
                  <Card.Text className={styles.curatorNameField}>
                        <div>Имя:</div>
                        <div>{teacherCurator?.fullName}</div>
                  </Card.Text>
                  <Card.Text className={styles.curatorNameField}>
                        <div>Должность:</div>
                        <div>{teacherCurator?.position}</div>
                  </Card.Text>
                  <Card.Text className={styles.curatorPhoneField}>
                    <div>Телефон:</div>
                    <a href={`tel:${teacherCurator?.phone}`}>{teacherCurator?.phone}</a>
                  </Card.Text>
                  <Card.Text className={styles.curatorEmailField}>
                      <div>Почта:</div>
                      <a href={`mailto:${teacherCurator?.email}`}>{teacherCurator?.email}</a>
                  </Card.Text>
                  <Card.Text className={styles.curatorEmailField}>
                      <div>Телеграм:</div>
                      <a href={`http://t.me/${teacherCurator?.telegram}`} target="_blank" rel="noreferrer">{teacherCurator?.telegram}</a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <h1 className="mb-3">Куратор ВУЗ/ССУЗ</h1>
            <div className="mb-3">
              <Card className={`${styles.curatorField}`}>
                <Card.Body>
                  <Card.Text className={styles.curatorNameField}>
                        <div>Имя:</div>
                        <div>{univerCurator?.fullName}</div>
                  </Card.Text>
                  <Card.Text className={styles.curatorNameField}>
                        <div>Должность:</div>
                        <div>{univerCurator?.position}</div>
                  </Card.Text>
                  <Card.Text className={styles.curatorPhoneField}>
                    <div>Телефон:</div>
                    <a href={`tel:${univerCurator?.phone}`}>{univerCurator?.phone}</a>
                  </Card.Text>
                  <Card.Text className={styles.curatorEmailField}>
                      <div>Почта:</div>
                      <a href={`mailto:${univerCurator?.email}`}>{univerCurator?.email}</a>
                  </Card.Text>
                  <Card.Text className={styles.curatorEmailField}>
                      <div>Телеграм:</div>
                      <a href={`http://t.me/${univerCurator?.telegram}`} target="_blank" rel="noreferrer">{univerCurator?.telegram}</a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <h1 className="mb-3">Куратор РОИВ</h1>
            <div className="mb-3">
              <Card className={`${styles.curatorField}`}>
                <Card.Body>
                  <Card.Text className={styles.curatorNameField}>
                        <div>Имя:</div>
                        <div>{roivCurator?.fullName}</div>
                  </Card.Text>
                  <Card.Text className={styles.curatorNameField}>
                        <div>Должность:</div>
                        <div>{roivCurator?.position}</div>
                  </Card.Text>
                  <Card.Text className={styles.curatorPhoneField}>
                    <div>Телефон:</div>
                    <a href={`tel:${roivCurator?.phone}`}>{roivCurator?.phone}</a>
                  </Card.Text>
                  <Card.Text className={styles.curatorEmailField}>
                      <div>Почта:</div>
                      <a href={`mailto:${roivCurator?.email}`}>{roivCurator?.email}</a>
                  </Card.Text>
                  <Card.Text className={styles.curatorEmailField}>
                      <div>Телеграм:</div>
                      <a href={`http://t.me/${roivCurator?.telegram}`} target="_blank" rel="noreferrer">{roivCurator?.telegram}</a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <h1 className="mb-3">Куратор региона</h1>
            <div className="mb-3">
              <Card className={`${styles.curatorField}`}>
                <Card.Body>
                  <Card.Text className={styles.curatorNameField}>
                        <div>Имя:</div>
                        <div>{regionCurator?.fullName}</div>
                  </Card.Text>
                  <Card.Text className={styles.curatorNameField}>
                        <div>Должность:</div>
                        <div>{regionCurator?.position}</div>
                  </Card.Text>
                  <Card.Text className={styles.curatorPhoneField}>
                    <div>Телефон:</div>
                    <a href={`tel:${regionCurator?.phone}`}>{regionCurator?.phone}</a>
                  </Card.Text>
                  <Card.Text className={styles.curatorEmailField}>
                      <div>Почта:</div>
                      <a href={`mailto:${regionCurator?.email}`}>{regionCurator?.email}</a>
                  </Card.Text>
                  <Card.Text className={styles.curatorEmailField}>
                      <div>Телеграм:</div>
                      <a href={`http://t.me/${regionCurator?.telegram}`} target="_blank" rel="noreferrer">{regionCurator?.telegram}</a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </>
        }
      </>
  );
}

export default ProjectPageView;