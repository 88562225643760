import { Container } from "react-bootstrap";
import NotificationsPageView from "../components/TeacherCuratorsComponents/NotificationsPageView";
import { TeacherCurator } from "../models/teacher-curator";
import styles from "../styles/NotificationsPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface TeacherCuratorNotificationsPageProps {
    loggedInTeacherCurator: TeacherCurator | any,
}

const TeacherCuratorNotificationsPage = ({loggedInTeacherCurator}: TeacherCuratorNotificationsPageProps) => {
    return (
        <Container className={styles.notificationsPage}>
            <>
            { loggedInTeacherCurator
                ? <NotificationsPageView role={loggedInTeacherCurator.role}/>
                : <NotFoundPage/>
            }
            </>
        </Container>
    );
}

export default TeacherCuratorNotificationsPage