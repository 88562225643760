import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Administrator } from "../models/administrator";
import * as AdministratorsApi from "../network/administrators-api";

interface NavBarLoggedInViewProps {
    user: Administrator,
    onLogoutSuccessful: () => void,
}

const NavBarLoggedInView = ({ user, onLogoutSuccessful}: NavBarLoggedInViewProps) => {
    let navigate = useNavigate();
    const routeChange = (path: string) =>{
        navigate(path);
    }
    //TODO make redirect to /role/cabinet
    const goHome = () => {
        navigate(user.role + "/cabinet")
    }
    
    async function logout() {
        try {
            await AdministratorsApi.logout();
            onLogoutSuccessful();
            routeChange("/");
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }
    
    return (
        <>
            <Button onClick={goHome}>
                Главная
            </Button>
            <Button onClick={logout}>
                Выход
            </Button>
        </>
    );
}

export default NavBarLoggedInView;
