import { Button, Container } from "react-bootstrap";
import styles from "../styles/AuthPage.module.css";
import stylesUtils from "../styles/utils.module.css";
import { Link } from "react-router-dom";

const AuthPage = () => {
    return (
        <Container className={styles.authPage}>
            <Link to="/administrator">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Авторизация администратора
                </Button>
            </Link>
            <Link to="/teacher-curator">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}`}>
                    Авторизация педагога-куратора
                </Button>
            </Link>
            <Link to="/observer-curator">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}`}>
                    Авторизация куратора-наблюдателя
                </Button>
            </Link>
        </Container>
    )
}

export default AuthPage;