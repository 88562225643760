import { Card, Modal, Spinner } from "react-bootstrap";
import { ProjectTask } from "../../../models/project-task";
import { formatDate } from "../../../utils/formatDate";
import styles from "../../../styles/ProjectTaskInfo.module.css"
import StatusCard from "../StatusCard";
import { useEffect, useState } from "react";
import { Project } from "../../../models/project";
import * as ProjectsApi from "../../../network/projects-api"

interface ProjectTaskInfoProps {
    projectTask: ProjectTask,
    role: string,
    onDismiss: () => void,
}

const TaskFromProjectInfo = ({projectTask, role, onDismiss}: ProjectTaskInfoProps) => {
    const [project, setProject] = useState<Project|null>(null);
    const [projectLoading, setProjectLoading] = useState(true);
    const [showProjectLoadingError, setShowProjectLoadingError] = useState(false);
    
    useEffect(() => {
        async function loadProject() {
            try {
                setShowProjectLoadingError(false);
            setProjectLoading(true);
            const projectId = projectTask.projectId;
            const projectTasksData = await ProjectsApi.fetchProject({role, projectId});
            setProject(projectTasksData);
            } catch (error) {
            console.error(error);
            setShowProjectLoadingError(true);
            } finally {
                setProjectLoading(false);
            }
        }
        loadProject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    return (
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {projectTask?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card className={`mb-3 ${styles.TaskDescription}`}>
                    <p>{projectTask?.text}</p>
                </Card>
                <Card className={`mb-3 ${styles.TaskStatus}`}>
                    <div>{"Статус:"}</div>
                    <StatusCard status={projectTask.status}/>
                </Card>
                <Card className={`mb-3 ${styles.TaskDeadline}`}>
                    <div>{"Дедлайн:"}</div>
                    <div>{formatDate(projectTask.deadline)}</div>
                </Card>
                {projectLoading && <Spinner animation='border' variant='primary' />}
                {showProjectLoadingError && <p>Something went wrong. Please refresh the page.</p>}
                {!projectLoading && !showProjectLoadingError && 
                    <Card className={`mb-3 ${styles.TaskDeadline}`}>
                        {"Проект: " + project?.title}
                    </Card>
                }
            </Modal.Body>
        </Modal>
    );
}

export default TaskFromProjectInfo;