import { Button, Card, Modal } from "react-bootstrap";
import { ProjectThirdStage } from "../../models/project-third-stage";
import * as ProjectThirdStageApi from "../../network/project-third-stages-api";
import styles from "../../styles/ChangeStageDialog.module.css";

interface ThirdStageDialogProps {
    stage: ProjectThirdStage,
    onDismiss: () => void,
}

const ThirdStageDialog = ({stage, onDismiss}: ThirdStageDialogProps) => {
    const downloadFile = () => {
        ProjectThirdStageApi.fetchProjectThirdStageFile({projectId: stage.projectId});
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Этап 3
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card className={`mb-3 ${styles.descriptionField}`}>
                    <Card.Header>
                        Описание
                    </Card.Header>
                    <Card.Body>
                        {stage.text}
                    </Card.Body>
                </Card>
                <Button onClick={downloadFile} className={styles.downloadFileButton}>
                    Скачать закреплённый документ
                </Button>
            </Modal.Body>
        </Modal>
    );
}

export default ThirdStageDialog;