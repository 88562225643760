import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Project as ProjectModel } from "../../models/project";
import { ObserverCurator } from "../../models/observer-curator";
import * as ProjectsApi from "../../network/projects-api";
import styles from "../../styles/ProjectsPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import Project from "./Project";

interface ObserverCuratorProjectsPageViewProps {
  observerCurator: ObserverCurator,
}

const ObserverCuratorProjectsPageView = ({observerCurator}: ObserverCuratorProjectsPageViewProps) => {
    const [projects, setProjects] = useState<ProjectModel[]>([]);
    const [projectsLoading, setProjectsLoading] = useState(true);
    const [showProjectsLoadingError, setShowProjectsLoadingError] = useState(false);

    useEffect(() => {
        async function loadProjects() {
          try {
            setShowProjectsLoadingError(false);
            setProjectsLoading(true);
            const role = observerCurator.role;
            const projectsData = await ProjectsApi.fetchProjects({role});
            setProjects(projectsData);
          } catch (error) {
            console.error(error);
            setShowProjectsLoadingError(true);
          } finally {
            setProjectsLoading(false);
          }
        }
        loadProjects();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let navigate = useNavigate();
    const goToProject = (project: ProjectModel) =>{
        let path = `${project._id}`;
        navigate(path);
        }

    const projectsGrid = 
        <Row xs={1} md={2} xl={3} className={`g-4 ${styles.projectsGrid}`}>
          {projects.map(project => (
            <Col key={project._id}>
              <Project
                project={project} 
                className={styles.project}
                onProjectClicked={goToProject}
                />
            </Col>
          ))}
        </Row>

    return (
        <> 
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Проекты</h1>
            {projectsLoading && <Spinner animation='border' variant='primary' />}
            {showProjectsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!projectsLoading && !showProjectsLoadingError && 
                <>
                    {
                    projects.length > 0
                        ? projectsGrid
                        : <p>Проекты ещё не добавлены</p>
                    }  
                </>
            }
        </>
    );
}

export default ObserverCuratorProjectsPageView;