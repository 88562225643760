import { Container } from "react-bootstrap";
import TeacherCuratorProjectsPageView from "../components/TeacherCuratorsComponents/TeacherCuratorProjectsPageView";
import { TeacherCurator } from "../models/teacher-curator";
import styles from "../styles/ProjectsPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface TeacherCuratorProjectsPageProps {
    loggedInTeacherCurator: TeacherCurator | any | null,
}

const TeacherCuratorProjectsPage = ({ loggedInTeacherCurator }: TeacherCuratorProjectsPageProps) => {
  return (
        <Container className={styles.projectsPage}>
        <>
          { loggedInTeacherCurator
              ? <TeacherCuratorProjectsPageView teacherCurator={loggedInTeacherCurator}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default TeacherCuratorProjectsPage