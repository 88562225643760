import { Container } from "react-bootstrap";
import { Administrator } from "../models/administrator";
import styles from "../styles/ProjectsPage.module.css";
import NotFoundPage from "./NotFoundPage";
import PersonalTasksPageView from "../components/AdministratorsComponents/PersonalTasksPageView";

interface AdministratorPersonalTasksPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorPersonalTasksPage = ({ loggedInAdministrator }: AdministratorPersonalTasksPageProps) => {
  return (
        <Container className={styles.projectsPage}>
        <>
          { loggedInAdministrator
              ? <PersonalTasksPageView role={loggedInAdministrator.role}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default AdministratorPersonalTasksPage;