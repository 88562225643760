import { Card } from "react-bootstrap";
import styles from "../../styles/StatusCard.module.css";

interface StatusCardProps {
    status: string,
}

const StatusCard = ({status}: StatusCardProps) => {
    
    return (
        <>
            {status==="В работе" &&
            <Card className={styles.InWorkStatus}>
                {status}
            </Card>
            }
            {status==="Нужна помощь" &&
            <Card className={styles.NeedHelpStatus}>
                {status}
            </Card>
            }
            {status==="Готово" &&
            <Card className={styles.DoneStatus}>
                {status}
            </Card>
            }
        </>
    );
}

export default StatusCard;