import { Container } from "react-bootstrap";
import PersonalTasksPageView from "../components/ObserverCuratorsComponents/PersonalTasksPageView";
import { ObserverCurator } from "../models/observer-curator";
import styles from "../styles/ProjectsPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface ObserverCuratorPersonalTasksPageProps {
  loggedInObserverCurator: ObserverCurator | any | null,
}

const ObserverCuratorPersonalTasksPage = ({ loggedInObserverCurator }: ObserverCuratorPersonalTasksPageProps) => {
  return (
        <Container className={styles.projectsPage}>
        <>
          { loggedInObserverCurator
              ? <PersonalTasksPageView role={loggedInObserverCurator.role}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default ObserverCuratorPersonalTasksPage;