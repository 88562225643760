import { useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { ProjectFifthStage } from "../../models/project-fifth-stage";
import * as ProjectFifthStageApi from "../../network/project-fifth-stages-api";
import styles from "../../styles/ChangeStageDialog.module.css";
import { useForm } from "react-hook-form";
import * as NotificationsApi from "../../network/notifications-api";
import * as ProjectsApi from "../../network/projects-api";

interface ChangeFifthStageDialogProps {
    stage: ProjectFifthStage,
    onDismiss: () => void,
    onStageSaved: (stage: ProjectFifthStage) => void,
}

const ChangeFifthStateDialog = ({stage, onDismiss, onStageSaved}: ChangeFifthStageDialogProps) => {
    const [currentStage, setCurrentStage] = useState<ProjectFifthStage>(stage);
    const [newStatus, setNewStatus] = useState("");
    const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);

    const downloadFile = () => {
        ProjectFifthStageApi.fetchProjectFifthStageFile({projectId: currentStage.projectId});
    }

    interface changeStatusFormProps{
        projectId: string,
        text: string,
        file: File[],
    }
    const { register, handleSubmit, formState : {errors, isSubmitting} } = useForm<changeStatusFormProps>({
        defaultValues: {
            projectId: stage.projectId,
            text: "",
        }
    });

    async function onSubmit(input:changeStatusFormProps) {
        try {
            const updatedStage = await ProjectFifthStageApi.postProjectFifthStage({projectId: input.projectId, status: newStatus, text: input.text, file: input.file[0]});
            const projectData = await ProjectsApi.fetchProject({role: "administrator", projectId: stage.projectId});
            if (projectData) {
                const notification = {
                    administratorId: projectData.administratorId, 
                    type: "21",
                    projectId: projectData._id,
                    univerObserverCuratorId: projectData.univerObserverCuratorId,
                    roivObserverCuratorId: projectData.roivObserverCuratorId,
                    regionObserverCuratorId: projectData.regionObserverCuratorId,
                }
                await NotificationsApi.createNotification(notification);
            }
            setCurrentStage(updatedStage);
            onStageSaved(updatedStage);
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            setShowChangeStatusDialog(false);
        }
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Этап 5
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card className={`mb-3 ${styles.descriptionField}`}>
                    <Card.Header>
                        Описание
                    </Card.Header>
                    <Card.Body>
                        {currentStage.text}
                    </Card.Body>
                </Card>
                <Button onClick={downloadFile} className={styles.downloadFileButton}>
                    Скачать закреплённый документ
                </Button>
            </Modal.Body>
            {currentStage.status === "NeedRework" && !showChangeStatusDialog &&
                <Modal.Footer className={styles.stageFooter}>
                    <Button onClick={() => {setNewStatus("NeedCheck"); setShowChangeStatusDialog(true);}} className="mb-3">
                        Повторная отправка
                    </Button>
                </Modal.Footer>
            }
            { showChangeStatusDialog &&
                <Modal.Footer className={styles.stageFooter}>
                    <Form id="changeFifthStageStatus" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                        <h1>Повторная отправка</h1>
                        <Form.Group className="mb-3" controlId="text-input">
                            <Form.Label>Описание</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                placeholder="Описание"
                                {...register("text")} 
                                isInvalid={!!errors.text}
                            />
                        </Form.Group>
                        <Form.Group controlId="file-input">
                            <Form.Label>
                                Файл
                            </Form.Label>
                            <Form.Control 
                                type="file"
                                multiple={false}
                                {...register("file")} 
                                isInvalid={!!errors.file}
                            />
                        </Form.Group>
                    </Form>
                    <Button 
                    type="submit"
                    form="changeFifthStageStatus"
                    disabled={isSubmitting}>
                        Отправить
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    );
}

export default ChangeFifthStateDialog;