import { Project } from "../models/project";
import { fetchData } from "../utils/fetchData";

interface RequestProps {
    role: string,
    regionId?:string,
}

export async function fetchProjects(requestProps: RequestProps): Promise<Project[]> {
    const response = await fetchData("/api/projects/all", 
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}

interface fetchProjectProps {
    role: string,
    projectId: string,
}

export async function fetchProject(requestProps: fetchProjectProps): Promise<Project> {
    const response = await fetchData("/api/projects/"+requestProps.projectId, 
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}

export interface ProjectInput {
    title: string,
    text: string,
    regionId: string,
    teacherCuratorId: string,
    univerObserverCuratorId: string,
    roivObserverCuratorId: string,
    regionObserverCuratorId: string,
}

export async function createProject(project: ProjectInput): Promise<Project> {
    const response = await fetchData("/api/projects",
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(project),
    });
    return response.json();
}

export async function updateProject(projectId:string, project: ProjectInput): Promise<Project> {
    const response = await fetchData("/api/projects/" + projectId,
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(project),
    })
    return response.json();
}

export async function deleteProject(projectId:string) {
    await fetchData("/api/projects/"+ projectId, { method: "DELETE"});
}