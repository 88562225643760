import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PersonalTask as PersonalTaskModel } from "../../models/personal-task";
import * as PersonalTasksApi from "../../network/personal-tasks-api";
import styles from "../../styles/PersonalTasksPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import PersonalTaskCard from "./PersonalTaskCard";
import PersonalTaskInfo from "./PersonalTaskInfo";

interface PersonalTasksPageViewProps {
    role: string,
}

const PersonalTasksPageView = ({role}: PersonalTasksPageViewProps) => {
    const [personalTasks, setPersonalTasks] = useState<PersonalTaskModel[]>([]);
    const [personalTasksLoading, setPersonalTasksLoading] = useState(true);
    const [showPersonalTasksLoadingError, setShowPersonalTasksLoadingError] = useState(false);

    const [personalTaskToShow, setPersonalTaskToShow] = useState<PersonalTaskModel|null>(null);

    useEffect(() => {
        async function loadTasks() {
          try {
            setShowPersonalTasksLoadingError(false);
            setPersonalTasksLoading(true);
            const PersonalTasksData = await PersonalTasksApi.fetchPersonalTasks({role});
            setPersonalTasks(PersonalTasksData);
          } catch (error) {
            console.error(error);
            setShowPersonalTasksLoadingError(true);
          } finally {
            setPersonalTasksLoading(false);
          }
        }
        loadTasks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let navigate = useNavigate();

    const PersonalTasksGrid = 
        <Row xs={1} md={2} xl={2} className={`g-4 ${styles.tasksGrid}`}>
          {personalTasks.map(PersonalTask => (
            <Col key={PersonalTask._id}>
              <PersonalTaskCard
                task={PersonalTask} 
                className={styles.task}
                onTaskClicked={setPersonalTaskToShow}
                />
            </Col>
          ))}
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                  className={`mb-1`}
                  onClick={() => navigate(-1)}
                  >
                  Назад
              </Button>
            </div>
            <h1>Персональные задачи</h1>
            {personalTasksLoading && <Spinner animation='border' variant='primary' />}
            {showPersonalTasksLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!personalTasksLoading && !showPersonalTasksLoadingError && 
                <>
                    {
                    personalTasks.length > 0
                        ? PersonalTasksGrid
                        : <p>Задачи ещё не добавлены</p>
                    } 
                </>
            }
            { (personalTaskToShow) &&
                <PersonalTaskInfo
                    personalTask={personalTaskToShow}
                    role={role}
                    onDismiss={(updatedTask) => { setPersonalTaskToShow(null); setPersonalTasks(personalTasks.map(existingTask => existingTask._id === updatedTask._id ? updatedTask : existingTask)); }}
                />
            }
        </>
    );
}

export default PersonalTasksPageView;