import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { ObserverCurator } from "../../../models/observer-curator";
import * as ObserverCuratorsApi from "../../../network/observer-curator-api";

interface CuratorCardProps {
    ObserverCuratorId: string,
    updateForm?: () => void,
}

const ObserverCuratorCard = ({ObserverCuratorId, updateForm} : CuratorCardProps) => {
    if (updateForm) {
        updateForm();
    }
    const [curatorData, setCuratorData] = useState<ObserverCurator|null>(null)
    const [curatorLoading, setCuratorLoading] = useState(true);
    const [showCuratorLoadingError, setShowCuratorLoadingError] = useState(false);
    useEffect(() => {
        async function loadCurator(ObserverCuratorId: string) {
            try {
                const curator = await ObserverCuratorsApi.getObserverCurator(ObserverCuratorId);
                setCuratorData(curator)
            } catch (error) {
                console.error(error);
                setShowCuratorLoadingError(true);
            } finally {
                setCuratorLoading(false);
            }
        }
        loadCurator(ObserverCuratorId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    return (
        <>
            {curatorLoading &&
                <Card>
                    <Card.Body>
                        Loading
                    </Card.Body>
                </Card>
            }
            {curatorData &&
                <Card>
                    <Card.Body>
                        <Card.Text>
                            {curatorData.fullName}
                        </Card.Text>
                        <Card.Text>
                            {"Должность: "+curatorData.position}
                        </Card.Text>
                    </Card.Body>
                </Card>
            }
            {showCuratorLoadingError &&
                 <Card>
                 <Card.Body>
                     Error
                 </Card.Body>
             </Card>
            }
        </>
    );
}

export default ObserverCuratorCard;