import { Region } from "../models/region";
import { fetchData } from "../utils/fetchData";


export async function fetchRegions(): Promise<Region[]> {
    const response = await fetchData("/api/regions", {method: "GET"});
    return response.json();
}

export interface RegionInput {
    title: string,
}

export async function createRegion(region: RegionInput): Promise<Region> {
    const response = await fetchData("/api/regions",
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(region),
    });
    return response.json();
}

export async function updateRegion(regionId:string, region: RegionInput): Promise<Region> {
    const response = await fetchData("/api/regions/" + regionId,
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(region),
    })
    return response.json();
}

export async function deleteRegion(regionId:string) {
    await fetchData("/api/regions/"+ regionId, { method: "DELETE"});
}