import { Button, Container } from "react-bootstrap";
import styles from "../styles/AuthPage.module.css";
import stylesUtils from "../styles/utils.module.css";
import { Link } from "react-router-dom";

const AdministratorCabinetPage = () => {
    return (
        <Container className={styles.authPage}>
            <Link to="notifications">
                <Button className={`mb-4 ${stylesUtils.flexCenter}` }>
                    Уведомления
                </Button>
            </Link>
            <Link to="regions">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Регионы
                </Button>
            </Link>
            <Link to="project-tasks">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}`}>
                    Задачи из проектов
                </Button>
            </Link>
            <Link to="personal-tasks">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}`}>
                    Персональные задачи
                </Button>
            </Link>
            <Link to="users">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}`}>
                    Пользователи
                </Button>
            </Link>
            <Link to="statistics">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}`}>
                    Статистика
                </Button>
            </Link>
        </Container>
    )
}

export default AdministratorCabinetPage;