import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ObserverCurator } from "../../models/observer-curator";
import * as ObserverCuratorsApi from "../../network/observer-curator-api";
import styles from "../../styles/ObserverCuratorsGrid.module.css";
import stylesUtils from "../../styles/utils.module.css";

const ObserverCuratorsPageView = () => {
    const [observerCurators, setObserverCurators] = useState<ObserverCurator[]>([]);
    const [observerCuratorsToShow, setObserverCuratorsToShow] = useState<ObserverCurator[]>([]);
    const [observerCuratorNameFilter, setObserverCuratorNameFilter] = useState("");
    const [observerCuratorsLoading, setObserverCuratorsLoading] = useState(true);
    const [showObserverCuratorsLoadingError, setShowObserverCuratorsLoadingError] = useState(false);

    useEffect(() => {
        async function loadObserverCurators() {
            try {
                setShowObserverCuratorsLoadingError(false);
                setObserverCuratorsLoading(true);
                const curators = await ObserverCuratorsApi.fetchObserverCurators();
                setObserverCurators(curators);
                setObserverCuratorsToShow(curators);
            } catch (error) {
                console.error(error);
                setShowObserverCuratorsLoadingError(true);
            } finally {
                setObserverCuratorsLoading(false);
            }
            }
            loadObserverCurators();
    }, []);

    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = observerCurators.filter((user) => {
                return user.fullName.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setObserverCuratorsToShow(filteredArray);
        } else {
            setObserverCuratorsToShow(observerCurators);
        }

        setObserverCuratorNameFilter(keyword);
    }

    const ObserverCuratorsGrid = 
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.allObserverCuratorsGrid}`}>
            {observerCuratorsToShow.map(observerCurator => (
            <Col key={observerCurator._id}>
                <Card>
                    <Card.Body>
                        <Card.Text className={styles.curatorNameField}>
                            <div>{observerCurator?.fullName}</div>
                        </Card.Text>
                        <Card.Text className={styles.curatorNameField}>
                                <div>Должность:</div>
                                <div>{observerCurator?.position}</div>
                        </Card.Text>
                        <Card.Text className={styles.curatorPhoneField}>
                            <div>Телефон:</div>
                            <a href={`tel:${observerCurator?.phone}`}>{observerCurator?.phone}</a>
                        </Card.Text>
                        <Card.Text className={styles.curatorEmailField}>
                            <div>Почта:</div>
                            <a href={`mailto:${observerCurator?.email}`}>{observerCurator?.email}</a>
                        </Card.Text>
                        <Card.Text className={styles.curatorEmailField}>
                            <div>Телеграм:</div>
                            <a href={`http://t.me/${observerCurator?.telegram}`} target="_blank" rel="noreferrer">{observerCurator?.telegram}</a>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            ))}
        </Row>

    let navigate = useNavigate();

    return (
        <> 
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Кураторы-наблюдатели</h1>
            {observerCuratorsLoading && <Spinner animation='border' variant='primary' />}
            {showObserverCuratorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!observerCuratorsLoading && !showObserverCuratorsLoadingError && 
            <>
            <div>
                <input
                    type="search"
                    value={observerCuratorNameFilter}
                    onChange={filter}
                    className={styles.search}
                    placeholder="Поиск по ФИО">
                </input>
            </div>
            <div className={styles.gridField}>
                {
                observerCurators.length > 0
                    ? ObserverCuratorsGrid
                    : <p>Вы ещё не добавили кураторов-наблюдателей</p>
                }  
            </div>
            </>
            }

        </>
    );
}

export default ObserverCuratorsPageView;