import { Card } from "react-bootstrap";
import { ObserverCurator } from "../../../models/observer-curator";
import styles from "../../../styles/ObserverCuratorsGrid.module.css";

interface ProjectObserverCuratorCardPreloadedProps {
    ObserverCurator: ObserverCurator,
    onObserverCuratorClicked: (value:string) => void,
    onFinish: (b:boolean)=> void,
    className?: string,
}

const ProjectObserverCuratorCardPreloaded = ({ObserverCurator, onObserverCuratorClicked, onFinish, className}: ProjectObserverCuratorCardPreloadedProps) => {
    return(
        <Card 
            className={`${styles.observerCuratorsModule} ${className}`}
            onClick={(e) => {
                onObserverCuratorClicked(ObserverCurator._id);
                onFinish(true);
            }}
            >
            <Card.Body>
                <Card.Text>
                    {ObserverCurator.fullName}
                </Card.Text>
                <Card.Text>
                    {"Должность: "+ObserverCurator.position}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default ProjectObserverCuratorCardPreloaded;
