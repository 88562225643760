import { LoginCredentials } from "../models/credentials";
import { Administrator } from "../models/administrator";
import { fetchData } from "../utils/fetchData";

export async function getLoggedAdministrator(): Promise<Administrator> {
    const response = await fetchData("/api/administrators", { method: "GET" });
    return response.json();
}

// export interface SignUpCredentials {
//     username: string,
//     email: string,
//     password: string,
// }

// export async function signUp(credentials: SignUpCredentials): Promise<Administrator> {
//     const response = await fetchData("/api/administrators/signup",
//     {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify(credentials),
//     });
//     return response.json();
// }

export async function login(credentials: LoginCredentials): Promise<Administrator> {
    const response = await fetchData("/api/administrators/login",
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(credentials),
    });
    return response.json();
}

export async function logout() {
    await fetchData("/api/administrators/logout", {method: "POST"});
}