import { ProjectFirstStage } from "../models/project-first-stage";
import { fetchFile, fetchData } from "../utils/fetchData";

interface fetchProjectFirstStageProps{
    projectId: string,
}

export async function fetchProjectFirstStage(requestProps: fetchProjectFirstStageProps): Promise<ProjectFirstStage> {
    const response = await fetchData("/api/project-first-stages/"+requestProps.projectId, { method: "GET" });
    return response.json();
}

export async function fetchProjectFirstStageFile(requestProps: fetchProjectFirstStageProps){
    await fetchFile("/api/project-first-stages/"+requestProps.projectId+"/file/", { method: "GET" });
}

interface postProjectFirstStageProps{
    projectId: string,
    status: string,
    text: string,
    file: File,
}

export async function postProjectFirstStage(requestProps: postProjectFirstStageProps): Promise<ProjectFirstStage> {
    const formData = new FormData();
    formData.append("projectId", requestProps.projectId);
    formData.append("status", requestProps.status);
    formData.append("text", requestProps.text);
    formData.append("projectfirststage", requestProps.file);
    const response = await fetchData("/api/project-first-stages/",
    {
        method: "POST",
        body: formData,
    });
    return response.json();
}

interface updateProjectFirstStageStatusProps{
    status: string
}

export async function updateProjectFirstStageStatus(requestProps: updateProjectFirstStageStatusProps, projectId: string): Promise<ProjectFirstStage> {
    const response = await fetchData("/api/project-first-stages/"+projectId,
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}