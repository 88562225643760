import { Notification } from "../models/notification";
import { fetchData } from "../utils/fetchData";

interface RequestProps {
    role: string,
}

export async function fetchNotifications(requestProps: RequestProps): Promise<Notification[]> {
    const response = await fetchData("/api/notifications", 
    {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}

export interface NotificationInput {
    administratorId: string,
    type: string,
    projectId?: string,
    userId?: string,
    teacherCuratorId?: string,
    univerObserverCuratorId?: string,
    roivObserverCuratorId?: string,
    regionObserverCuratorId?: string,
}

export async function createNotification(notification: NotificationInput) {
    await fetchData("/api/notifications",
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(notification),
    });
    return;
}

export async function updateNotificationStatus(noteficationId: string): Promise<Notification> {
    const response = await fetchData("/api/notifications/" + noteficationId,
    {
        method: "PATCH",
    })
    return response.json();
}