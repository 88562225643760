import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ProjectTask } from "../../models/project-task";
import * as ProjectTaskFilesApi from "../../network/project-task-files-api";
import * as ProjectTasksApi from "../../network/project-tasks-api";
import * as NotificationsApi from "../../network/notifications-api";
import styles from "../../styles/ChangeStatusDialog.module.css";

interface ChangeStausDialogProps{
    task: ProjectTask,
    newStatus: string,
    onStatusUpdated: (task: ProjectTask) => void
}

const ProjectTaskChangeStatusDialog = ({task, newStatus, onStatusUpdated}: ChangeStausDialogProps) => {
    interface changeStatusFormProps{
        taskId: string,
        status: string,
        statusDescription: string,
        statusFile: File[],
    }
    const { register, handleSubmit, formState : {errors, isSubmitting} } = useForm<changeStatusFormProps>({
        defaultValues: {
            taskId: task._id,
            status: newStatus,
            statusDescription: "",
        }
    });

    async function onSubmit(input:changeStatusFormProps) {
        try {
            await ProjectTaskFilesApi.postProjectTaskFile({taskId: input.taskId, file: input.statusFile[0]});
            const updatedTask = await ProjectTasksApi.updateProjectTaskStatus(input.taskId, {status: input.status, statusDescription: input.statusDescription});
            if (input.status === "Нужна помощь") {
                const notification = {
                    administratorId: task.administratorId, 
                    type: "12",
                    projectId: task.projectId,
                    univerObserverCuratorId: task.univerObserverCuratorId,
                    roivObserverCuratorId: task.roivObserverCuratorId,
                    regionObserverCuratorId: task.regionObserverCuratorId,
                }
                await NotificationsApi.createNotification(notification);
            } else if (input.status === "Готово") {
                const notification = {
                    administratorId: task.administratorId, 
                    type: "13",
                    projectId: task.projectId,
                    univerObserverCuratorId: task.univerObserverCuratorId,
                    roivObserverCuratorId: task.roivObserverCuratorId,
                    regionObserverCuratorId: task.regionObserverCuratorId,
                }
                await NotificationsApi.createNotification(notification);
            }
            onStatusUpdated(updatedTask);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return(
        <Modal.Footer className={styles.changeStatusDialog}>
            <Form id="changeStatus" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                <h1>Изменение статуса</h1>
                <Form.Group className="mb-3" controlId="statusDescription-input">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3} 
                        placeholder="Описание"
                        {...register("statusDescription")} 
                        isInvalid={!!errors.statusDescription}
                    />
                </Form.Group>
                <Form.Group controlId="statusFile-input">
                    <Form.Label>
                        Файл
                    </Form.Label>
                    <FormControl 
                        type="file"
                        multiple={false}
                        {...register("statusFile")} 
                        isInvalid={!!errors.statusFile}
                    />
                </Form.Group>
            </Form>
            <Button 
            type="submit"
            form="changeStatus"
            disabled={isSubmitting}>
                Подтвердить
            </Button>
        </Modal.Footer>
    );
}

export default ProjectTaskChangeStatusDialog;