import { useEffect, useState } from "react";
import { ObserverCurator } from "../../../models/observer-curator";
import * as ObserverCuratorsApi from "../../../network/observer-curator-api";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import styles from "../../../styles/ObserverCuratorsGrid.module.css";
import ObserverCuratorCardPreloaded from "./ObserverCuratorCardPreloaded";
import { FieldError, RegisterOptions, UseFormSetValue } from "react-hook-form";

interface ObserverCuratorGridProps {
    name: string,
    registerOptions?: RegisterOptions,
    error?: FieldError,
    setValue: UseFormSetValue<any>,
    [x: string]: any,
}

const ObserverCuratorsGrid = ({name, register, registerOptions, error, setValue, ...props}: ObserverCuratorGridProps) => {
    const [observerCurators, setObserverCurators] = useState<ObserverCurator[]>([]);
    const [observerCuratorsToShow, setObserverCuratorsToShow] = useState<ObserverCurator[]>([]);
    const [observerCuratorNameFilter, setObserverCuratorNameFilter] = useState("");
    const [ObserverCuratorsLoading, setObserverCuratorsLoading] = useState(true);
    const [showObserverCuratorsLoadingError, setShowObserverCuratorsLoadingError] = useState(false);

    const [chosenCurators, setChosenCurators] = useState<ObserverCurator[]>([]);

    let chosenCuratorsArray: ObserverCurator[] = [];

    useEffect(() => {
        async function loadObserverCurators() {
            try {
                setShowObserverCuratorsLoadingError(false);
                setObserverCuratorsLoading(true);
                const curators = await ObserverCuratorsApi.fetchObserverCurators();
                setObserverCurators(curators);
                setObserverCuratorsToShow(curators);
            } catch (error) {
                console.error(error);
                setShowObserverCuratorsLoadingError(true);
            } finally {
                setObserverCuratorsLoading(false);
            }
          }
          loadObserverCurators();
    }, []);

    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = observerCurators.filter((user) => {
                return user.fullName.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setObserverCuratorsToShow(filteredArray);
        } else {
            setObserverCuratorsToShow(observerCurators);
        }

        setObserverCuratorNameFilter(keyword);
    }

    const AddPopObserverCuratorId = (curator: ObserverCurator) => {
        try{
            if (chosenCurators.includes(curator)) {
                chosenCuratorsArray = chosenCurators.filter(chosenCurator => chosenCurator._id !== curator._id);
                setChosenCurators(chosenCuratorsArray);
            } else {
                chosenCuratorsArray = chosenCurators.concat(curator);
                setChosenCurators(chosenCuratorsArray);
            }
        } finally {
            setValue(name, chosenCuratorsArray);
        }
    }

    const ObserverCuratorsGrid = 
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.observerCuratorsGrid}`}>
          {observerCuratorsToShow.map(ObserverCurator => (
            <Col key={ObserverCurator._id}>
              <ObserverCuratorCardPreloaded 
                observerCurator={ObserverCurator} 
                className={styles.observerCurator}
                chosenCurators={chosenCurators}
                onObserverCuratorClicked={AddPopObserverCuratorId}
                />
            </Col>
          ))}
        </Row>

    return (
        <>
            {ObserverCuratorsLoading && <Spinner animation='border' variant='primary' />}
                {showObserverCuratorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
                {!ObserverCuratorsLoading && !showObserverCuratorsLoadingError && 
                <div className={styles.observerCuratorsModule}>
                    <Button className={`mb-3 ${styles.chooseAllButton}`} onClick={() => {chosenCuratorsArray = observerCurators; setChosenCurators(chosenCuratorsArray); setValue(name, chosenCuratorsArray)}}>
                        Выбрать все
                    </Button>
                    <input
                        type="search"
                        value={observerCuratorNameFilter}
                        onChange={filter}
                        className={styles.search}
                        placeholder="Поиск по ФИО">
                    </input>
                    {
                    observerCurators.length > 0
                        ? ObserverCuratorsGrid
                        : <p>Вы ещё не добавили кураторов-наблюдателей</p>
                    }  
                </div>
                }
        </>
    );
}

export default ObserverCuratorsGrid;