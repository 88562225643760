import { ProjectSecondStage } from "../models/project-second-stage";
import { fetchFile, fetchData } from "../utils/fetchData";

interface fetchProjectSecondStageProps{
    projectId: string,
}

export async function fetchProjectSecondStage(requestProps: fetchProjectSecondStageProps): Promise<ProjectSecondStage> {
    const response = await fetchData("/api/project-second-stages/"+requestProps.projectId, { method: "GET" });
    return response.json();
}

export async function fetchProjectSecondStageFile(requestProps: fetchProjectSecondStageProps){
    await fetchFile("/api/project-second-stages/"+requestProps.projectId+"/file/", { method: "GET" });
}

interface postProjectSecondStageProps{
    projectId: string,
    status: string,
    text: string,
    file: File,
}

export async function postProjectSecondStage(requestProps: postProjectSecondStageProps): Promise<ProjectSecondStage> {
    const formData = new FormData();
    formData.append("projectId", requestProps.projectId);
    formData.append("status", requestProps.status);
    formData.append("text", requestProps.text);
    formData.append("projectsecondstage", requestProps.file);
    const response = await fetchData("/api/project-second-stages/",
    {
        method: "POST",
        body: formData,
    });
    return response.json();
}

interface updateProjectSecondStageStatusProps{
    status: string
}

export async function updateProjectSecondStageStatus(requestProps: updateProjectSecondStageStatusProps, projectId: string): Promise<ProjectSecondStage> {
    const response = await fetchData("/api/project-second-stages/"+projectId,
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}