import { Container } from "react-bootstrap";
import { Administrator } from "../models/administrator";
import NotFoundPage from "./NotFoundPage";
import styles from "../styles/ProjectsPage.module.css"
import { useParams } from "react-router-dom";
import ProjectTasksPageView from "../components/TeacherCuratorsComponents/ProjectTasksPageView"

interface TeacherCuratorProjectTasksPageProps {
  loggedInTeacherCurator: Administrator | null,
}

const TeacherCuratorProjectTasksPage = ({ loggedInTeacherCurator }: TeacherCuratorProjectTasksPageProps) => {
  const {projectId} = useParams();
  return (
        <Container className={styles.projectsPage}>
        <>
          { loggedInTeacherCurator
              ? <ProjectTasksPageView role={loggedInTeacherCurator.role} projectId={projectId}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default TeacherCuratorProjectTasksPage