import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { FieldError, RegisterOptions, UseFormSetValue } from "react-hook-form";
import styles from "../../../styles/AddEditProject.module.css";
import CuratorCard from "./CuratorCard";
import ObserverCuratorsGrid from "./ObserverCuratorsGrid";
import TeacherCuratorsGrid from "./TeacherCuratorsGrid";


interface ChooseCuratorFieldProps {
    name: string,
    label: string,
    registerOptions?: RegisterOptions,
    error?: FieldError,
    curatorId?: string,
    setValue: UseFormSetValue<any>,
    [x: string]: any,
}

const ChooseCuratorField = ({name, label, registerOptions, error, curatorId, setValue, ...props}: ChooseCuratorFieldProps) => {
    const [showChooseTeacherCuratorsDialog, setShowChooseTeacherCuratorsDialog] = useState(false);
    const [showChooseObserverCuratorsDialog, setShowChooseObserverCuratorsDialog] = useState(false);

    const showChooseObserverCurator = () => {
        setShowChooseTeacherCuratorsDialog(false);
        setShowChooseObserverCuratorsDialog(true);
    }
    const showChooseTeacherCurator = () => {
        setShowChooseTeacherCuratorsDialog(true);
        setShowChooseObserverCuratorsDialog(false);
    }

    return (
        <Form.Group className="mb-3" controlId={name}>
            <Card className={styles.curatorCard}>
                <Card.Footer className={styles.CuratorFooter}>
                    {label}
                </Card.Footer>
                <Card.Header className={styles.curatorCardHeader}>
                    <Button className={styles.curatorButton} onClick={showChooseTeacherCurator}>
                        Педагоги
                    </Button>
                    <Button className={styles.curatorButton} onClick={showChooseObserverCurator}>
                        Наблюдатели
                    </Button>
                </Card.Header>
                <Card.Body>
                    { (curatorId && !showChooseTeacherCuratorsDialog && !showChooseTeacherCuratorsDialog) &&
                        <CuratorCard curatorId={curatorId}/>
                    }
                    { showChooseTeacherCuratorsDialog &&
                        <TeacherCuratorsGrid
                            name={name}
                            registerOptions={registerOptions}
                            setValue={setValue}
                        />
                    }
                    { showChooseObserverCuratorsDialog &&
                        <ObserverCuratorsGrid
                            name={name}
                            registerOptions={registerOptions}
                            setValue={setValue}
                        />
                    }
                </Card.Body>
            </Card>
        </Form.Group>
    );
}

export default ChooseCuratorField;