import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginModal from "../components/LoginModal";
import { UnauthorizedError } from "../errors/http_errors";
import { LoginCredentials } from "../models/credentials";
import { ObserverCurator } from "../models/observer-curator";
import styleUtils from "../styles/utils.module.css";
import * as ObserverCuratorsApi from "../network/observer-curator-api"

interface ObserverCuratorAuthPageProps {
    onLoginSuccessful: (ObserverCurator: ObserverCurator) => void,
}

const ObserverCuratorAuthPage = ({onLoginSuccessful}: ObserverCuratorAuthPageProps) => {
    const [errorText, setErrorText] = useState<string | null>(null);
    let navigate = useNavigate();
    const routeChange = () =>{
        let path = `cabinet`;
        navigate(path);
    }

    async function onSubmit(credentials: LoginCredentials) {
        try {
            const user = await ObserverCuratorsApi.login(credentials);
            onLoginSuccessful(user);
            routeChange();
        } catch (error) {
            if (error instanceof UnauthorizedError) {
                setErrorText(error.message);
            } else {
                alert(error);
            }
            console.error(error);
        }
    }

    return (
        <>
            <h1 className={styleUtils.flexCenter}>Авторизация куратора наблюдателя</h1>
            <LoginModal errorText={errorText} onSubmit={onSubmit}/>
        </>
    );
}

export default ObserverCuratorAuthPage;