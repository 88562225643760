import { Card } from "react-bootstrap";
import { MdDelete, MdTune } from "react-icons/md";
import styles from "../../styles/Project.module.css";
import styleUtils from "../../styles/utils.module.css";
import { Project as ProjectModel } from "../../models/project";

interface ProjectProps {
    project: ProjectModel,
    onProjectClicked: (project: ProjectModel) => void,
    onUpdateProjectClicked: (project: ProjectModel) => void,
    onDeleteProjectClicked: (project: ProjectModel) => void,
    className?: string,
}

const Project = ({ project, onProjectClicked, onUpdateProjectClicked, onDeleteProjectClicked, className }: ProjectProps) => {
    const {
        title,
    } = project;

    return (
        <Card 
            className={`${styles.projectCard} ${className}`}
            onClick={() => onProjectClicked(project)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSA}>
                    {title}
                    <div>
                        <MdTune
                            className="text-muted ms-right"
                            onClick={(e) => {
                                onUpdateProjectClicked(project);
                                e.stopPropagation();
                            }}
                        />
                        <MdDelete
                            className="text-muted ms-right"
                            onClick={(e) => {
                                onDeleteProjectClicked(project);
                                e.stopPropagation();
                            }}
                        />
                    </div>
                </Card.Title>
            </Card.Body>
        </Card>
    )
}

export default Project;