import { useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ProjectSecondStage } from "../../models/project-second-stage";
import * as ProjectSecondStageApi from "../../network/project-second-stages-api";
import styles from "../../styles/ChangeStageDialog.module.css";
import * as NotificationsApi from "../../network/notifications-api";
import * as ProjectsApi from "../../network/projects-api";

interface ChangeSecondStageDialogProps {
    stage: ProjectSecondStage,
    onDismiss: () => void,
    onStageSaved: (stage: ProjectSecondStage) => void,
}

const ChangeSecondStateDialog = ({stage, onDismiss, onStageSaved}: ChangeSecondStageDialogProps) => {
    const [currentStage, setCurrentStage] = useState<ProjectSecondStage>(stage);
    const [newStatus, setNewStatus] = useState("");
    const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);

    const downloadFile = () => {
        ProjectSecondStageApi.fetchProjectSecondStageFile({projectId: currentStage.projectId});
    }

    const setStageStatusToDone = async () => {
        const updatedStage = await ProjectSecondStageApi.updateProjectSecondStageStatus({status: "Done"}, currentStage.projectId);
        const projectData = await ProjectsApi.fetchProject({role: "administrator", projectId: stage.projectId});
        if (projectData) {
            const notification = {
                administratorId: projectData.administratorId, 
                type: "23",
                projectId: projectData._id,
                teacherCuratorId: projectData.teacherCuratorId,
                univerObserverCuratorId: projectData.univerObserverCuratorId,
                roivObserverCuratorId: projectData.roivObserverCuratorId,
                regionObserverCuratorId: projectData.regionObserverCuratorId,
            }
            await NotificationsApi.createNotification(notification);
        }
        setCurrentStage(updatedStage);
        onStageSaved(updatedStage);
    }

    interface changeStatusFormProps{
        projectId: string,
        text: string,
        file: File[],
    }
    const { register, handleSubmit, formState : {errors, isSubmitting} } = useForm<changeStatusFormProps>({
        defaultValues: {
            projectId: stage.projectId,
            text: "",
        }
    });

    async function onSubmit(input:changeStatusFormProps) {
        try {
            const updatedStage = await ProjectSecondStageApi.postProjectSecondStage({projectId: input.projectId, status: newStatus, text: input.text, file: input.file[0]});
            const projectData = await ProjectsApi.fetchProject({role: "administrator", projectId: stage.projectId});
            if (projectData) {
                const notification = {
                    administratorId: projectData.administratorId, 
                    type: "22",
                    projectId: projectData._id,
                    teacherCuratorId: projectData.teacherCuratorId,
                    univerObserverCuratorId: projectData.univerObserverCuratorId,
                    roivObserverCuratorId: projectData.roivObserverCuratorId,
                    regionObserverCuratorId: projectData.regionObserverCuratorId,
                }
                await NotificationsApi.createNotification(notification);
            }
            setCurrentStage(updatedStage);
            onStageSaved(updatedStage);
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            setShowChangeStatusDialog(false);
        }
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Этап 2
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card className={`mb-3 ${styles.descriptionField}`}>
                    <Card.Header>
                        Описание
                    </Card.Header>
                    <Card.Body>
                        {currentStage.text}
                    </Card.Body>
                </Card>
                <Button onClick={downloadFile} className={styles.downloadFileButton}>
                    Скачать закреплённый документ
                </Button>
            </Modal.Body>
            {currentStage.status === "NeedCheck" && !showChangeStatusDialog &&
                <Modal.Footer className={styles.stageFooter}>
                    <Button onClick={() => {setNewStatus("NeedRework"); setShowChangeStatusDialog(true);}} variant="danger" className="mb-3">
                        Отправить на доработку
                    </Button>
                    <Button onClick={() => {setStageStatusToDone()}} variant="success" className="mb-3">
                        Подтвердить
                    </Button>
                </Modal.Footer>
            }
            { showChangeStatusDialog &&
                <Modal.Footer className={styles.stageFooter}>
                    <Form id="changeSecondStageStatus" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                        <h1>Отправка на доработку</h1>
                        <Form.Group className="mb-3" controlId="text-input">
                            <Form.Label>Описание</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                placeholder="Описание"
                                {...register("text")} 
                                isInvalid={!!errors.text}
                            />
                        </Form.Group>
                        <Form.Group controlId="file-input">
                            <Form.Label>
                                Файл
                            </Form.Label>
                            <Form.Control 
                                type="file"
                                multiple={false}
                                {...register("file")} 
                                isInvalid={!!errors.file}
                            />
                        </Form.Group>
                    </Form>
                    <Button 
                    type="submit"
                    form="changeSecondStageStatus"
                    disabled={isSubmitting}>
                        Отправить
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    );
}

export default ChangeSecondStateDialog;