import { Card } from "react-bootstrap";
import { Notification as NotificationModel } from "../../models/notification";
import styles from "../../styles/Notification.module.css";
import { formatDate } from "../../utils/formatDate";

interface NotificationProps {
    notification: NotificationModel,
    onNotificationClicked: (notification: NotificationModel) => void,
    className?: string,
}

const Notification = ({ notification, onNotificationClicked, className }: NotificationProps) => {
    const {
        title,
        text,
        status,
        createdAt,
    } = notification;

    return (
        <Card 
            className={`${styles.noteCard} ${className}`}
            onClick={() => onNotificationClicked(notification)}
            >
                <Card.Header className={styles.notificationHeader}>
                    <>{title}</>
                    { status === "Новое" &&
                        <Card className={styles.notificationUnread}>{status}</Card>
                    }
                    { status === "Прочитано" &&
                        <Card className={styles.notificationRead}>{status}</Card>
                    }
                </Card.Header>
            <Card.Body className={styles.cardBody}>
                <Card.Text>{text}</Card.Text>
            </Card.Body>
            <Card.Footer>
                {"Создана: " + formatDate(createdAt)}
            </Card.Footer>
        </Card>
    )
}

export default Notification;