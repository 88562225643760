import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProjectPageView from "../components/TeacherCuratorsComponents/ProjectPageView";
import { TeacherCurator } from "../models/teacher-curator";
import styles from "../styles/ProjectPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface TeacherCuratorProjectPageProps {
    loggedInTeacherCurator: TeacherCurator | any| null,
}

const TeacherCuratorProjectPage = ({loggedInTeacherCurator}: TeacherCuratorProjectPageProps) => {
    const {projectId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
            { (loggedInTeacherCurator && projectId)
                ? <ProjectPageView role={loggedInTeacherCurator.role} projectId={projectId}/>
                : <NotFoundPage/>
            }
            </>
        </Container>
    );
}

export default TeacherCuratorProjectPage