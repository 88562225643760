import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { FieldError, RegisterOptions, UseFormRegister, UseFormSetValue } from "react-hook-form";
import styles from "../../../styles/AddEditProject.module.css";
import ObserverCuratorCard from "./ObserverCuratorCard";
import ProjectObserverCuratorsGrid from "./ProjectObserverCuratorsGrid";
import SignUpObserverCurator from "./SignUpObserverCurator";


interface ChooseObserverCuratorFieldProps {
    name: string,
    label: string,
    register: UseFormRegister<any>,
    registerOptions?: RegisterOptions,
    error?: FieldError,
    curatorId?: string,
    setValue: UseFormSetValue<any>,
    [x: string]: any,
}

const ChooseObserverCuratorField = ({name, label, register, registerOptions, error, curatorId, setValue, ...props}: ChooseObserverCuratorFieldProps) => {
    const [chosenCuratorId, setChosenCuratorId] = useState("")
    const [finishedChoosingCurator, setFinishedChoosingCurator] = useState(false)

    const [showAddedObserverCuratorCard, setShowAddedObserverCuratorCard] = useState(false)
    const [showAddObserverCuratorsDialog, setShowAddObserverCuratorsDialog] = useState(false);
    const [showChooseObserverCuratorsDialog, setShowChooseObserverCuratorsDialog] = useState(false);

    function useSetConstantValue() {
        setValue(name, chosenCuratorId);
        setFinishedChoosingCurator(false);
        setShowAddObserverCuratorsDialog(false);
        setShowChooseObserverCuratorsDialog(false);
        setShowAddedObserverCuratorCard(true);
    }

    const showAddNewObserverCurator = () => {
        setShowAddObserverCuratorsDialog(true);
        setShowChooseObserverCuratorsDialog(false);
        setShowAddedObserverCuratorCard(false);
    }
    const showChooseNewObserverCurator = () => {
        setShowAddObserverCuratorsDialog(false);
        setShowChooseObserverCuratorsDialog(true);
        setShowAddedObserverCuratorCard(false);
    }

    return (
        <>
        <Form.Group className="mb-3" controlId={name}>
            <Card className={styles.curatorCard}>
                <Card.Footer className={styles.CuratorFooter}>
                    {label}
                </Card.Footer>
                <Card.Header className={styles.curatorCardHeader}>
                    <Button className={styles.curatorButton} onClick={showAddNewObserverCurator}>
                        Добавить нового
                    </Button>
                    <Button className={styles.curatorButton} onClick={showChooseNewObserverCurator}>
                        Выбрать существующего
                    </Button>
                </Card.Header>
                <Card.Body>
                    { (curatorId && !showAddedObserverCuratorCard && !showAddObserverCuratorsDialog && !showChooseObserverCuratorsDialog) &&
                        <ObserverCuratorCard ObserverCuratorId={curatorId}/>
                    }
                    {showAddedObserverCuratorCard &&
                        <ObserverCuratorCard ObserverCuratorId={chosenCuratorId}/>
                    }
                    { showChooseObserverCuratorsDialog &&
                        <ProjectObserverCuratorsGrid
                        setChosenCuratorId={setChosenCuratorId}
                        setOnFinish={setFinishedChoosingCurator}
                    />
                    }
                </Card.Body>
            </Card>
        </Form.Group>
        { showAddObserverCuratorsDialog &&
            <Card className="mb-3">
                <Card.Header>
                    Добавление нового куратора-наблюдателя
                </Card.Header>
                <Card.Body>
                    <SignUpObserverCurator
                    formId={name+"signup"}
                    setChosenCuratorId={setChosenCuratorId}
                    setOnFinish={setFinishedChoosingCurator}
                    />
                </Card.Body>
            </Card>
        }
        {
            finishedChoosingCurator &&
            <ObserverCuratorCard 
                ObserverCuratorId={chosenCuratorId}
                updateForm={useSetConstantValue}
            />
        }

        </>
    );
}

export default ChooseObserverCuratorField;