import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PersonalTask as PersonalTaskModel } from "../../models/personal-task";
import * as PersonalTasksApi from "../../network/personal-tasks-api";
import styles from "../../styles/PersonalTasksPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import AddSeveralPersonalTaskDialog from "./AddSeveralPersonalTaskDialog";
import EditPersonalTaskDialog from "./EditPersonalTaskDialog";
import PersonalTaskCard from "./PersonalTaskCard";
import PersonalTaskInfo from "./PersonalTaskInfo";

interface PersonalTasksPageViewProps {
    role: string,
}

const PersonalTasksPageView = ({role}: PersonalTasksPageViewProps) => {
    const [personalTasks, setPersonalTasks] = useState<PersonalTaskModel[]>([]);
    const [personalTasksLoading, setPersonalTasksLoading] = useState(true);
    const [showPersonalTasksLoadingError, setShowPersonalTasksLoadingError] = useState(false);

    const [showAddPersonalTaskDialog, setShowAddPersonalTaskDialog] = useState(false);
    const [personalTaskToEdit, setPersonalTaskToEdit] = useState<PersonalTaskModel|null>(null);

    const [personalTaskToShow, setPersonalTaskToShow] = useState<PersonalTaskModel|null>(null);

    useEffect(() => {
        async function loadTasks() {
          try {
            setShowPersonalTasksLoadingError(false);
            setPersonalTasksLoading(true);
            const PersonalTasksData = await PersonalTasksApi.fetchPersonalTasks({role});
            setPersonalTasks(PersonalTasksData);
          } catch (error) {
            console.error(error);
            setShowPersonalTasksLoadingError(true);
          } finally {
            setPersonalTasksLoading(false);
          }
        }
        loadTasks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    async function deletePersonalTask(task:PersonalTaskModel) {
        try {
          await PersonalTasksApi.deletePersonalTask(task._id)
          setPersonalTasks(personalTasks.filter(existingTask => existingTask._id !== task._id));
        } catch (error) {
          console.error(error);
          alert(error);
        }
      }

    let navigate = useNavigate();

    const PersonalTasksGrid = 
        <Row xs={1} md={2} xl={2} className={`g-4 ${styles.tasksGrid}`}>
          {personalTasks.map(PersonalTask => (
            <Col key={PersonalTask._id}>
              <PersonalTaskCard
                task={PersonalTask} 
                className={styles.task}
                onTaskClicked={setPersonalTaskToShow}
                onUpdateTaskClicked={setPersonalTaskToEdit}
                onDeleteTaskClicked={deletePersonalTask}
                />
            </Col>
          ))}
        </Row>

    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                  className={`mb-1`}
                  onClick={() => navigate(-1)}
                  >
                  Назад
              </Button>
            </div>
            <h1>Персональные задачи</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={() => setShowAddPersonalTaskDialog(true)}>
                <FaPlus />
                Добавить задачу
            </Button>
            {personalTasksLoading && <Spinner animation='border' variant='primary' />}
            {showPersonalTasksLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!personalTasksLoading && !showPersonalTasksLoadingError && 
                <>
                    {
                    personalTasks.length > 0
                        ? PersonalTasksGrid
                        : <p>Задачи ещё не добавлены</p>
                    } 
                </>
            }
            { showAddPersonalTaskDialog &&
                <AddSeveralPersonalTaskDialog
                    role={role}
                    onDismiss={() => setShowAddPersonalTaskDialog(false)}
                    onTasksSaved={(newTasks) => {
                        setPersonalTasks([...personalTasks, ...newTasks]);
                        setShowAddPersonalTaskDialog(false);
                        }
                    }
                />
            }
            { personalTaskToEdit &&
                <EditPersonalTaskDialog
                    role={role}
                    taskToEdit={personalTaskToEdit}
                    onDismiss={() => setPersonalTaskToEdit(null)}
                    onTaskSaved={(updatedTask) => {
                        setPersonalTasks(personalTasks.map(existingTask => existingTask._id === updatedTask._id ? updatedTask : existingTask));
                        setPersonalTaskToEdit(null);
                        }
                    }
                />
            }
            { (personalTaskToShow) &&
                <PersonalTaskInfo
                    personalTask={personalTaskToShow}
                    role={role}
                    onDismiss={(updatedTask) => { setPersonalTaskToShow(null); setPersonalTasks(personalTasks.map(existingTask => existingTask._id === updatedTask._id ? updatedTask : existingTask)); }}
                />
            }
        </>
    );
}

export default PersonalTasksPageView;