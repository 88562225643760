import { Button, Form, Modal, ModalFooter } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Project as ProjectModel } from "../../../models/project";
import { ProjectTask } from "../../../models/project-task";
import * as ProjectTasksApi from "../../../network/project-tasks-api";
import CalendarInputField from "../form/CalendarInputField";
import TextInputField from "../form/TextInputField";
import ChooseProjectsField from "./ChooseProjectsField";

interface AddSeveralProjectTasksDialogProps {
  role: string,
  onDismiss: () => void,
  onTasksSaved: (tasks: ProjectTask[]) => void,
}

const AddSeveralProjectTasksDialog = ({role, onDismiss, onTasksSaved}: AddSeveralProjectTasksDialogProps) => {
  interface FormProps {
    title: string,
    text: string,
    status: string,
    projects: ProjectModel[],
    deadline: string,
  }

  const { register, handleSubmit, setValue, formState : {errors, isSubmitting} } = useForm<FormProps>({
    defaultValues: {
      projects: [],  
      title: "",
      text: "",
      status: "В работе",
      deadline: "",
    }
  });

  async function onSubmit(input:FormProps) {
    try {
        const TaskResponse: ProjectTask[] = [];
        for (const project of input.projects) {
          const taskInput = {
            projectId: project._id,
            teacherCuratorId: project.teacherCuratorId,
            univerObserverCuratorId: project.univerObserverCuratorId,
            roivObserverCuratorId: project.roivObserverCuratorId,
            regionObserverCuratorId: project.regionObserverCuratorId,
            status: input.status,
            title: input.title,
            text: input.text,
            deadline: input.deadline,
          }
          TaskResponse.push(await ProjectTasksApi.createProjectTask(taskInput))
        }
        onTasksSaved(TaskResponse);
    } catch (error) {
        console.error(error);
        alert(error);
    }
  };

    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Добавить задачу
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form id="addProjectTaskForm" onSubmit={handleSubmit(onSubmit)}>
                <TextInputField 
                    name="title"
                    label="Название"
                    type="text"
                    placeholder="Название"
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.title}
                />

                <TextInputField 
                    name="text"
                    label="Описание"
                    as="textarea"
                    rows={3}
                    placeholder="Описание"
                    register={register}
                    registerOptions={{required: "Required"}}
                    error={errors.text}
                />

                <CalendarInputField
                    name="deadline"
                    label="Дедлайн"
                    placeholder=""
                    register={register}
                    setValue={setValue}
                    registerOptions={{required: "Required"}}
                    error={errors.deadline}
                />

                <ChooseProjectsField
                  role={role}
                  name="projects"
                  setValue={setValue}
                  registerOptions={{required: "Required"}}
                  error={errors.title}
                />
            </Form>
            </Modal.Body>
            <ModalFooter>
              <Button
              type="submit"
              form="addProjectTaskForm"
              disabled={isSubmitting}
              >
                  Сохранить
              </Button>
            </ModalFooter>
        </Modal>
    );
}

export default AddSeveralProjectTasksDialog;