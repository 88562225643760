import { Card } from "react-bootstrap";
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import { ObserverCurator } from "../../../models/observer-curator";

interface ObserverCuratorCardPreloadedProps {
    observerCurator: ObserverCurator,
    chosenCurators: ObserverCurator[],
    onObserverCuratorClicked: (value:ObserverCurator) => void,
    className?: string,
}

const ObserverCuratorCardPreloaded = ({observerCurator, chosenCurators, onObserverCuratorClicked, className}: ObserverCuratorCardPreloadedProps) => {
    return(
        <Card 
            onClick={(e) => {
                onObserverCuratorClicked(observerCurator);
            }}
            >
            <Card.Body className={className}>
                <div>
                    <div>
                    {observerCurator.fullName}
                    </div>
                    <div>
                    {"Должность: "+observerCurator.position}
                    </div>
                </div>
                {(chosenCurators.includes(observerCurator))? 
                    <FaRegCheckSquare/>
                    :
                    <FaRegSquare/>}
            </Card.Body>
        </Card>
    );
}

export default ObserverCuratorCardPreloaded;