import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProjectStagesPageView from "../components/ObserverCuratorsComponents/ProjectStagesPageView";
import { ObserverCurator } from "../models/observer-curator";
import styles from "../styles/ProjectStagesPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface ObserverCuratorProjectStagesPageProps {
  loggedInObserverCurator: ObserverCurator | any,
}

const ObserverCuratorProjectStagesPage = ({ loggedInObserverCurator }: ObserverCuratorProjectStagesPageProps) => {
  const {projectId} = useParams();
  return (
        <Container className={styles.projectStagesPage}>
        <>
          { (loggedInObserverCurator && projectId)
              ? <ProjectStagesPageView role={loggedInObserverCurator.role} projectId={projectId}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default ObserverCuratorProjectStagesPage;