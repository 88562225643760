import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TeacherCurator } from "../../models/teacher-curator";
import * as TeacherCuratorsApi from "../../network/teacher-curator-api";
import styles from "../../styles/TeacherCuratorsGrid.module.css";
import stylesUtils from "../../styles/utils.module.css";

const TeacherCuratorsPageView = () => {
    const [teacherCurators, setTeacherCurators] = useState<TeacherCurator[]>([]);
    const [teacherCuratorsToShow, setTeacherCuratorsToShow] = useState<TeacherCurator[]>([]);
    const [teacherCuratorNameFilter, setTeacherCuratorNameFilter] = useState("");
    const [teacherCuratorsLoading, setTeacherCuratorsLoading] = useState(true);
    const [showTeacherCuratorsLoadingError, setShowTeacherCuratorsLoadingError] = useState(false);

    useEffect(() => {
        async function loadTeacherCurators() {
            try {
                setShowTeacherCuratorsLoadingError(false);
                setTeacherCuratorsLoading(true);
                const curators = await TeacherCuratorsApi.fetchTeacherCurators();
                setTeacherCurators(curators);
                setTeacherCuratorsToShow(curators);
            } catch (error) {
                console.error(error);
                setShowTeacherCuratorsLoadingError(true);
            } finally {
                setTeacherCuratorsLoading(false);
            }
            }
            loadTeacherCurators();
    }, []);

    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = teacherCurators.filter((user) => {
                return user.fullName.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setTeacherCuratorsToShow(filteredArray);
        } else {
            setTeacherCuratorsToShow(teacherCurators);
        }

        setTeacherCuratorNameFilter(keyword);
    }

    const teacherCuratorsGrid = 
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.allTeacherCuratorsGrid}`}>
            {teacherCuratorsToShow.map(teacherCurator => (
            <Col key={teacherCurator._id}>
                <Card>
                    <Card.Body>
                        <Card.Text className={styles.curatorNameField}>
                            <div>{teacherCurator?.fullName}</div>
                        </Card.Text>
                        <Card.Text className={styles.curatorNameField}>
                                <div>Должность:</div>
                                <div>{teacherCurator?.position}</div>
                        </Card.Text>
                        <Card.Text className={styles.curatorPhoneField}>
                            <div>Телефон:</div>
                            <a href={`tel:${teacherCurator?.phone}`}>{teacherCurator?.phone}</a>
                        </Card.Text>
                        <Card.Text className={styles.curatorEmailField}>
                            <div>Почта:</div>
                            <a href={`mailto:${teacherCurator?.email}`}>{teacherCurator?.email}</a>
                        </Card.Text>
                        <Card.Text className={styles.curatorEmailField}>
                            <div>Телеграм:</div>
                            <a href={`http://t.me/${teacherCurator?.telegram}`} target="_blank" rel="noreferrer">{teacherCurator?.telegram}</a>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            ))}
        </Row>

    let navigate = useNavigate();

    return (
        <> 
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Педагоги-кураторы</h1>
            {teacherCuratorsLoading && <Spinner animation='border' variant='primary' />}
            {showTeacherCuratorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!teacherCuratorsLoading && !showTeacherCuratorsLoadingError && 
            <>
            <div>
                <input
                    type="search"
                    value={teacherCuratorNameFilter}
                    onChange={filter}
                    className={styles.search}
                    placeholder="Поиск по ФИО">
                </input>
            </div>
            <div className={styles.gridField}>
                {
                teacherCurators.length > 0
                    ? teacherCuratorsGrid
                    : <p>Вы ещё не добавили педагогов-кураторов</p>
                }  
            </div>
            </>
            }

        </>
    );
}

export default TeacherCuratorsPageView;