import { Container } from "react-bootstrap";
import { ObserverCurator } from "../models/observer-curator";
import styles from "../styles/StatisticsPage.module.css";
import NotFoundPage from "./NotFoundPage";
import StatisticsPageView from "../components/ObserverCuratorsComponents/StatisticsPageView";

interface ObserverCuratorStatisticsPageProps {
    loggedInObserverCurator: ObserverCurator | any,
}

const ObserverCuratorStatisticsPage = ({ loggedInObserverCurator }: ObserverCuratorStatisticsPageProps) => {
  return (
        <Container className={styles.statisticsPage}>
        <>
          { loggedInObserverCurator
              ? <StatisticsPageView role={loggedInObserverCurator.role}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default ObserverCuratorStatisticsPage;