import { Container } from "react-bootstrap";
import TeacherCuratorsPageView from "../components/AdministratorsComponents/TeacherCuratorsPageView";
import { Administrator } from "../models/administrator";
import styles from "../styles/NotesPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface AdministratorTeacherCuratorsPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorTeacherCuratorsPage = ({ loggedInAdministrator }: AdministratorTeacherCuratorsPageProps) => {
    return (
        <Container className={styles.notesPage}>
        <>
          { loggedInAdministrator
              ? <TeacherCuratorsPageView/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default AdministratorTeacherCuratorsPage;