import { Button, Container } from "react-bootstrap";
import styles from "../styles/AuthPage.module.css";
import stylesUtils from "../styles/utils.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Administrator } from "../models/administrator";

interface AdministratorUsersPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorUsersPage = ({ loggedInAdministrator }: AdministratorUsersPageProps) => {
    let navigate = useNavigate();
    
    return (
        <Container className={styles.authPage}>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <Link to="teacher-curators">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Педагоги-кураторы
                </Button>
            </Link>
            <Link to="observer-curators">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Кураторы-наблюдатели
                </Button>
            </Link>
        </Container>
    )
}

export default AdministratorUsersPage;