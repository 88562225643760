import { Container } from "react-bootstrap";
import ObserverCuratorsPageView from "../components/AdministratorsComponents/ObserverCuratorsPageView";
import { Administrator } from "../models/administrator";
import styles from "../styles/NotesPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface AdministratorObserverCuratorsPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorObserverCuratorsPage = ({ loggedInAdministrator }: AdministratorObserverCuratorsPageProps) => {
    return (
        <Container className={styles.notesPage}>
        <>
          { loggedInAdministrator
              ? <ObserverCuratorsPageView/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default AdministratorObserverCuratorsPage;