import { Container } from "react-bootstrap";
import ObserverCuratorProjectsPageView from "../components/ObserverCuratorsComponents/ObserverCuratorProjectsPageView";
import { ObserverCurator } from "../models/observer-curator";
import styles from "../styles/ProjectsPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface ObserverCuratorProjectsPageProps {
    loggedInObserverCurator: ObserverCurator | any | null,
}

const ObserverCuratorProjectsPage = ({ loggedInObserverCurator }: ObserverCuratorProjectsPageProps) => {
  return (
        <Container className={styles.projectsPage}>
        <>
          { loggedInObserverCurator
              ? <ObserverCuratorProjectsPageView observerCurator={loggedInObserverCurator}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default ObserverCuratorProjectsPage