import { useEffect, useState } from "react";
import { TeacherCurator } from "../../../models/teacher-curator";
import * as TeacherCuratorsApi from "../../../network/teacher-curator-api";
import { Col, Row, Spinner } from "react-bootstrap";
import styles from "../../../styles/TeacherCuratorsGrid.module.css";
import ProjectTeacherCuratorCardPreloaded from "./ProjectTeacherCuratorCardPreloaded";

interface ProjectTeacherCuratorGridProps {
    setChosenCuratorId: (value:string) => void,
    setOnFinish: (b:boolean)=> void,
}

const ProjectTeacherCuratorsGrid = ({setChosenCuratorId, setOnFinish}: ProjectTeacherCuratorGridProps) => {
    const [teacherCurators, setTeacherCurators] = useState<TeacherCurator[]>([]);
    const [teacherCuratorsToShow, setTeacherCuratorsToShow] = useState<TeacherCurator[]>([]);
    const [teacherCuratorNameFilter, setTeacherCuratorNameFilter] = useState("");
    const [teacherCuratorsLoading, setTeacherCuratorsLoading] = useState(true);
    const [showTeacherCuratorsLoadingError, setShowTeacherCuratorsLoadingError] = useState(false);

    useEffect(() => {
        async function loadTeacherCurators() {
            try {
                setShowTeacherCuratorsLoadingError(false);
                setTeacherCuratorsLoading(true);
                const curators = await TeacherCuratorsApi.fetchTeacherCurators();
                setTeacherCurators(curators);
                setTeacherCuratorsToShow(curators);
            } catch (error) {
                console.error(error);
                setShowTeacherCuratorsLoadingError(true);
            } finally {
                setTeacherCuratorsLoading(false);
            }
          }
          loadTeacherCurators();
    }, []);

    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = teacherCurators.filter((user) => {
                return user.fullName.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setTeacherCuratorsToShow(filteredArray);
        } else {
            setTeacherCuratorsToShow(teacherCurators);
        }

        setTeacherCuratorNameFilter(keyword);
    }

    const teacherCuratorsGrid = 
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.teacherCuratorsGrid}`}>
          {teacherCuratorsToShow.map(teacherCurator => (
            <Col key={teacherCurator._id}>
              <ProjectTeacherCuratorCardPreloaded 
                teacherCurator={teacherCurator} 
                className={styles.projectTeacherCurator}
                onTeacherCuratorClicked={setChosenCuratorId}
                onFinish={setOnFinish}
                />
            </Col>
          ))}
        </Row>

    return (
        <>
            {teacherCuratorsLoading && <Spinner animation='border' variant='primary' />}
                {showTeacherCuratorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
                {!teacherCuratorsLoading && !showTeacherCuratorsLoadingError && 
                <div className={styles.gridField}>
                    <input
                        type="search"
                        value={teacherCuratorNameFilter}
                        onChange={filter}
                        className={styles.search}
                        placeholder="Поиск по ФИО">
                    </input>
                    {
                    teacherCurators.length > 0
                        ? teacherCuratorsGrid
                        : <p>Вы ещё не добавили педагогов-кураторов</p>
                    }  
                </div>
                }
        </>
    );
}

export default ProjectTeacherCuratorsGrid;