import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProjectTasksPageView from "../components/ObserverCuratorsComponents/ProjectTasksPageView";
import { ObserverCurator } from "../models/observer-curator";
import styles from "../styles/ProjectsPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface ObserverCuratorProjectTasksPageProps {
    loggedInObserverCurator: ObserverCurator | any | null,
}

const ObserverCuratorProjectTasksPage = ({ loggedInObserverCurator }: ObserverCuratorProjectTasksPageProps) => {
  const {projectId} = useParams();
  return (
        <Container className={styles.projectsPage}>
        <>
          { loggedInObserverCurator
              ? <ProjectTasksPageView role={loggedInObserverCurator.role} projectId={projectId}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default ObserverCuratorProjectTasksPage