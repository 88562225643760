import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Project as ProjectModel } from "../../models/project";
import * as ProjectsApi from "../../network/projects-api";
import styles from "../../styles/ProjectsPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import AddEditProjectDialog from "./AddEditProjectDialog";
import Project from "./Project";

interface ProjectsPageViewProps {
  role: string,
  regionId: string|undefined,
}

const ProjectsPageView = ({role, regionId}: ProjectsPageViewProps) => {
    const [projects, setProjects] = useState<ProjectModel[]>([]);
    const [projectsLoading, setProjectsLoading] = useState(true);
    const [showProjectsLoadingError, setShowProjectsLoadingError] = useState(false);

    const [showAddProjectDialog, setShowAddProjectDialog] = useState(false);
    const [projectToEdit, setProjectToEdit] = useState<ProjectModel|null>(null);

    useEffect(() => {
        async function loadProjects() {
          try {
            setShowProjectsLoadingError(false);
            setProjectsLoading(true);
            const projectsData = await ProjectsApi.fetchProjects({role, regionId});
            setProjects(projectsData);
          } catch (error) {
            console.error(error);
            setShowProjectsLoadingError(true);
          } finally {
            setProjectsLoading(false);
          }
        }
        loadProjects();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    async function deleteProject(project:ProjectModel) {
        try {
          await ProjectsApi.deleteProject(project._id)
          setProjects(projects.filter(existingProject => existingProject._id !== project._id));
        } catch (error) {
          console.error(error);
          alert(error);
        }
      }

    let navigate = useNavigate();
    const goToProject = (project: ProjectModel) =>{
        let path = `${project._id}`;
        navigate(path);
        }

    const projectsGrid = 
        <Row xs={1} md={2} xl={3} className={`g-4 ${styles.projectsGrid}`}>
          {projects.map(project => (
            <Col key={project._id}>
              <Project
                project={project} 
                className={styles.project}
                onProjectClicked={goToProject}
                onUpdateProjectClicked={setProjectToEdit}
                onDeleteProjectClicked={deleteProject}
                />
            </Col>
          ))}
        </Row>

    return (
        <> 
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Проекты</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={() => setShowAddProjectDialog(true)}>
                <FaPlus />
                Добавить проект
            </Button>
            {projectsLoading && <Spinner animation='border' variant='primary' />}
            {showProjectsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!projectsLoading && !showProjectsLoadingError && 
                <>
                    {
                    projects.length > 0
                        ? projectsGrid
                        : <p>Проекты ещё не добавлены</p>
                    }  
                </>
                }
                { (showAddProjectDialog && regionId) &&
                <AddEditProjectDialog
                regionId={regionId}
                onDismiss={() => setShowAddProjectDialog(false)}
                onProjectSaved={(newProject) => {
                    setProjects([...projects, newProject]);
                    setShowAddProjectDialog(false);
                }}
                />
                }
                { (projectToEdit && regionId) &&
                <AddEditProjectDialog
                  regionId={regionId}
                  projectToEdit={projectToEdit}
                    onDismiss={() => setProjectToEdit(null)}
                    onProjectSaved={(updatedProject) => {
                        setProjects(projects.map(existingProject => existingProject._id === updatedProject._id ? updatedProject : existingProject))
                        setProjectToEdit(null);
                        }
                    }
                />
                }
        </>
    );
}

export default ProjectsPageView