import { Container } from "react-bootstrap";
import { Administrator } from "../models/administrator";
import NotFoundPage from "./NotFoundPage";
import styles from "../styles/ProjectsPage.module.css"
import { useParams } from "react-router-dom";
import ProjectsPageView from "../components/AdministratorsComponents/ProjectsPageView";

interface AdministratorProjectsPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorProjectsPage = ({ loggedInAdministrator }: AdministratorProjectsPageProps) => {
  const {regionId} = useParams();
  return (
        <Container className={styles.projectsPage}>
        <>
          { loggedInAdministrator
              ? <ProjectsPageView role={loggedInAdministrator.role} regionId={regionId}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default AdministratorProjectsPage