import { PersonalTask } from "../models/personal-task"
import { fetchData } from "../utils/fetchData";


interface RequestProps {
    role: string,
}

export async function fetchPersonalTasks(requestProps: RequestProps): Promise<PersonalTask[]> {
    const response = await fetchData("/api/personal-tasks/all", 
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}

interface fetchPersonalTaskProps {
    role: string,
    taskId: string,
}

export async function fetchPersonalTask(requestProps: fetchPersonalTaskProps): Promise<PersonalTask> {
    const response = await fetchData("/api/personal-tasks/"+requestProps.taskId, 
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}

export interface PersonalTaskInput {
    userId: string,
    status: string,
    title: string,
    text: string,
    deadline: string,
}

export async function createPersonalTask(task: PersonalTaskInput): Promise<PersonalTask> {
    const response = await fetchData("/api/personal-tasks",
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(task),
    });
    return response.json();
}

export async function updatePersonalTask(taskId:string, task: PersonalTaskInput): Promise<PersonalTask> {
    const response = await fetchData("/api/personal-tasks/" + taskId,
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(task),
    })
    return response.json();
}

interface updatePersonalTaskStatusProps{
    status: string,
    statusDescription:string,
}

export async function updatePersonalTaskStatus(taskId:string, taskStatusInfo: updatePersonalTaskStatusProps): Promise<PersonalTask> {
    const response = await fetchData("/api/personal-tasks/" + taskId + "/status",
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(taskStatusInfo),
    })
    return response.json();
}

export async function deletePersonalTask(taskId:string) {
    await fetchData("/api/personal-tasks/"+ taskId, { method: "DELETE"});
}