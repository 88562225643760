import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ProjectTask as TaskModel } from "../../models/project-task";
import * as TasksApi from "../../network/project-tasks-api";
import styles from "../../styles/ProjectTasksPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import ProjectTask from "./ProjectTask";
import ProjectTaskInfo from "./ProjectTaskInfo";

interface ProjectTasksPageViewProps {
  role: string,
  projectId: string|undefined,
}

const ProjectTasksPageView = ({role, projectId}: ProjectTasksPageViewProps) => {
    const [tasks, setTasks] = useState<TaskModel[]>([]);
    const [tasksLoading, setTasksLoading] = useState(true);
    const [showTasksLoadingError, setShowTasksLoadingError] = useState(false);

    const [taskToShow, setTaskToShow] = useState<TaskModel|null>(null);

    useEffect(() => {
        async function loadTasks() {
          try {
            setShowTasksLoadingError(false);
            setTasksLoading(true);
            const tasksData = await TasksApi.fetchProjectTasks({role, projectId});
            setTasks(tasksData);
          } catch (error) {
            console.error(error);
            setShowTasksLoadingError(true);
          } finally {
            setTasksLoading(false);
          }
        }
        loadTasks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let navigate = useNavigate();

    const tasksGrid = 
        <Row xs={1} md={2} xl={2} className={`g-4 ${styles.tasksGrid}`}>
          {tasks.map(task => (
            <Col key={task._id}>
              <ProjectTask
                task={task} 
                className={styles.task}
                onTaskClicked={setTaskToShow}
                />
            </Col>
          ))}
        </Row>

    return (
        <> 
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Задачи</h1>
            {tasksLoading && <Spinner animation='border' variant='primary' />}
            {showTasksLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!tasksLoading && !showTasksLoadingError && 
              <>
                  {
                  tasks.length > 0
                      ? tasksGrid
                      : <p>Задачи ещё не добавлены</p>
                  }  
              </>
            }
            { (taskToShow) &&
                <ProjectTaskInfo
                  projectTask={taskToShow}
                  onDismiss={(updatedTask) => { setTaskToShow(null); setTasks(tasks.map(existingTask => existingTask._id === updatedTask._id ? updatedTask : existingTask)); }}
                />
                }
        </>
    );
}

export default ProjectTasksPageView;