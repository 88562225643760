import { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Project as ProjectModel } from "../../models/project";
import * as FirstStageApi from "../../network/project-first-stages-api";
import * as SecondStageApi from "../../network/project-second-stages-api";
import * as TasksApi from "../../network/project-tasks-api";
import * as ThirdStageApi from "../../network/project-third-stages-api";
import * as ProjectsApi from "../../network/projects-api";
import styles from "../../styles/StatisticsPage.module.css";
import stylesUtils from "../../styles/utils.module.css";

interface StatisticsRegionPageViewProps{
    role: string,
    regionId: string,
}

const StatisticsRegionPageView = ({role, regionId}: StatisticsRegionPageViewProps) => {
    interface ProjectStatistics{
        project: ProjectModel,
        deadlineProblems: number,
        tasksProblems: number,
        stagesProblems: number,
    }

    const [statistics, setStatistics] = useState<ProjectStatistics[]>([]);
    const [statisticsLoading, setStatisticsLoading] = useState(true);
    const [showStatisticsLoadingError, setShowStatisticsLoadingError] = useState(false);

    useEffect(() => {
        async function countStatistics() {
          try {
            setShowStatisticsLoadingError(false);
            setStatisticsLoading(true);
            //Load projects
            const projects = await ProjectsApi.fetchProjects({role, regionId});
            //Initialize statistics
            const statistics = projects.map((project) => {return {project: project, deadlineProblems: 0, tasksProblems: 0, stagesProblems: 0}});
            //Count statistics
            for (let statisticPiece of statistics) {
                const projectId = statisticPiece.project._id;
                //Check tasks
                const tasks = await TasksApi.fetchProjectTasks({role, projectId});
                for (let task of tasks) {
                    //Check status
                    if (task.status === "Нужна помощь") {
                        statisticPiece.tasksProblems += 1;
                    }
                    //Check deadline
                    if (task.status !== "Готово") {
                        const TodayDate = new Date();
                        const TaskDate = new Date(task.deadline);
                        var diff = TaskDate.getTime() - TodayDate.getTime();
                        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                        if (diffDays < 3 && diffDays > 0) {
                            statisticPiece.deadlineProblems += 1;
                        } else if (diffDays <= 0) {
                            statisticPiece.tasksProblems += 1;
                        }
                    }
                    
                }
                //Check stages status
                const firstStage = await FirstStageApi.fetchProjectFirstStage({projectId});
                if (!firstStage){
                    continue;
                }
                if (firstStage.status==="NeedRework") {
                    statisticPiece.stagesProblems += 1;
                }
                const secondStage = await SecondStageApi.fetchProjectSecondStage({projectId});
                if (!secondStage){
                    continue;
                }
                if (secondStage.status==="NeedRework") {
                    statisticPiece.stagesProblems += 1;
                }
                const thirdStage = await ThirdStageApi.fetchProjectThirdStage({projectId});
                if (!thirdStage){
                    continue;
                }
                if (thirdStage.status==="NeedRework") {
                    statisticPiece.stagesProblems += 1;
                }
            }
            setStatistics(statistics);
          } catch (error) {
            console.error(error);
            setShowStatisticsLoadingError(true);
          } finally {
            setStatisticsLoading(false);
          }
        }
        countStatistics();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let navigate = useNavigate();

    const goToProject = (project: ProjectModel) =>{
        let path = `/administrator/cabinet/regions/${regionId}/${project._id}`;
        navigate(path);
        }

    const regionsRows= 
        <tbody>
          {statistics.map(statistic => (
            statistic.deadlineProblems + statistic.tasksProblems + statistic.stagesProblems> 0 ?
            <tr onClick={() => {goToProject(statistic.project)}}>
                <td className={styles.tableCellWithProblems}>
                    {statistic.project.title}
                </td>
                <td className={styles.tableCellWithProblems}>
                    {statistic.deadlineProblems}
                </td>
                <td className={styles.tableCellWithProblems}>
                    {statistic.tasksProblems}
                </td>
                <td className={styles.tableCellWithProblems}>
                    {statistic.stagesProblems}
                </td>
            </tr>
            :
            <tr onClick={() => {goToProject(statistic.project)}}>
                <td className={styles.tableCellNoProblems}>
                    {statistic.project.title}
                </td>
                <td className={styles.tableCellNoProblems}>
                    {statistic.deadlineProblems}
                </td>
                <td className={styles.tableCellNoProblems}>
                    {statistic.tasksProblems}
                </td>
                <td className={styles.tableCellNoProblems}>
                    {statistic.stagesProblems}
                </td>
            </tr>
          ))}
        </tbody>

    return (
        <> 
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1 className="mb-3">Статистика</h1>
            {statisticsLoading && <Spinner animation='border' variant='primary' />}
            {showStatisticsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!statisticsLoading && !showStatisticsLoadingError && 
                <Table bordered hover className={styles.tableStyle}>
                    <thead>
                        <tr >
                            <th className={styles.headingRegionCell}>Проект</th>
                            <th className={styles.headingProblemsCell}>Дедлайны</th>
                            <th className={styles.headingProblemsCell}>Задачи</th>
                            <th className={styles.headingProblemsCell}>Этапы</th>
                        </tr>
                    </thead>
                    {statistics.length > 0 ? 
                        regionsRows
                        :
                        <tr>
                            <td colSpan={2}>
                                Проекты ещё не добавлены
                            </td>
                        </tr>}
                </Table>
            }
        </>
    );
}

export default StatisticsRegionPageView;