import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FieldError, RegisterOptions, UseFormSetValue } from "react-hook-form";
import { TeacherCurator } from "../../../models/teacher-curator";
import * as TeacherCuratorsApi from "../../../network/teacher-curator-api";
import styles from "../../../styles/TeacherCuratorsGrid.module.css";
import TeacherCuratorCardPreloaded from "./TeacherCuratorCardPreloaded";

interface TeacherCuratorGridProps {
    name: string,
    registerOptions?: RegisterOptions,
    error?: FieldError,
    setValue: UseFormSetValue<any>,
    [x: string]: any,
}

const TeacherCuratorsGrid = ({name, register, registerOptions, error, setValue, ...props}: TeacherCuratorGridProps) => {
    const [teacherCurators, setTeacherCurators] = useState<TeacherCurator[]>([]);
    const [teacherCuratorNameFilter, setTeacherCuratorNameFilter] = useState("");
    const [teacherCuratorsToShow, setTeacherCuratorsToShow] = useState<TeacherCurator[]>([]);
    const [teacherCuratorsLoading, setTeacherCuratorsLoading] = useState(true);
    const [showTeacherCuratorsLoadingError, setShowTeacherCuratorsLoadingError] = useState(false);

    const [chosenCurators, setChosenCurators] = useState<TeacherCurator[]>([]);

    let chosenCuratorsArray: TeacherCurator[] = [];

    useEffect(() => {
        async function loadTeacherCurators() {
            try {
                setShowTeacherCuratorsLoadingError(false);
                setTeacherCuratorsLoading(true);
                const curators = await TeacherCuratorsApi.fetchTeacherCurators();
                setTeacherCurators(curators);
                setTeacherCuratorsToShow(curators);
            } catch (error) {
                console.error(error);
                setShowTeacherCuratorsLoadingError(true);
            } finally {
                setTeacherCuratorsLoading(false);
            }
          }
          loadTeacherCurators();
    }, []);

    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = teacherCurators.filter((user) => {
                return user.fullName.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setTeacherCuratorsToShow(filteredArray);
        } else {
            setTeacherCuratorsToShow(teacherCurators);
        }

        setTeacherCuratorNameFilter(keyword);
    }

    const AddPopTeacherCuratorId = (curator: TeacherCurator) => {
        try{
            if (chosenCurators.includes(curator)) {
                chosenCuratorsArray = chosenCurators.filter(chosenCurator => chosenCurator._id !== curator._id);
                setChosenCurators(chosenCuratorsArray);
            } else {
                chosenCuratorsArray = chosenCurators.concat(curator);
                setChosenCurators(chosenCuratorsArray);
            }
        } finally {
            setValue(name, chosenCuratorsArray);
        }
    }

    const teacherCuratorsGrid = 
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.teacherCuratorsGrid}`}>
          {teacherCuratorsToShow.map(teacherCurator => (
            <Col key={teacherCurator._id}>
              <TeacherCuratorCardPreloaded 
                teacherCurator={teacherCurator} 
                className={styles.teacherCurator}
                chosenCurators={chosenCurators}
                onTeacherCuratorClicked={AddPopTeacherCuratorId}
                />
            </Col>
          ))}
        </Row>

    return (
        <>
            {teacherCuratorsLoading && <Spinner animation='border' variant='primary' />}
            {showTeacherCuratorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!teacherCuratorsLoading && !showTeacherCuratorsLoadingError && 
            <div className={styles.teacherCuratorsModule}>
                <Button className={`mb-3 ${styles.chooseAllButton}`} onClick={() => {chosenCuratorsArray = teacherCurators; setChosenCurators(chosenCuratorsArray); setValue(name, chosenCuratorsArray)}}>
                    Выбрать все
                </Button>
                <input
                        type="search"
                        value={teacherCuratorNameFilter}
                        onChange={filter}
                        className={styles.search}
                        placeholder="Поиск по ФИО">
                    </input>
                {
                teacherCurators.length > 0
                    ? teacherCuratorsGrid
                    : <p>Вы ещё не добавили педагогов-кураторов</p>
                }  
            </div>
            }
        </>
    );
}

export default TeacherCuratorsGrid;