import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { TeacherCurator } from "../../../models/teacher-curator";
import * as TeacherCuratorsApi from "../../../network/teacher-curator-api";

interface CuratorCardProps {
    teacherCuratorId: string,
    updateForm?: () => void,
}

const TeacherCuratorCard = ({teacherCuratorId, updateForm} : CuratorCardProps) => {
    if (updateForm) {
        updateForm();
    }
    const [curatorData, setCuratorData] = useState<TeacherCurator|null>(null)
    const [curatorLoading, setCuratorLoading] = useState(true);
    const [showCuratorLoadingError, setShowCuratorLoadingError] = useState(false);
    useEffect(() => {
        async function loadCurator(teacherCuratorId: string) {
            try {
                const curator = await TeacherCuratorsApi.getTeacherCurator(teacherCuratorId);
                setCuratorData(curator)
            } catch (error) {
                console.error(error);
                setShowCuratorLoadingError(true);
            } finally {
                setCuratorLoading(false);
            }
        }
        loadCurator(teacherCuratorId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    return (
        <>
            {curatorLoading &&
                <Card>
                    <Card.Body>
                        Loading
                    </Card.Body>
                </Card>
            }
            {curatorData &&
                <Card>
                    <Card.Body>
                        <Card.Text>
                            {curatorData.fullName}
                        </Card.Text>
                        <Card.Text>
                            {"Должность: "+curatorData.position}
                        </Card.Text>
                    </Card.Body>
                </Card>
            }
            {showCuratorLoadingError &&
                 <Card>
                 <Card.Body>
                     Error
                 </Card.Body>
             </Card>
            }
        </>
    );
}

export default TeacherCuratorCard;