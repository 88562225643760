import { useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { FieldError, RegisterOptions, UseFormSetValue } from "react-hook-form";
import { Project as ProjectModel } from "../../../models/project";
import * as ProjectsApi from "../../../network/projects-api";
import styles from "../../../styles/ChooseProjectField.module.css";
import ChooseRegions from "./ChooseRegions";
import ProjectCard from "./ProjectCard";

interface ChooseProjectsFieldProps {
    role: string,
    name: string,
    setValue: UseFormSetValue<any>,
    registerOptions?: RegisterOptions,
    error?: FieldError,
}

const ChooseProjectsField = ({role, name, setValue, registerOptions, error}: ChooseProjectsFieldProps) => {
    const [projects, setProjects] = useState<ProjectModel[]>([]);
    const [projectsLoading, setProjectsLoading] = useState(true);
    const [showProjectsLoadingError, setShowProjectsLoadingError] = useState(false);

    const [showChooseRegionsField, setShowChooseRegionsField] = useState(true);
    const [chosenProjects, setChosenProjects] = useState<ProjectModel[]>([]);

    let chosenProjectsArray: ProjectModel[] = [];
    
    const useLoadProjects = async (chosenRegionsId: string[]) => {
        try {
            setShowProjectsLoadingError(false);
            setProjectsLoading(true);
            const projectsData: ProjectModel[] = [];
            for (const regionId of chosenRegionsId) {
                projectsData.push(...await ProjectsApi.fetchProjects({role: role, regionId: regionId}));
            }
            setProjects(projectsData);
        } catch (error) {
            console.error(error);
            setShowProjectsLoadingError(true);
        } finally {
            setProjectsLoading(false);
            setShowChooseRegionsField(false);
        }
    }

    const AddPopProjectId = (project: ProjectModel) => {
        try{
            if (chosenProjects.includes(project)) {
                chosenProjectsArray = chosenProjects.filter(chosenProject => chosenProject._id !== project._id);
                setChosenProjects(chosenProjectsArray);
            } else {
                chosenProjectsArray = chosenProjects.concat(project);
                setChosenProjects(chosenProjectsArray);
            }
        } finally {
            setValue(name, chosenProjectsArray);
        }
    }

    const projectsGrid = 
        <Row xs={1} md={1} xl={1} className={`g-4`}>
          {projects.map(project => (
            <Col key={project._id}>
              <ProjectCard
                project={project}
                chosenProjects={chosenProjects}
                className={styles.project}
                onProjectClicked={AddPopProjectId}
                />
            </Col>
          ))}
        </Row>
    return(
        <Card className={styles.chooseProjectField}>
            <Card.Header>
                Выбор проектов
            </Card.Header>
            {showChooseRegionsField ?
                <ChooseRegions
                    onDismiss={useLoadProjects}
                />
                :
                <Card.Body className={styles.chooseProjectFieldBody}>
                    <Button className="mb-3" onClick={() => {setShowChooseRegionsField(true); chosenProjectsArray=[]; setChosenProjects(chosenProjectsArray); setValue(name, chosenProjectsArray)}}>Выбрать другие регионы</Button>
                    <Form.Group className="mb-3" controlId={name + "-input"}>
                        {projectsLoading && <Spinner animation='border' variant='primary' />}
                        {showProjectsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
                        {!projectsLoading && !showProjectsLoadingError &&
                            <div className={styles.porjectsForm}>
                                <Button className="mb-3" onClick={() => {chosenProjectsArray = projects; setChosenProjects(chosenProjectsArray); setValue(name, chosenProjectsArray)}}>
                                    Выбрать все
                                </Button>
                                <div>
                                    {projects.length > 0 ?
                                    projectsGrid
                                    : "Вы не добавили регионы"}
                                </div>
                            </div>
                        }
                        <Form.Control.Feedback type="invalid">
                            {error?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Card.Body>                        
          }
        </Card>
    );
}

export default ChooseProjectsField;