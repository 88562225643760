import { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Region as RegionModel } from "../../models/region";
import * as RegionsApi from "../../network/regions-api";
import styles from "../../styles/StatisticsPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import * as ProjectsApi from "../../network/projects-api";
import * as TasksApi from "../../network/project-tasks-api";
import * as FirstStageApi from "../../network/project-first-stages-api";
import * as SecondStageApi from "../../network/project-second-stages-api";
import * as ThirdStageApi from "../../network/project-third-stages-api";
import { Project as ProjectModel } from "../../models/project";

interface StatisticsPageViewProps{
    role: string,
}

const StatisticsPageView = ({role}: StatisticsPageViewProps) => {
    interface ProjectStatistics{
        project: ProjectModel,
        deadlineProblems: number,
        tasksProblems: number,
        stagesProblems: number,
    }
    interface StatisticPiece{
        regionStatistic: {
            region: RegionModel,
            projectStatistics: ProjectStatistics[],
        },
        deadlineProblems: number,
        tasksProblems: number,
        stagesProblems: number,
    }
    const [statistics, setStatistics] = useState<StatisticPiece[]>([]);
    const [statisticsLoading, setStatisticsLoading] = useState(true);
    const [showStatisticsLoadingError, setShowStatisticsLoadingError] = useState(false);

    useEffect(() => {
        async function countStatistics() {
          try {
            setShowStatisticsLoadingError(false);
            setStatisticsLoading(true);
            //Load regions
            const regions = await RegionsApi.fetchRegions();
            //Initialize statistics
            const emptyProjectStatistics: ProjectStatistics[] = [];
            const statistics = regions.map((region) => {return {regionStatistic: {region: region, projectStatistics: emptyProjectStatistics,}, deadlineProblems: 0, tasksProblems: 0, stagesProblems: 0}});
            //Count statistics
            for (let statisticPiece of statistics) {
                const regionId = statisticPiece.regionStatistic.region._id;
                const projects = await ProjectsApi.fetchProjects({role, regionId});
                for (let project of projects) {
                    var deadlineProblems = 0;
                    var tasksProblems = 0;
                    var stagesProblems = 0;
                    const projectId = project._id;
                    //Check tasks
                    const tasks = await TasksApi.fetchProjectTasks({role, projectId});
                    for (let task of tasks) {
                        //Check status
                        if (task.status === "Нужна помощь") {
                            tasksProblems += 1;
                        }
                        //Check deadline
                        if (task.status !== "Готово") {
                            const TodayDate = new Date();
                            const TaskDate = new Date(task.deadline);
                            var diff = TaskDate.getTime() - TodayDate.getTime();
                            var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                            if (diffDays < 3 && diffDays > 0) {
                                deadlineProblems += 1;
                            } else if (diffDays <= 0) {
                                tasksProblems += 1;
                            }
                        }
                    }
                    //Check stages status
                    const firstStage = await FirstStageApi.fetchProjectFirstStage({projectId});
                    if (!firstStage){
                        statisticPiece.deadlineProblems += deadlineProblems;
                        statisticPiece.tasksProblems += tasksProblems;
                        statisticPiece.stagesProblems += stagesProblems;
                        statisticPiece.regionStatistic.projectStatistics.push({project: project, deadlineProblems: deadlineProblems, tasksProblems: tasksProblems, stagesProblems: stagesProblems});
                        continue;
                    }
                    if (firstStage.status==="NeedRework") {
                        stagesProblems += 1;
                    }
                    const secondStage = await SecondStageApi.fetchProjectSecondStage({projectId});
                    if (!secondStage){
                        statisticPiece.deadlineProblems += deadlineProblems;
                        statisticPiece.tasksProblems += tasksProblems;
                        statisticPiece.stagesProblems += stagesProblems;
                        statisticPiece.regionStatistic.projectStatistics.push({project: project, deadlineProblems: deadlineProblems, tasksProblems: tasksProblems, stagesProblems: stagesProblems});
                        continue;
                    }
                    if (secondStage.status==="NeedRework") {
                        stagesProblems += 1;
                    }
                    const thirdStage = await ThirdStageApi.fetchProjectThirdStage({projectId});
                    if (!thirdStage){
                        statisticPiece.deadlineProblems += deadlineProblems;
                        statisticPiece.tasksProblems += tasksProblems;
                        statisticPiece.stagesProblems += stagesProblems;
                        statisticPiece.regionStatistic.projectStatistics.push({project: project, deadlineProblems: deadlineProblems, tasksProblems: tasksProblems, stagesProblems: stagesProblems});
                        continue;
                    }
                    if (thirdStage.status==="NeedRework") {
                        stagesProblems += 1;
                    }
                    statisticPiece.deadlineProblems += deadlineProblems;
                    statisticPiece.tasksProblems += tasksProblems;
                    statisticPiece.stagesProblems += stagesProblems;
                    statisticPiece.regionStatistic.projectStatistics.push({project: project, deadlineProblems: deadlineProblems, tasksProblems: tasksProblems, stagesProblems: stagesProblems});
                }
            }
            setStatistics(statistics);
          } catch (error) {
            console.error(error);
            setShowStatisticsLoadingError(true);
          } finally {
            setStatisticsLoading(false);
          }
        }
        countStatistics();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let navigate = useNavigate();

    interface goToRegionStatisticProps {
        region: RegionModel,
        projectStatistics: ProjectStatistics[],
    }
    const goToRegionStatistic = (regionStatistic: goToRegionStatisticProps) =>{
        let path = `${regionStatistic.region._id}`;
        navigate(path, {state:{projectStatistics: regionStatistic.projectStatistics}});
        }

    const regionsRows= 
        <tbody>
          {statistics.map(statistic => (
            statistic.deadlineProblems + statistic.tasksProblems + statistic.stagesProblems> 0 ?
            <tr onClick={() => {goToRegionStatistic(statistic.regionStatistic)}}>
                <td className={styles.tableCellWithProblems}>
                    {statistic.regionStatistic.region.title}
                </td>
                <td className={styles.tableCellWithProblems}>
                    {statistic.deadlineProblems}
                </td>
                <td className={styles.tableCellWithProblems}>
                    {statistic.tasksProblems}
                </td>
                <td className={styles.tableCellWithProblems}>
                    {statistic.stagesProblems}
                </td>
            </tr>
            :
            <tr onClick={() => {goToRegionStatistic(statistic.regionStatistic)}}>
                <td className={styles.tableCellNoProblems}>
                    {statistic.regionStatistic.region.title}
                </td>
                <td className={styles.tableCellNoProblems}>
                    {statistic.deadlineProblems}
                </td>
                <td className={styles.tableCellNoProblems}>
                    {statistic.tasksProblems}
                </td>
                <td className={styles.tableCellNoProblems}>
                    {statistic.stagesProblems}
                </td>
            </tr>
          ))}
        </tbody>

    return (
        <> 
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1 className="mb-3">Статистика</h1>
            {statisticsLoading && <Spinner animation='border' variant='primary' />}
            {showStatisticsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!statisticsLoading && !showStatisticsLoadingError && 
                <Table bordered hover className={styles.tableStyle}>
                    <thead>
                        <tr >
                            <th className={styles.headingRegionCell}>Регион</th>
                            <th className={styles.headingProblemsCell}>Дедлайны</th>
                            <th className={styles.headingProblemsCell}>Задачи</th>
                            <th className={styles.headingProblemsCell}>Этапы</th>
                        </tr>
                    </thead>
                    {statistics.length > 0 ? 
                        regionsRows
                        :
                        <tr>
                            <td colSpan={2}>
                                Регионы ещё не добавлены
                            </td>
                        </tr>}
                </Table>
            }
        </>
    );
}

export default StatisticsPageView;