import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Region as RegionModel } from "../../models/region";
import * as RegionsApi from "../../network/regions-api";
import styles from "../../styles/RegionsPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import AddEditRegionDialog from "./AddEditRegionDialog";
import Region from "./Region";

const RegionsPageView = () => {
    const [regions, setRegions] = useState<RegionModel[]>([]);
    const [regionsLoading, setRegionsLoading] = useState(true);
    const [showRegionsLoadingError, setShowRegionsLoadingError] = useState(false);

    const [showAddRegionDialog, setShowAddRegionDialog] = useState(false);
    const [regionToEdit, setRegionToEdit] = useState<RegionModel|null>(null);

    useEffect(() => {
        async function loadRegions() {
          try {
            setShowRegionsLoadingError(false);
            setRegionsLoading(true);
            const regions = await RegionsApi.fetchRegions();
            setRegions(regions);
          } catch (error) {
            console.error(error);
            setShowRegionsLoadingError(true);
          } finally {
            setRegionsLoading(false);
          }
        }
        loadRegions();
      }, []);

    async function deleteRegion(region:RegionModel) {
        try {
          await RegionsApi.deleteRegion(region._id)
          setRegions(regions.filter(existingRegion => existingRegion._id !== region._id));
        } catch (error) {
          console.error(error);
          alert(error);
        }
      }

    let navigate = useNavigate();
    const goToRegion = (region: RegionModel) =>{
        let path = `${region._id}`;
        navigate(path);
        }

    const regionsGrid = 
        <Row xs={1} md={2} xl={3} className={`g-4 ${styles.regionsGrid}`}>
          {regions.map(region => (
            <Col key={region._id}>
              <Region
                region={region} 
                className={styles.region}
                onRegionClicked={goToRegion}
                onUpdateClicked={setRegionToEdit}
                onDeleteRegionClicked={deleteRegion}
                />
            </Col>
          ))}
        </Row>

    return (
        <> 
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Регионы</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={() => setShowAddRegionDialog(true)}>
                <FaPlus />
                Добавить регион
            </Button>
            {regionsLoading && <Spinner animation='border' variant='primary' />}
            {showRegionsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!regionsLoading && !showRegionsLoadingError && 
                <>
                    {
                    regions.length > 0
                        ? regionsGrid
                        : <p>Регионы ещё не добавлены</p>
                    }  
                </>
                }
                { showAddRegionDialog &&
                <AddEditRegionDialog
                onDismiss={() => setShowAddRegionDialog(false)}
                onRegionSaved={(newRegion) => {
                    setRegions([...regions, newRegion]);
                    setShowAddRegionDialog(false);
                }}
                />
                }
                { regionToEdit &&
                <AddEditRegionDialog
                    regionToEdit={regionToEdit}
                    onDismiss={() => setRegionToEdit(null)}
                    onRegionSaved={(updatedRegion) => {
                        setRegions(regions.map(existingRegion => existingRegion._id === updatedRegion._id ? updatedRegion : existingRegion))
                        setRegionToEdit(null);
                        }
                    }
                />
                }
        </>
    );
}

export default RegionsPageView;