import { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { Value } from "react-calendar/dist/cjs/shared/types";
import { FieldError, RegisterOptions, UseFormRegister, UseFormSetValue } from "react-hook-form";


interface TextInputFieldProps {
    name: string,
    label: string,
    date?: string
    register: UseFormRegister<any>,
    setValue: UseFormSetValue<any>
    registerOptions?: RegisterOptions,
    error?: FieldError,
    [x: string]: any,
}

const CalendarInputField = ({name, label, date, register, setValue, registerOptions, error, ...props}: TextInputFieldProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [calendarDate, setDate] = useState<Date>(new Date());
    const [calendarLoading, setCalendarLoading] = useState(true);
    const [showCalendarLoadingError, setShowCalendarLoadingError] = useState(false);

    useEffect(() => {
        try {
            setShowCalendarLoadingError(false);
            setCalendarLoading(true);
            if (date) {
                setDate(new Date(date));
            } else {
                setDate(new Date())
            }
        } catch (error){
            console.error(error);
            setShowCalendarLoadingError(true);
        } finally {
            setCalendarLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function registerDate (e: Value) {
        if (e) {
            setValue(name,  e.toString());
        } else {
            //Error
        }
        
    }
    return (
        <>
            {calendarLoading && <Spinner animation='border' variant='primary' />}
            {showCalendarLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!calendarLoading && !showCalendarLoadingError && 
                <Form.Group className="mb-3" controlId={name + "-input"}>
                    <Form.Label>{label}</Form.Label>
                    <Calendar value={date} onChange={(e) => registerDate(e)} selectRange={false} />
                    <Form.Control.Feedback type="invalid">
                        {error?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            }
        </>
    );
}

export default CalendarInputField;