// import logo from './logo.svg';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import { AdministratorProtectedRoute, ObserverCuratorProtectedRoute, TeacherCuratorProtectedRoute } from './components/ProtectedRoute';
import { Administrator } from './models/administrator';
import { ObserverCurator } from './models/observer-curator';
import { TeacherCurator } from './models/teacher-curator';
import * as AdministratorApi from "./network/administrators-api";
import * as ObserverCuratorApi from "./network/observer-curator-api";
import * as TeacherCuratorApi from "./network/teacher-curator-api";
import AdministratorAuthPage from './pages/AdministratorAuthPage';
import AdministratorCabinetPage from './pages/AdministratorCabinetPage';
import AdministratorProjectPage from './pages/AdministratorProjectPage';
import AdministratorProjectStagesPage from './pages/AdministratorProjectStagesPage';
import AdministratorProjectTasksPage from './pages/AdministratorProjectTasksPage';
import AdministratorProjectsPage from './pages/AdministratorProjectsPage';
import AdministratorRegionsPage from './pages/AdministratorRegionsPage';
import AdministratorAllProjectTasksPage from './pages/AdministrstorAllTasksPage';
import AdministratorPersonalTasksPage from './pages/AdministrstorPersonalTasksPage';
import AuthPage from './pages/AuthPage';
import NotFoundPage from './pages/NotFoundPage';
import ObserverCuratorAuthPage from './pages/ObserverCuratorAuthPage';
import ObserverCuratorCabinetPage from './pages/ObserverCuratorCabinetPage';
import ObserverCuratorPersonalTasksPage from './pages/ObserverCuratorPersonalTasksPage';
import ObserverCuratorProjectPage from './pages/ObserverCuratorProjectPage';
import ObserverCuratorProjectTasksPage from './pages/ObserverCuratorProjectTasksPage';
import ObserverCuratorProjectsPage from './pages/ObserverCuratorProjectsPage';
import PrivacyPage from './pages/PrivacyPage';
import TeacherCuratorAuthPage from './pages/TeacherCuratorAuthPage';
import TeacherCuratorCabinetPage from './pages/TeacherCuratorCabinetPage';
import TeacherCuratorPersonalTasksPage from './pages/TeacherCuratorPersonalTasksPage';
import TeacherCuratorProjectPage from './pages/TeacherCuratorProjectPage';
import TeacherCuratorProjectTasksPage from './pages/TeacherCuratorProjectTasksPage';
import TeacherCuratorProjectsPage from './pages/TeacherCuratorProjectsPage';
import styles from "./styles/App.module.css";
import TeacherCuratorProjectStagesPage from './pages/TeacherCuratorProjectStagesPage';
import ObserverCuratorProjectStagesPage from './pages/ObserverCuratorProjectStagesPage';
import AdministratorStatisticsPage from './pages/AdministratorStatisticsPage';
import AdministratorStatisticsRegionPage from './pages/AdministratorStatisticsRegionPage';
import TeacherCuratorStatisticsPage from './pages/TeacherCuratorStatisticsPage';
import ObserverCuratorStatisticsPage from './pages/ObserverCuratorStatisticsPage';
import AdministratorNotificationsPage from './pages/AdministratorNotificationsPage';
import TeacherCuratorNotificationsPage from './pages/TeacherCuratorNotificationsPage';
import ObserverCuratorNotificationsPage from './pages/ObserverCuratorNotificationsPage';
import AdministratorUsersPage from './pages/AdministratorUsersPage';
import AdministratorTeacherCuratorsPage from './pages/AdministratorTeacherCuratorsPage';
import AdministratorObserverCuratorsPage from './pages/AdministratorObserverCuratorsPage';

function App() {
  
  const [loggedInUser, setLoggedInUser] = useState<Administrator|TeacherCurator|ObserverCurator|null>(null);

  useEffect(() => {
    async function fetchLoggedInAdministrator() {
      try {
        const administrator = await AdministratorApi.getLoggedAdministrator();
        if (administrator){
          setLoggedInUser(administrator);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchLoggedInAdministrator();
  }, []);
  useEffect(() => {
    async function fetchLoggedInTeacherCurator() {
      try {
        const teacherCurator = await TeacherCuratorApi.getLoggedTeacherCurator();
        if (teacherCurator){
          setLoggedInUser(teacherCurator);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchLoggedInTeacherCurator();
  }, []);
  useEffect(() => {
    async function fetchLoggedInObserverCurator() {
      try {
        const observerCurator = await ObserverCuratorApi.getLoggedObserverCurator();
        if (observerCurator){
          setLoggedInUser(observerCurator);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchLoggedInObserverCurator();
  }, []);

  return (
    <BrowserRouter>
      <div>
        <NavBar
          loggedInUser={loggedInUser}
          onLogoutSuccessful={() => setLoggedInUser(null)}
        />
        <Container className={styles.pageContainer}>
          <Routes>
            <Route
              path='/'
              element={<AuthPage/>}
            />
            <Route
              path='/administrator'
              element={<AdministratorAuthPage onLoginSuccessful={(administrator) => {setLoggedInUser(administrator)}}/>}
              />
            <Route
              path='/administrator/cabinet'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorCabinetPage/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/notifications'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorNotificationsPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/regions'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorRegionsPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/regions/:regionId'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorProjectsPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/regions/:regionId/:projectId'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorProjectPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/regions/:regionId/:projectId/stages'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorProjectStagesPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/regions/:regionId/:projectId/tasks'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorProjectTasksPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/project-tasks'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorAllProjectTasksPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/personal-tasks'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorPersonalTasksPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/statistics'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorStatisticsPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/statistics/:regionId'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorStatisticsRegionPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/users'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorUsersPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/users/teacher-curators'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorTeacherCuratorsPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/administrator/cabinet/users/observer-curators'
              element={
                <AdministratorProtectedRoute user={loggedInUser}>
                  <AdministratorObserverCuratorsPage loggedInAdministrator={loggedInUser}/>
                </AdministratorProtectedRoute>
              }
            />
            <Route
              path='/teacher-curator'
              element={<TeacherCuratorAuthPage onLoginSuccessful={(teacherCurator) => {setLoggedInUser(teacherCurator)}}/>}
            />
            <Route
              path='/teacher-curator/cabinet'
              element={
                <TeacherCuratorProtectedRoute user={loggedInUser}>
                  <TeacherCuratorCabinetPage/>
                </TeacherCuratorProtectedRoute>
              }
            />
            <Route
              path='/teacher-curator/cabinet/notifications'
              element={
                <TeacherCuratorProtectedRoute user={loggedInUser}>
                  <TeacherCuratorNotificationsPage loggedInTeacherCurator={loggedInUser}/>
                </TeacherCuratorProtectedRoute>
              }
            />
            <Route
              path='/teacher-curator/cabinet/projects/'
              element={
                <TeacherCuratorProtectedRoute user={loggedInUser}>
                  <TeacherCuratorProjectsPage loggedInTeacherCurator={loggedInUser}/>
                </TeacherCuratorProtectedRoute>
              }
            />
            <Route
              path='/teacher-curator/cabinet/projects/:projectId'
              element={
                <TeacherCuratorProtectedRoute user={loggedInUser}>
                  <TeacherCuratorProjectPage loggedInTeacherCurator={loggedInUser}/>
                </TeacherCuratorProtectedRoute>
              }
            />
            <Route
              path='/teacher-curator/cabinet/projects/:projectId/stages'
              element={
                <TeacherCuratorProtectedRoute user={loggedInUser}>
                  <TeacherCuratorProjectStagesPage loggedInTeacherCurator={loggedInUser}/>
                </TeacherCuratorProtectedRoute>
              }
            />
            <Route
              path='/teacher-curator/cabinet/projects/:projectId/tasks'
              element={
                <TeacherCuratorProtectedRoute user={loggedInUser}>
                  <TeacherCuratorProjectTasksPage loggedInTeacherCurator={loggedInUser}/>
                </TeacherCuratorProtectedRoute>
              }
            />
            <Route
              path='/teacher-curator/cabinet/personal-tasks/'
              element={
                <TeacherCuratorProtectedRoute user={loggedInUser}>
                  <TeacherCuratorPersonalTasksPage loggedInTeacherCurator={loggedInUser}/>
                </TeacherCuratorProtectedRoute>
              }
            />
            <Route
              path='/teacher-curator/cabinet/statistics'
              element={
                <TeacherCuratorProtectedRoute user={loggedInUser}>
                  <TeacherCuratorStatisticsPage loggedInTeacherCurator={loggedInUser}/>
                </TeacherCuratorProtectedRoute>
              }
            />
            <Route
              path='/observer-curator'
              element={<ObserverCuratorAuthPage onLoginSuccessful={(observerCurator) => {setLoggedInUser(observerCurator)}}/>}
            />
            <Route
              path='/observer-curator/cabinet'
              element={
                <ObserverCuratorProtectedRoute user={loggedInUser}>
                  <ObserverCuratorCabinetPage/>
                </ObserverCuratorProtectedRoute>
              }/>
            <Route
              path='/observer-curator/cabinet/notifications'
              element={
                <ObserverCuratorProtectedRoute user={loggedInUser}>
                  <ObserverCuratorNotificationsPage loggedInObserverCurator={loggedInUser}/>
                </ObserverCuratorProtectedRoute>
              }
            />
            <Route
              path='/observer-curator/cabinet/projects/'
              element={
                <ObserverCuratorProtectedRoute user={loggedInUser}>
                  <ObserverCuratorProjectsPage loggedInObserverCurator={loggedInUser}/>
                </ObserverCuratorProtectedRoute>
              }
            />
            <Route
              path='/observer-curator/cabinet/projects/:projectId'
              element={
                <ObserverCuratorProtectedRoute user={loggedInUser}>
                  <ObserverCuratorProjectPage loggedInObserverCurator={loggedInUser}/>
                </ObserverCuratorProtectedRoute>
              }
            />
            <Route
              path='/observer-curator/cabinet/projects/:projectId/stages'
              element={
                <ObserverCuratorProtectedRoute user={loggedInUser}>
                  <ObserverCuratorProjectStagesPage loggedInObserverCurator={loggedInUser}/>
                </ObserverCuratorProtectedRoute>
              }
            />
            <Route
              path='/observer-curator/cabinet/projects/:projectId/tasks'
              element={
                <ObserverCuratorProtectedRoute user={loggedInUser}>
                  <ObserverCuratorProjectTasksPage loggedInObserverCurator={loggedInUser}/>
                </ObserverCuratorProtectedRoute>
              }
            />
            <Route
              path='/observer-curator/cabinet/personal-tasks/'
              element={
                <ObserverCuratorProtectedRoute user={loggedInUser}>
                  <ObserverCuratorPersonalTasksPage loggedInObserverCurator={loggedInUser}/>
                </ObserverCuratorProtectedRoute>
              }
            />
            <Route
              path='/observer-curator/cabinet/statistics'
              element={
                <ObserverCuratorProtectedRoute user={loggedInUser}>
                  <ObserverCuratorStatisticsPage loggedInObserverCurator={loggedInUser}/>
                </ObserverCuratorProtectedRoute>
              }
            />
            <Route
              path='/privacy'
              element={<PrivacyPage />}
            />
            <Route
              path='*'
              element={<NotFoundPage/>}
            />
          </Routes>
        </Container>
      </div>
    </BrowserRouter>
  );
}

export default App;
