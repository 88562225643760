import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Notification as NotificationModel } from "../../models/notification";
import * as NotificationsApi from "../../network/notifications-api";
import styles from "../../styles/NotificationsPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import Notification from "./Notificitaion";

interface NotificationsPageViewProps{
    role: string,
}

const NotificationsPageView = ({role}: NotificationsPageViewProps) => {
    const [notifications, setNotifications] = useState<NotificationModel[]>([]);
    const [notificationsLoading, setNotificationsLoading] = useState(true);
    const [showNotificationsLoadingError, setShowNotificationsLoadingError] = useState(false);

    useEffect(() => {
        async function loadNotifications() {
          try {
            setShowNotificationsLoadingError(false);
            setNotificationsLoading(true);
            const notifications = await NotificationsApi.fetchNotifications({role: role});
            notifications.sort(function(a,b): any {
              return Date.parse(b.createdAt) - Date.parse(a.createdAt);
            });
            setNotifications(notifications);
          } catch (error) {
            console.error(error);
            setShowNotificationsLoadingError(true);
          } finally {
            setNotificationsLoading(false);
          }
        }
        loadNotifications();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let navigate = useNavigate();
    const goToTarget = async (notification: NotificationModel) =>{
        const updatedNotification = await NotificationsApi.updateNotificationStatus(notification._id);
        notifications.map(existingNotification => existingNotification._id === updatedNotification._id ? updatedNotification : existingNotification)
        switch (notification.type) {
            case "11":
            case "12":
            case "13":{
                let path = `/teacher-curator/cabinet/projects/${notification.projectId}/`;
                navigate(path);
                break;
            }
            case "21":
            case "22":
            case "23":{
                let path = `/teacher-curator/cabinet/projects/${notification.projectId}/`;
                navigate(path);
                break;
            }
            case "31":
            case "32":
            case "33":{
                let path = `/teacher-curator/cabinet/personal-tasks/`;
                navigate(path);
                break;
            }
        }
        
        }

    const notificationsGrid = 
        <Row xs={1} md={2} xl={3} className={`g-4 ${styles.notificationsGrid}`}>
          {notifications.map(notification => (
            <Col key={notification._id}>
              <Notification
                notification={notification}
                onNotificationClicked={goToTarget}
                className={styles.notification}
                />
            </Col>
          ))}
        </Row>

    return (
        <> 
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1 className="mb-3">Уведомления</h1>
            {notificationsLoading && <Spinner animation='border' variant='primary' />}
            {showNotificationsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!notificationsLoading && !showNotificationsLoadingError && 
                <>
                    {
                    notifications.length > 0
                        ? notificationsGrid
                        : <p>Уведомлений нет</p>
                    }  
                </>
            }
        </>
    );
}

export default NotificationsPageView;