import { Button, Form, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Project } from "../../models/project";
import * as ProjectsApi from "../../network/projects-api";
import ChooseObserverCuratorField from "./form/ChooseObserverCuratorField";
import ChooseTeacherCuratorField from "./form/ChooseTeacherCuratorField";
import TextInputField from "./form/TextInputField";

interface AddEditProjectDialogProps {
    regionId: string,
    projectToEdit?: Project,
    onDismiss: () => void,
    onProjectSaved: (project: Project) => void,
}


const AddEditProjectDialog = ({regionId, projectToEdit, onDismiss, onProjectSaved}: AddEditProjectDialogProps) => {
    interface ProjectFormInput{
        title: string,
        text: string,
        regionId: string,
        filerTeacherCurator: string,
        teacherCuratorId: string,
        univerObserverCuratorId: string,
        roivObserverCuratorId: string,
        regionObserverCuratorId: string,
    }


    const { register, handleSubmit, setValue, formState : {errors, isSubmitting} } = useForm<ProjectFormInput>({
        defaultValues: {
            title: projectToEdit?.title || "",
            text: projectToEdit?.text || "",
            regionId: regionId,
            filerTeacherCurator: "",
            teacherCuratorId: projectToEdit?.teacherCuratorId || "",
            univerObserverCuratorId: projectToEdit?.univerObserverCuratorId || "",
            roivObserverCuratorId: projectToEdit?.roivObserverCuratorId || "",
            regionObserverCuratorId: projectToEdit?.regionObserverCuratorId || "",
        }
    });
    
    async function onSubmit(input:ProjectFormInput) {
        try {
            let projectResponse: Project;
            if (projectToEdit) {
                projectResponse = await ProjectsApi.updateProject(projectToEdit._id, input);
            } else {
                projectResponse = await ProjectsApi.createProject(input);
            }
            onProjectSaved(projectResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return ( 
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {projectToEdit ? "Редактировать проект" : "Добавить проект"}
                </Modal.Title>
            </Modal.Header>

            <ModalBody>
                <Form id="addEditProjectForm" onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField 
                        name="title"
                        label="Название"
                        type="text"
                        placeholder="Название"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.title}
                    />

                    <TextInputField 
                        name="text"
                        label="Описание"
                        as="textarea"
                        rows={3}
                        placeholder="Описание"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.text}
                    />
                </Form>
                { projectToEdit ?
                        <ChooseTeacherCuratorField
                            name="teacherCuratorId"
                            label="Педагог-куратор"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.teacherCuratorId}
                            curatorId={projectToEdit.teacherCuratorId}
                            setValue={setValue}
                        />
                        :
                        <ChooseTeacherCuratorField
                            name="teacherCuratorId"
                            label="Педагог-куратор"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.teacherCuratorId}
                            setValue={setValue}
                        />
                    }
                    { projectToEdit ?
                        <ChooseObserverCuratorField
                            name="univerObserverCuratorId"
                            label="Куратор ВУЗ/ССУЗ"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.univerObserverCuratorId}
                            curatorId={projectToEdit.univerObserverCuratorId}
                            setValue={setValue}
                        />
                        :
                        <ChooseObserverCuratorField
                            name="univerObserverCuratorId"
                            label="Куратор ВУЗ/ССУЗ"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.univerObserverCuratorId}
                            setValue={setValue}
                        />
                    }
                    { projectToEdit ?
                        <ChooseObserverCuratorField
                            name="roivObserverCuratorId"
                            label="Куратор РОИВ"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.roivObserverCuratorId}
                            curatorId={projectToEdit.roivObserverCuratorId}
                            setValue={setValue}
                        />
                        :
                        <ChooseObserverCuratorField
                            name="roivObserverCuratorId"
                            label="Куратор РОИВ"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.roivObserverCuratorId}
                            setValue={setValue}
                        />
                    }
                    { projectToEdit ?
                        <ChooseObserverCuratorField
                            name="regionObserverCuratorId"
                            label="Куратор региона"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.regionObserverCuratorId}
                            curatorId={projectToEdit.regionObserverCuratorId}
                            setValue={setValue}
                        />
                        :
                        <ChooseObserverCuratorField
                            name="regionObserverCuratorId"
                            label="Куратор региона"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.regionObserverCuratorId}
                            setValue={setValue}
                        />
                    }
            </ModalBody>

            <ModalFooter>
                <Button
                type="submit"
                form="addEditProjectForm"
                disabled={isSubmitting}
                >
                    Сохранить
                </Button>
            </ModalFooter>
        </Modal>
     );
}
 
export default AddEditProjectDialog;