import { ProjectTask } from "../models/project-task"
import { fetchData } from "../utils/fetchData";


interface RequestProps {
    role: string,
    projectId?:string,
}

export async function fetchProjectTasks(requestProps: RequestProps): Promise<ProjectTask[]> {
    const response = await fetchData("/api/project-tasks/project", 
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}

interface fetchProjectTasksByUserProps {
    role: string,
}

export async function fetchProjectTasksByUser(requestProps: fetchProjectTasksByUserProps): Promise<ProjectTask[]> {
    const response = await fetchData("/api/project-tasks/user", 
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}

interface fetchProjectTaskProps {
    role: string,
    taskId: string,
}

export async function fetchProjectTask(requestProps: fetchProjectTaskProps): Promise<ProjectTask> {
    const response = await fetchData("/api/project-tasks/"+requestProps.taskId, 
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}

export interface ProjectTaskInput {
    projectId: string,
    teacherCuratorId: string,
    univerObserverCuratorId: string,
    roivObserverCuratorId: string,
    regionObserverCuratorId: string,
    status: string,
    title: string,
    text: string,
    deadline: string,
}

export async function createProjectTask(task: ProjectTaskInput): Promise<ProjectTask> {
    const response = await fetchData("/api/project-tasks",
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(task),
    });
    return response.json();
}

export async function updateProjectTask(taskId:string, task: ProjectTaskInput): Promise<ProjectTask> {
    const response = await fetchData("/api/project-tasks/" + taskId,
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(task),
    })
    return response.json();
}

interface updateProjectTaskStatusProps{
    status: string,
    statusDescription:string,
}

export async function updateProjectTaskStatus(taskId:string, taskStatusInfo: updateProjectTaskStatusProps): Promise<ProjectTask> {
    const response = await fetchData("/api/project-tasks/" + taskId + "/status",
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(taskStatusInfo),
    })
    return response.json();
}

export async function deleteProjectTask(taskId:string) {
    await fetchData("/api/project-tasks/"+ taskId, { method: "DELETE"});
}