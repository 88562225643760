import { Button, Card, Spinner } from "react-bootstrap";
import styles from "../../styles/ProjectStagesPage.module.css";
import stylesUtils from "../../styles/utils.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProjectFirstStage } from "../../models/project-first-stage";
import { ProjectSecondStage } from "../../models/project-second-stage";
import { ProjectThirdStage } from "../../models/project-third-stage";
import { ProjectFourthStage } from "../../models/project-fourth-stage";
import { ProjectFifthStage } from "../../models/project-fifth-stage";
import * as FirstStageApi from "../../network/project-first-stages-api";
import * as SecondStageApi from "../../network/project-second-stages-api";
import * as ThirdStageApi from "../../network/project-third-stages-api";
import * as FourthStageApi from "../../network/project-fourth-stages-api";
import * as FifthStageApi from "../../network/project-fifth-stages-api";
import ChangeFirstStateDialog from "./ChangeFirstStageDialog";
import ChangeSecondStateDialog from "./ChangeSecondStageDialog";
import ChangeThirdStateDialog from "./ChangeThirdStageDialog";
import ChangeFourthStateDialog from "./ChangeFourthStageDialog";
import ChangeFifthStateDialog from "./ChangeFifthStageDialog";


interface ProjectStagesPageViewProps {
    role: string,
    projectId: string,
}

const ProjectStagesPageView = ({role, projectId}: ProjectStagesPageViewProps) => {
    const [firstStage, setFirstStage] = useState<ProjectFirstStage|null>(null);
    const [secondStage, setSecondStage] = useState<ProjectSecondStage|null>(null);
    const [thirdStage, setThirdStage] = useState<ProjectThirdStage|null>(null);
    const [fourthStage, setFourthStage] = useState<ProjectFourthStage|null>(null);
    const [fifthStage, setFifthStage] = useState<ProjectFifthStage|null>(null);

    const [stagesLoading, setStagesLoading] = useState(true);
    const [showStagesLoadingError, setShowStagesLoadingError] = useState(false);

    const [editFirstStage, setEditFirstStage] = useState<ProjectFirstStage|null>(null);
    const [editSecondStage, setEditSecondStage] = useState<ProjectSecondStage|null>(null);
    const [editThirdStage, setEditThirdStage] = useState<ProjectThirdStage|null>(null);
    const [editFourthStage, setEditFourthStage] = useState<ProjectThirdStage|null>(null);
    const [editFifthStage, setEditFifthStage] = useState<ProjectThirdStage|null>(null);
    
    useEffect(() => {
        async function loadStages() {
          try {
            setShowStagesLoadingError(false);
            setStagesLoading(true);

            const firstStageData = await FirstStageApi.fetchProjectFirstStage({projectId});
            if (!firstStageData) {
                return;
            }
            setFirstStage(firstStageData);

            const secondStageData = await SecondStageApi.fetchProjectSecondStage({projectId});
            if (!secondStageData) {
                return;
            }
            setSecondStage(secondStageData);

            const thirdStageData = await ThirdStageApi.fetchProjectThirdStage({projectId});
            if (!thirdStageData) {
                return;
            }
            setThirdStage(thirdStageData);

            const fourthStageData = await FourthStageApi.fetchProjectFourthStage({projectId});
            if (!fourthStageData) {
                return;
            }
            setFourthStage(fourthStageData);

            const fifthStageData = await FifthStageApi.fetchProjectFifthStage({projectId});
            if (!fifthStageData) {
                return;
            }
            setFifthStage(fifthStageData);
          } catch (error) {
            console.error(error);
            setShowStagesLoadingError(true);
          } finally {
            setStagesLoading(false);
          }
        }
        loadStages();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let navigate = useNavigate();

    const firstStageCard = (stage: ProjectFirstStage|null) => {
        if(!stage) {
            return(
                <Card className={`mb-3 ${styles.stageCardInWork}`}>
                    <Card.Title>Этап 1</Card.Title>
                    <Card.Text>{"Cтарт работы: знакомство команды и кураторов, знакомство со школой, первичная документация."}</Card.Text>
                    <Card.Text>{"Статус: В работе"}</Card.Text>
                </Card>
            )
        }
        switch (stage.status){
            case "NeedRework" : {
                return (
                    <Card onClick={() => {setEditFirstStage(stage)}} className={`mb-3 ${styles.stageCardNeedRework}`}>
                        <Card.Title>Этап 1</Card.Title>
                        <Card.Text>{"Cтарт работы: знакомство команды и кураторов, знакомство со школой, первичная документация."}</Card.Text>
                        <Card.Text>{"Статус: Требуются доработки"}</Card.Text>
                    </Card>
                )
            }
            case "NeedCheck" : {
                return (
                    <Card onClick={() => {setEditFirstStage(stage)}} className={`mb-3 ${styles.stageCardNeedCheck}`}>
                        <Card.Title>Этап 1</Card.Title>
                        <Card.Text>{"Cтарт работы: знакомство команды и кураторов, знакомство со школой, первичная документация."}</Card.Text>
                        <Card.Text>{"Статус: Требуется проверка"}</Card.Text>
                    </Card>
                )
            }
            case "Done" : {
                return(
                    <Card onClick={() => {setEditFirstStage(stage)}} className={`mb-3 ${styles.stageCardDone}`}>
                        <Card.Title>Этап 1</Card.Title>
                        <Card.Text>{"Cтарт работы: знакомство команды и кураторов, знакомство со школой, первичная документация."}</Card.Text>
                        <Card.Text>{"Статус: Выполнено"}</Card.Text>
                    </Card>
                );
            }
        }
    };

    const secondStageCard = (stage: ProjectSecondStage|null, prevStage: ProjectFirstStage|null) => {
        if(!stage) {
            if (prevStage?.status === "Done") {
                return(
                    <Card className={`mb-3 ${styles.stageCardInWork}`}>
                        <Card.Title>Этап 2</Card.Title>
                        <Card.Text>{"Проведение соучаствующего исследования и отправка отчета."}</Card.Text>
                        <Card.Text>{"Статус: В работе"}</Card.Text>
                    </Card>
                )
            }
            return(
                <Card className={`mb-3 ${styles.stageCardNotOpen}`}>
                    <Card.Title>Этап 2</Card.Title>
                    <Card.Text>{"Проведение соучаствующего исследования и отправка отчета."}</Card.Text>
                    <Card.Text>{"Статус: Закрыт"}</Card.Text>
                </Card>
            );
        }
        switch (stage.status){
            case "NeedRework" : {
                return (
                    <Card onClick={() => {setEditSecondStage(stage)}} className={`mb-3 ${styles.stageCardNeedRework}`}>
                        <Card.Title>Этап 2</Card.Title>
                        <Card.Text>{"Проведение соучаствующего исследования и отправка отчета."}</Card.Text>
                        <Card.Text>{"Статус: Требуются доработки"}</Card.Text>
                    </Card>
                )
            }
            case "NeedCheck" : {
                return (
                    <Card onClick={() => {setEditSecondStage(stage)}} className={`mb-3 ${styles.stageCardNeedCheck}`}>
                        <Card.Title>Этап 2</Card.Title>
                        <Card.Text>{"Проведение соучаствующего исследования и отправка отчета."}</Card.Text>
                        <Card.Text>{"Статус: Требуется проверка"}</Card.Text>
                    </Card>
                )
            }
            case "Done" : {
                return(
                    <Card onClick={() => {setEditSecondStage(stage)}} className={`mb-3 ${styles.stageCardDone}`}>
                        <Card.Title>Этап 2</Card.Title>
                        <Card.Text>{"Проведение соучаствующего исследования и отправка отчета."}</Card.Text>
                        <Card.Text>{"Статус: Выполнено"}</Card.Text>
                    </Card>
                );
            }
        }
    };

    const thirdStageCard = (stage: ProjectThirdStage|null, prevStage: ProjectSecondStage|null) => {
        if(!stage) {
            if (prevStage?.status === "Done") {
                return(
                    <Card className={`mb-3 ${styles.stageCardInWork}`}>
                        <Card.Title>Этап 3</Card.Title>
                        <Card.Text>{"Разработка эскизного дизайн-проекта и его предзащита. Внесение корректировок."}</Card.Text>
                        <Card.Text>{"Статус: В работе"}</Card.Text>
                    </Card>
                )
            }
            return(
                <Card className={`mb-3 ${styles.stageCardNotOpen}`}>
                    <Card.Title>Этап 3</Card.Title>
                    <Card.Text>{"Разработка эскизного дизайн-проекта и его предзащита. Внесение корректировок."}</Card.Text>
                    <Card.Text>{"Статус: Закрыт"}</Card.Text>
                </Card>
            );
        }
        switch (stage.status){
            case "NeedRework" : {
                return (
                    <Card onClick={() => {setEditThirdStage(stage)}} className={`mb-3 ${styles.stageCardNeedRework}`}>
                        <Card.Title>Этап 3</Card.Title>
                        <Card.Text>{"Разработка эскизного дизайн-проекта и его предзащита. Внесение корректировок."}</Card.Text>
                        <Card.Text>{"Статус: Требуются доработки"}</Card.Text>
                    </Card>
                )
            }
            case "NeedCheck" : {
                return (
                    <Card onClick={() => {setEditThirdStage(stage)}} className={`mb-3 ${styles.stageCardNeedCheck}`}>
                        <Card.Title>Этап 3</Card.Title>
                        <Card.Text>{"Разработка эскизного дизайн-проекта и его предзащита. Внесение корректировок."}</Card.Text>
                        <Card.Text>{"Статус: Требуется проверка"}</Card.Text>
                    </Card>
                )
            }
            case "Done" : {
                return(
                    <Card onClick={() => {setEditThirdStage(stage)}} className={`mb-3 ${styles.stageCardDone}`}>
                        <Card.Title>Этап 3</Card.Title>
                        <Card.Text>{"Разработка эскизного дизайн-проекта и его предзащита. Внесение корректировок."}</Card.Text>
                        <Card.Text>{"Статус: Выполнено"}</Card.Text>
                    </Card>
                );
            }
        }
    };

    const fourthStageCard = (stage: ProjectFourthStage|null, prevStage: ProjectThirdStage|null) => {
        if(!stage) {
            if (prevStage?.status === "Done") {
                return(
                    <Card className={`mb-3 ${styles.stageCardInWork}`}>
                        <Card.Title>Этап 4</Card.Title>
                        <Card.Text>{"Разбор с куратором проекта. Участие в Мастерской (акселераторе)."}</Card.Text>
                        <Card.Text>{"Статус: В работе"}</Card.Text>
                    </Card>
                )
            }
            return(
                <Card className={`mb-3 ${styles.stageCardNotOpen}`}>
                    <Card.Title>Этап 4</Card.Title>
                    <Card.Text>{"Разбор с куратором проекта. Участие в Мастерской (акселераторе)."}</Card.Text>
                    <Card.Text>{"Статус: Закрыт"}</Card.Text>
                </Card>
            );
        }
        switch (stage.status){
            case "NeedRework" : {
                return (
                    <Card onClick={() => {setEditFourthStage(stage)}} className={`mb-3 ${styles.stageCardNeedRework}`}>
                        <Card.Title>Этап 4</Card.Title>
                        <Card.Text>{"Разбор с куратором проекта. Участие в Мастерской (акселераторе)."}</Card.Text>
                        <Card.Text>{"Статус: Требуются доработки"}</Card.Text>
                    </Card>
                )
            }
            case "NeedCheck" : {
                return (
                    <Card onClick={() => {setEditFourthStage(stage)}} className={`mb-3 ${styles.stageCardNeedCheck}`}>
                        <Card.Title>Этап 4</Card.Title>
                        <Card.Text>{"Разбор с куратором проекта. Участие в Мастерской (акселераторе)."}</Card.Text>
                        <Card.Text>{"Статус: Требуется проверка"}</Card.Text>
                    </Card>
                )
            }
            case "Done" : {
                return(
                    <Card onClick={() => {setEditFourthStage(stage)}} className={`mb-3 ${styles.stageCardDone}`}>
                        <Card.Title>Этап 4</Card.Title>
                        <Card.Text>{"Разбор с куратором проекта. Участие в Мастерской (акселераторе)."}</Card.Text>
                        <Card.Text>{"Статус: Выполнено"}</Card.Text>
                    </Card>
                );
            }
        }
    };

    const fifthStageCard = (stage: ProjectFifthStage|null, prevStage: ProjectFourthStage|null) => {
        if(!stage) {
            if (prevStage?.status === "Done") {
                return(
                    <Card className={`mb-3 ${styles.stageCardInWork}`}>
                        <Card.Title>Этап 5</Card.Title>
                        <Card.Text>{"Доработка проекта и его защита на уровне региона."}</Card.Text>
                        <Card.Text>{"Статус: В работе"}</Card.Text>
                    </Card>
                )
            }
            return(
                <Card className={`mb-3 ${styles.stageCardNotOpen}`}>
                    <Card.Title>Этап 5</Card.Title>
                    <Card.Text>{"Доработка проекта и его защита на уровне региона."}</Card.Text>
                    <Card.Text>{"Статус: Закрыт"}</Card.Text>
                </Card>
            );
        }
        switch (stage.status){
            case "NeedRework" : {
                return (
                    <Card onClick={() => {setEditFifthStage(stage)}} className={`mb-3 ${styles.stageCardNeedRework}`}>
                        <Card.Title>Этап 5</Card.Title>
                        <Card.Text>{"Доработка проекта и его защита на уровне региона."}</Card.Text>
                        <Card.Text>{"Статус: Требуются доработки"}</Card.Text>
                    </Card>
                )
            }
            case "NeedCheck" : {
                return (
                    <Card onClick={() => {setEditFifthStage(stage)}} className={`mb-3 ${styles.stageCardNeedCheck}`}>
                        <Card.Title>Этап 5</Card.Title>
                        <Card.Text>{"Доработка проекта и его защита на уровне региона."}</Card.Text>
                        <Card.Text>{"Статус: Требуется проверка"}</Card.Text>
                    </Card>
                )
            }
            case "Done" : {
                return(
                    <Card onClick={() => {setEditFifthStage(stage)}} className={`mb-3 ${styles.stageCardDone}`}>
                        <Card.Title>Этап 5</Card.Title>
                        <Card.Text>{"Доработка проекта и его защита на уровне региона."}</Card.Text>
                        <Card.Text>{"Статус: Выполнено"}</Card.Text>
                    </Card>
                );
            }
        }
    };

    return(
        <>
        <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Этапы</h1>
            {stagesLoading && <Spinner animation='border' variant='primary' />}
            {showStagesLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!stagesLoading && !showStagesLoadingError &&
                <div>
                    <>{firstStageCard(firstStage)}</>
                    <>{secondStageCard(secondStage, firstStage)}</>
                    <>{thirdStageCard(thirdStage, secondStage)}</>
                    <>{fourthStageCard(fourthStage, thirdStage)}</>
                    <>{fifthStageCard(fifthStage, fourthStage)}</>
                </div>
            }
            {editFirstStage &&
                <ChangeFirstStateDialog
                    stage={editFirstStage}
                    onDismiss={() => setEditFirstStage(null)}
                    onStageSaved={setFirstStage}
                />
            }
            {editSecondStage &&
                <ChangeSecondStateDialog
                    stage={editSecondStage}
                    onDismiss={() => setEditSecondStage(null)}
                    onStageSaved={setSecondStage}
                />
            }
            {editThirdStage &&
                <ChangeThirdStateDialog
                    stage={editThirdStage}
                    onDismiss={() => setEditThirdStage(null)}
                    onStageSaved={setThirdStage}
                />
            }
            {editFourthStage &&
                <ChangeFourthStateDialog
                    stage={editFourthStage}
                    onDismiss={() => setEditFourthStage(null)}
                    onStageSaved={setFourthStage}
                />
            }
            {editFifthStage &&
                <ChangeFifthStateDialog
                    stage={editFifthStage}
                    onDismiss={() => setEditFifthStage(null)}
                    onStageSaved={setFifthStage}
                />
            }
        </>
    );
}

export default ProjectStagesPageView;