import { useEffect, useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ProjectTask } from "../../models/project-task";
import { fetchProject } from "../../network/projects-api";
import * as TasksApi from "../../network/project-tasks-api";
import * as NotificationsApi from "../../network/notifications-api";
import { ProjectTaskInput } from "../../network/project-tasks-api";
import CalendarInputField from "./form/CalendarInputField";
import TextInputField from "./form/TextInputField";
import { Project } from "../../models/project";

interface AddEditTaskDialogProps {
    projectId: string,
    role: string,
    taskToEdit?: ProjectTask,
    onDismiss: () => void,
    onTaskSaved: (Task: ProjectTask) => void,
}


const AddEditTaskDialog = ({projectId, role, taskToEdit, onDismiss, onTaskSaved}: AddEditTaskDialogProps) => {
    const [projectLoading, setProjectLoading] = useState(true);
    const [showProjectLoadingError, setShowProjectLoadingError] = useState(false);
    const [project, setProject] = useState<Project|null>(null);
    
    const { register, handleSubmit, setValue, formState : {errors, isSubmitting} } = useForm<ProjectTaskInput>({
        defaultValues: {
            projectId: taskToEdit?.projectId || projectId,
            teacherCuratorId: taskToEdit?.teacherCuratorId || "",
            univerObserverCuratorId: taskToEdit?.univerObserverCuratorId || "",
            roivObserverCuratorId: taskToEdit?.roivObserverCuratorId || "",
            regionObserverCuratorId: taskToEdit?.regionObserverCuratorId || "",
            status: taskToEdit?.status || "В работе",
            title: taskToEdit?.title || "",
            text: taskToEdit?.text || "",
            deadline: taskToEdit?.deadline || "",
        }
    });

    useEffect(() => {
        async function loadProject() {
          try {
            setShowProjectLoadingError(false);
            setProjectLoading(true);
            const projectData = await fetchProject({role, projectId});
            setProject(projectData);
            setValue("teacherCuratorId", projectData.teacherCuratorId);
            setValue("univerObserverCuratorId", projectData.univerObserverCuratorId);
            setValue("roivObserverCuratorId", projectData.roivObserverCuratorId);
            setValue("regionObserverCuratorId", projectData.regionObserverCuratorId);
          } catch (error) {
            console.error(error);
            setShowProjectLoadingError(true);
          } finally {
            setProjectLoading(false);
          }
        }
        loadProject();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    async function onSubmit(input:ProjectTaskInput) {
        try {
            let TaskResponse: ProjectTask;
            if (taskToEdit) {
                TaskResponse = await TasksApi.updateProjectTask(taskToEdit._id, input);
            } else {
                TaskResponse = await TasksApi.createProjectTask(input);
                if (project) {
                    const notification = {
                        administratorId: project.administratorId, 
                        type: "11",
                        projectId: project._id,
                        teacherCuratorId: project.teacherCuratorId,
                        univerObserverCuratorId: project.univerObserverCuratorId,
                        roivObserverCuratorId: project.roivObserverCuratorId,
                        regionObserverCuratorId: project.regionObserverCuratorId,
                    }
                    await NotificationsApi.createNotification(notification);
                }
                
            }
            onTaskSaved(TaskResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return ( 
        <Modal show onHide={onDismiss}>
            {projectLoading && <Spinner animation='border' variant='primary' />}
            {showProjectLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!projectLoading && !showProjectLoadingError && 
                <>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {taskToEdit ? "Редактировать задачу" : "Добавить задачу"}
                    </Modal.Title>
                </Modal.Header>

                <ModalBody>
                    <Form id="addEditTaskForm" onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField 
                            name="title"
                            label="Название"
                            type="text"
                            placeholder="Название"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.title}
                        />

                        <TextInputField 
                            name="text"
                            label="Описание"
                            as="textarea"
                            rows={3}
                            placeholder="Описание"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.title}
                        />

                        <CalendarInputField
                            name="deadline"
                            label="Дедлайн"
                            placeholder=""
                            register={register}
                            setValue={setValue}
                            registerOptions={{required: "Required"}}
                            error={errors.title}
                            date={taskToEdit?.deadline}
                        />
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button
                    type="submit"
                    form="addEditTaskForm"
                    disabled={isSubmitting}
                    >
                        Сохранить
                    </Button>
                </ModalFooter>
                </>
            }
        </Modal>
     );
}
 
export default AddEditTaskDialog;