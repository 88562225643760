import { Card } from "react-bootstrap";
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import { TeacherCurator } from "../../../models/teacher-curator";

interface TeacherCuratorCardPreloadedProps {
    teacherCurator: TeacherCurator,
    chosenCurators: TeacherCurator[],
    onTeacherCuratorClicked: (value:TeacherCurator) => void,
    className?: string,
}

const TeacherCuratorCardPreloaded = ({teacherCurator, chosenCurators, onTeacherCuratorClicked, className}: TeacherCuratorCardPreloadedProps) => {
    return(
        <Card 
            onClick={(e) => {
                onTeacherCuratorClicked(teacherCurator);
            }}
            >
            <Card.Body className={className}>
                <div>
                    <div>
                        {teacherCurator.fullName}
                    </div>
                    <div>
                        {"Должность: "+teacherCurator.position}
                    </div>
                </div>
                {(chosenCurators.includes(teacherCurator))? 
                    <FaRegCheckSquare/>
                    :
                    <FaRegSquare/>}
            </Card.Body>
        </Card>
    );
}

export default TeacherCuratorCardPreloaded;