import { Button, Container } from "react-bootstrap";
import styles from "../styles/AuthPage.module.css";
import stylesUtils from "../styles/utils.module.css";
import { Link } from "react-router-dom";

const ObserverCuratorCabinetPage = () => {
    return (
        <Container className={styles.authPage}>
            <Link to="notifications">
                <Button className={`mb-4 ${stylesUtils.flexCenter}` }>
                    Уведомления
                </Button>
            </Link>
            <Link to="projects">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Проекты
                </Button>
            </Link>
            <Link to="personal-tasks">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}`}>
                    Персональные задачи
                </Button>
            </Link>
            <Link to="statistics">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}`}>
                    Статистика
                </Button>
            </Link>
        </Container>
    )
}

export default ObserverCuratorCabinetPage;