import { Card } from "react-bootstrap";
import { Project as ProjectModel } from "../../models/project";
import styles from "../../styles/Project.module.css";
import styleUtils from "../../styles/utils.module.css";

interface ProjectProps {
    project: ProjectModel,
    onProjectClicked: (project: ProjectModel) => void,
    className?: string,
}

const Project = ({ project, onProjectClicked, className }: ProjectProps) => {
    const {
        title,
    } = project;

    return (
        <Card 
            className={`${styles.projectCard} ${className}`}
            onClick={() => onProjectClicked(project)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSA}>
                    {title}
                </Card.Title>
            </Card.Body>
        </Card>
    )
}

export default Project;