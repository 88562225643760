import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProjectStagesPageView from "../components/AdministratorsComponents/ProjectStagesPageView";
import { Administrator } from "../models/administrator";
import styles from "../styles/ProjectStagesPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface AdministratorProjectStagesPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorProjectStagesPage = ({ loggedInAdministrator }: AdministratorProjectStagesPageProps) => {
  const {projectId} = useParams();
  return (
        <Container className={styles.projectStagesPage}>
        <>
          { (loggedInAdministrator && projectId)
              ? <ProjectStagesPageView role={loggedInAdministrator.role} projectId={projectId}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default AdministratorProjectStagesPage;