import { Container } from "react-bootstrap";
import { TeacherCurator } from "../models/teacher-curator";
import styles from "../styles/StatisticsPage.module.css";
import NotFoundPage from "./NotFoundPage";
import StatisticsPageView from "../components/TeacherCuratorsComponents/StatisticsPageView";

interface TeacherCuratorStatisticsPageProps {
    loggedInTeacherCurator: TeacherCurator | any,
}

const TeacherCuratorStatisticsPage = ({ loggedInTeacherCurator }: TeacherCuratorStatisticsPageProps) => {
  return (
        <Container className={styles.statisticsPage}>
        <>
          { loggedInTeacherCurator
              ? <StatisticsPageView role={loggedInTeacherCurator.role}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default TeacherCuratorStatisticsPage;