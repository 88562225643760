import { Card } from "react-bootstrap";
import { MdDelete, MdTune } from "react-icons/md";
import { Region as RegionModel } from "../../models/region";
import styles from "../../styles/Region.module.css";
import styleUtils from "../../styles/utils.module.css";

interface RegionProps {
    region: RegionModel,
    onRegionClicked: (region: RegionModel) => void,
    onUpdateClicked: (region: RegionModel) => void,
    onDeleteRegionClicked: (region: RegionModel) => void,
    className?: string,
}

const Region = ({ region, onRegionClicked, onUpdateClicked, onDeleteRegionClicked, className }: RegionProps) => {
    const {
        title,
    } = region;

    return (
        <Card 
            className={`${styles.noteCard} ${className}`}
            onClick={() => onRegionClicked(region)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={`${styleUtils.flexCenterSA} ${styleUtils.width100}`}>
                {title}
                <div>
                    <MdTune
                        className="text-muted ms-right"
                        onClick={(e) => {
                            onUpdateClicked(region);
                            e.stopPropagation();
                        }}
                    />
                    <MdDelete
                        className="text-muted ms-right"
                        onClick={(e) => {
                            onDeleteRegionClicked(region);
                            e.stopPropagation();
                        }}
                    />
                </div>
                </Card.Title>
            </Card.Body>
        </Card>
    )
}

export default Region;