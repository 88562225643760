import { useEffect, useState } from "react";
import { ObserverCurator } from "../../../models/observer-curator";
import * as ObserverCuratorsApi from "../../../network/observer-curator-api";
import { Col, Row, Spinner } from "react-bootstrap";
import styles from "../../../styles/ObserverCuratorsGrid.module.css";
import ProjectObserverCuratorCardPreloaded from "./ProjectObserverCuratorCardPreloaded";

interface ProjectObserverCuratorGridProps {
    setChosenCuratorId: (value:string) => void,
    setOnFinish: (b:boolean)=> void,
}

const ProjectObserverCuratorsGrid = ({setChosenCuratorId, setOnFinish}: ProjectObserverCuratorGridProps) => {
    const [observerCurators, setObserverCurators] = useState<ObserverCurator[]>([]);
    const [observerCuratorsToShow, setObserverCuratorsToShow] = useState<ObserverCurator[]>([]);
    const [observerCuratorNameFilter, setObserverCuratorNameFilter] = useState("");
    const [ObserverCuratorsLoading, setObserverCuratorsLoading] = useState(true);
    const [showObserverCuratorsLoadingError, setShowObserverCuratorsLoadingError] = useState(false);

    useEffect(() => {
        async function loadObserverCurators() {
            try {
                setShowObserverCuratorsLoadingError(false);
                setObserverCuratorsLoading(true);
                const curators = await ObserverCuratorsApi.fetchObserverCurators();
                setObserverCurators(curators);
                setObserverCuratorsToShow(curators);
            } catch (error) {
                console.error(error);
                setShowObserverCuratorsLoadingError(true);
            } finally {
                setObserverCuratorsLoading(false);
            }
          }
          loadObserverCurators();
    }, []);

    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = observerCurators.filter((user) => {
                return user.fullName.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setObserverCuratorsToShow(filteredArray);
        } else {
            setObserverCuratorsToShow(observerCurators);
        }

        setObserverCuratorNameFilter(keyword);
    }

    const ObserverCuratorsGrid = 
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.observerCuratorsGrid}`}>
          {observerCuratorsToShow.map(ObserverCurator => (
            <Col key={ObserverCurator._id}>
              <ProjectObserverCuratorCardPreloaded 
                ObserverCurator={ObserverCurator} 
                className={styles.projectObserverCurator}
                onObserverCuratorClicked={setChosenCuratorId}
                onFinish={setOnFinish}
                />
            </Col>
          ))}
        </Row>

    return (
        <>
            {ObserverCuratorsLoading && <Spinner animation='border' variant='primary' />}
                {showObserverCuratorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
                {!ObserverCuratorsLoading && !showObserverCuratorsLoadingError && 
                <div className={styles.gridField}>
                    <input
                        type="search"
                        value={observerCuratorNameFilter}
                        onChange={filter}
                        className={styles.search}
                        placeholder="Поиск по ФИО">
                    </input>
                    {
                    observerCurators.length > 0
                        ? ObserverCuratorsGrid
                        : <p>Вы ещё не добавили кураторов-наблюдателей</p>
                    }  
                </div>
                }
        </>
    );
}

export default ProjectObserverCuratorsGrid;