import { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { MdDelete, MdTune } from "react-icons/md";
import { ObserverCurator } from "../../models/observer-curator";
import { PersonalTask as TaskModel } from "../../models/personal-task";
import { TeacherCurator } from "../../models/teacher-curator";
import * as ObserverCuratorApi from "../../network/observer-curator-api";
import * as TeacherCuratorApi from "../../network/teacher-curator-api";
import styles from "../../styles/Task.module.css";
import styleUtils from "../../styles/utils.module.css";
import { formatDate } from "../../utils/formatDate";
import StatusCard from "./StatusCard";

interface PersonalTaskCardProps {
    task: TaskModel,
    onTaskClicked: (task: TaskModel) => void,
    onUpdateTaskClicked: (task: TaskModel) => void,
    onDeleteTaskClicked: (task: TaskModel) => void,
    className?: string,
}

const PersonalTaskCard = ({task, onTaskClicked, onUpdateTaskClicked, onDeleteTaskClicked, className }: PersonalTaskCardProps) => {
    const {
        title,
        status,
        deadline,
        userId,
    } = task;

    const [user, setUser] = useState<TeacherCurator|ObserverCurator|null>(null);
    const [userLoading, setUserLoading] = useState(true);
    const [showUserLoadingError, setUserLoadingError] = useState(false);

    useEffect(() => {
        async function loadCurator() {
          try {
            setUserLoadingError(false);
            setUserLoading(true);
            let userData = await TeacherCuratorApi.getTeacherCurator(userId);
            if (!userData) {
                userData = await ObserverCuratorApi.getObserverCurator(userId);
            }
            if (!userData) {
                setUserLoadingError(true);
            }
            setUser(userData);
          } catch (error) {
            console.error(error);
            setUserLoadingError(true);
          } finally {
            setUserLoading(false);
          }
        }
        loadCurator();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <Card 
            className={`${styles.taskCard} ${className}`}
            onClick={() => onTaskClicked(task)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSA}>
                    {title}
                    <div>
                        <MdTune
                            className="text-muted ms-right"
                            onClick={(e) => {
                                onUpdateTaskClicked(task);
                                e.stopPropagation();
                            }}
                        />
                        <MdDelete
                            className="text-muted ms-right"
                            onClick={(e) => {
                                onDeleteTaskClicked(task);
                                e.stopPropagation();
                            }}
                        />
                    </div>
                </Card.Title>
                <div className={styles.cardBodyStatus}>
                    <div>
                        {"Статус:"}
                    </div>
                    <StatusCard status={status}/>
                </div>
                {userLoading && <Spinner animation='border' variant='primary' />}
                {showUserLoadingError && <p>Something went wrong. Please refresh the page.</p>}
                {!userLoading && !showUserLoadingError &&
                    <Card.Text className="mb-2">
                        {"Куратор: " + user?.fullName}
                    </Card.Text>
                }
                <Card.Footer>{"Дедлайн: " + formatDate(deadline)}</Card.Footer>
            </Card.Body>
        </Card>
    )
}

export default PersonalTaskCard;