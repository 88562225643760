import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as ObserverCuratorsApi from "../../../network/observer-curator-api";
import { ObserverCuratorSignUpCredentials } from "../../../network/observer-curator-api";
import TextInputField from "./TextInputField";

interface SignUpObserverCuratorProps {
    formId: string, 
    setChosenCuratorId: (value:string) => void,
    setOnFinish: (b:boolean)=> void,
}

const SignUpObserverCurator = ({formId, setChosenCuratorId, setOnFinish}: SignUpObserverCuratorProps) => {
    const { register, handleSubmit, formState : {errors, isSubmitting} } = useForm<ObserverCuratorSignUpCredentials>({
        defaultValues: {
            fullName:"",
            position: "",
            phone: "",
            email: "",
            telegram: "",
            login: "",
            password: "",
        }
    });

    async function onSubmit(input:ObserverCuratorSignUpCredentials) {
        let ObserverCuratorResponse;
        input.telegram = input.telegram.slice(1);
        try {
            ObserverCuratorResponse = await ObserverCuratorsApi.signUp(input);
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            if(ObserverCuratorResponse) {
                setChosenCuratorId(ObserverCuratorResponse._id);
                setOnFinish(true);
            }

        }
    }

    return (
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <TextInputField 
                name="fullName"
                label="ФИО"
                type="text"
                placeholder="ФИО"
                register={register}
                registerOptions={{required: "Required"}}
                error={errors.fullName}
            />

            <TextInputField 
                name="position"
                label="Должность"
                type="text"
                placeholder="Должность"
                register={register}
                registerOptions={{required: "Required"}}
                error={errors.fullName}
            />

            <TextInputField 
                name="phone"
                label="Телефон"
                type="phone"
                placeholder="+7(900)000-00-00"
                register={register}
                registerOptions={{required: "Required"}}
                error={errors.phone}
            />

            <TextInputField 
                name="email"
                label="Эл. почта"
                type="email"
                placeholder="name@example.com"
                register={register}
                registerOptions={{required: "Required"}}
                error={errors.email}
            />

            <TextInputField 
                name="telegram"
                label="Телеграм"
                type="telegram"
                placeholder="@name"
                register={register}
                registerOptions={{required: "Required"}}
                error={errors.email}
            />

            <TextInputField 
                name="login"
                label="Логин"
                type="text"
                placeholder="Введите логин"
                register={register}
                registerOptions={{required: "Required"}}
                error={errors.login}
            />

            <TextInputField 
                name="password"
                label="Пароль"
                type="password"
                placeholder="Password"
                register={register}
                registerOptions={{required: "Required"}}
                error={errors.password}
            />

            <Button
                type="submit"
                form={formId}
                disabled={isSubmitting}
                >
                    Добавить
            </Button>
        </Form>
    );
}

export default SignUpObserverCurator;