import { Container } from "react-bootstrap";
import StatisticsPageView from "../components/AdministratorsComponents/StatisticsPageView";
import { Administrator } from "../models/administrator";
import styles from "../styles/StatisticsPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface AdministratorStatisticsPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorStatisticsPage = ({ loggedInAdministrator }: AdministratorStatisticsPageProps) => {
    return (
        <Container className={styles.statisticsPage}>
        <>
          { loggedInAdministrator
              ? <StatisticsPageView role={loggedInAdministrator.role}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default AdministratorStatisticsPage;