import { useEffect, useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PersonalTask } from "../../models/personal-task";
import * as TasksApi from "../../network/personal-tasks-api";
import CalendarInputField from "./form/CalendarInputField";
import ChooseCuratorField from "./form/ChooseCuratorField";
import TextInputField from "./form/TextInputField";
import * as NotificationsApi from "../../network/notifications-api";

interface AddSeveralPersonalTaskDialogProps {
    role: string,
    onDismiss: () => void,
    onTasksSaved: (Tasks: PersonalTask[]) => void,
}


const AddSeveralPersonalTaskDialog = ({role, onDismiss, onTasksSaved}: AddSeveralPersonalTaskDialogProps) => {
    const [userLoading, setUserLoading] = useState(true);
    const [showUserLoadingError, setShowUserLoadingError] = useState(false);
    
    interface FormProps {
        curatorsId: string[],
        status: string,
        title: string,
        text: string,
        deadline: string,
    }

    const { register, handleSubmit, setValue, formState : {errors, isSubmitting} } = useForm<FormProps>({
        defaultValues: {
            curatorsId: [],
            status: "В работе",
            title: "",
            text: "",
            deadline: "",
        }
    });

    useEffect(() => {
        async function loadUser() {
          try {
            setShowUserLoadingError(false);
            setUserLoading(true);

          } catch (error) {
            console.error(error);
            setShowUserLoadingError(true);
          } finally {
            setUserLoading(false);
          }
        }
        loadUser();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    async function onSubmit(input:FormProps) {
        try {
            const TasksResponse: PersonalTask[] = [];
            console.log(input)
            for (const curator of input.curatorsId) {
                const taskInput = {
                    userId: curator,
                    status: input.status,
                    title: input.title,
                    text: input.text,
                    deadline: input.deadline,
                }
                TasksResponse.push(await TasksApi.createPersonalTask(taskInput));
                
                const notification = {
                    administratorId: "NONE", 
                    type: "31",
                    userId: curator,
                }
                await NotificationsApi.createNotification(notification);
            }
            onTasksSaved(TasksResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return ( 
        <Modal show onHide={onDismiss}>
            {userLoading && <Spinner animation='border' variant='primary' />}
            {showUserLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!userLoading && !showUserLoadingError && 
                <>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Добавить задачу
                    </Modal.Title>
                </Modal.Header>

                <ModalBody>
                    <Form>
                        <ChooseCuratorField
                            name="curatorsId"
                            label="Куратор"
                            registerOptions={{required: "Required"}}
                            errors={errors.curatorsId}
                            setValue={setValue}
                        />
                    </Form>
                    <Form id="addEditTaskForm" onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField 
                            name="title"
                            label="Название"
                            type="text"
                            placeholder="Название"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.title}
                        />

                        <TextInputField 
                            name="text"
                            label="Описание"
                            as="textarea"
                            rows={3}
                            placeholder="Описание"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.title}
                        />

                        <CalendarInputField
                            name="deadline"
                            label="Дедлайн"
                            placeholder=""
                            register={register}
                            setValue={setValue}
                            registerOptions={{required: "Required"}}
                            error={errors.title}
                        />
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button
                    type="submit"
                    form="addEditTaskForm"
                    disabled={isSubmitting}
                    >
                        Сохранить
                    </Button>
                </ModalFooter>
                </>
            }
        </Modal>
     );
}
 
export default AddSeveralPersonalTaskDialog;