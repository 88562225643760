import { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Project } from "../../../models/project";
import { ProjectTask as TaskModel } from "../../../models/project-task";
import styles from "../../../styles/Task.module.css";
import styleUtils from "../../../styles/utils.module.css";
import { formatDate } from "../../../utils/formatDate";
import * as ProjectsApi from "../../../network/projects-api"
import StatusCard from "../StatusCard";

interface ProjectTaskProps {
    role: string,
    task: TaskModel,
    onTaskClicked: (task: TaskModel) => void,
    className?: string,
}

const TaskFromProject = ({ role, task, onTaskClicked, className }: ProjectTaskProps) => {
    const {
        title,
        status,
        deadline,
        projectId,
    } = task;

    const [project, setProject] = useState<Project|null>(null);
    const [projectLoading, setProjectLoading] = useState(true);
    const [showProjectLoadingError, setShowProjectLoadingError] = useState(false);
    
    useEffect(() => {
        async function loadProject() {
            try {
                setShowProjectLoadingError(false);
                setProjectLoading(true);
                const projectTasksData = await ProjectsApi.fetchProject({role, projectId});
                setProject(projectTasksData);
            } catch (error) {
            console.error(error);
            setShowProjectLoadingError(true);
            } finally {
                setProjectLoading(false);
            }
        }
        loadProject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    let navigate = useNavigate();
    const goToProject = () =>{
        let path = "/" + role + "/cabinet/regions/" + project?.regionId + "/" + project?._id;
        navigate(path);
        }

    return (
        <Card 
            className={`${styles.taskCard} ${className}`}
            onClick={() => onTaskClicked(task)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSA}>
                    {title}
                    <div>
                        <Button variant="secondary" onClick={goToProject}>
                            Перейти в проект
                        </Button>
                    </div>
                </Card.Title>
                <div className={styles.cardBodyStatus}>
                    <div>
                        {"Статус:"}
                    </div>
                    <StatusCard status={status}/>
                </div>
                {projectLoading && <Spinner animation='border' variant='primary' />}
                {showProjectLoadingError && <p>Something went wrong. Please refresh the page.</p>}
                {!projectLoading && !showProjectLoadingError && 
                    <Card.Text className="mb-3">
                        {"Проект: " + project?.title}
                    </Card.Text>
                }
                <Card.Footer>{"Дедлайн: " + formatDate(deadline)}</Card.Footer>
            </Card.Body>
        </Card>
    )
}

export default TaskFromProject;