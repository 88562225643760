import { LoginCredentials } from "../models/credentials";
import { ObserverCurator} from "../models/observer-curator";
import { fetchData } from "../utils/fetchData";

export async function getLoggedObserverCurator(): Promise<ObserverCurator> {
    const response = await fetchData("/api/observer-curators", { method: "GET" });
    return response.json();
}

export interface ObserverCuratorSignUpCredentials {
    fullName: string,
    phone: string,
    email: string,
    telegram: string,
    position: string,
    login: string,
    password: string,
}

export async function fetchObserverCurators(): Promise<ObserverCurator[]> {
    const response = await fetchData("/api/observer-curators/all", { method: "GET" });
    return response.json();
}

export async function getObserverCurator(observerCuratorId: string): Promise<ObserverCurator> {
    const response = await fetchData("/api/observer-curators/"+observerCuratorId, { method: "GET" });
    return response.json();
}

export async function signUp(credentials: ObserverCuratorSignUpCredentials): Promise<ObserverCurator> {
    const response = await fetchData("/api/observer-curators/signup",
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(credentials),
    });
    return response.json();
}


export async function login(credentials: LoginCredentials): Promise<ObserverCurator> {
    const response = await fetchData("/api/observer-curators/login",
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(credentials),
    });
    return response.json();
}

export async function logout() {
    await fetchData("/api/observer-curators/logout", {method: "POST"});
}