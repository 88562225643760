import { Button, Form, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Region } from "../../models/region";
import * as RegionsApi from "../../network/regions-api";
import { RegionInput } from "../../network/regions-api";
import TextInputField from "./form/TextInputField";

interface AddEditRegionDialogProps {
    regionToEdit?: Region,
    onDismiss: () => void,
    onRegionSaved: (region: Region) => void,
}


const AddEditRegionDialog = ({regionToEdit, onDismiss, onRegionSaved}: AddEditRegionDialogProps) => {
    const { register, handleSubmit, formState : {errors, isSubmitting} } = useForm<RegionInput>({
        defaultValues: {
            title: regionToEdit?.title || "",
        }
    });
    
    async function onSubmit(input:RegionInput) {
        try {
            let regionResponse: Region;
            if (regionToEdit) {
                console.log(input);
                regionResponse = await RegionsApi.updateRegion(regionToEdit._id, input);
            } else {
                regionResponse = await RegionsApi.createRegion(input);
            }
            onRegionSaved(regionResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return ( 
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {regionToEdit ? "Редактировать регион" : "Добавить регион"}
                </Modal.Title>
            </Modal.Header>

            <ModalBody>
                <Form id="addEditRegionForm" onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField 
                        name="title"
                        label="Название"
                        type="text"
                        placeholder="Название"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.title}
                    />

                </Form>
            </ModalBody>

            <ModalFooter>
                <Button
                type="submit"
                form="addEditRegionForm"
                disabled={isSubmitting}
                >
                    Сохранить
                </Button>
            </ModalFooter>
        </Modal>
     );
}
 
export default AddEditRegionDialog;