import { Card } from "react-bootstrap";
import { ProjectTask as TaskModel } from "../../models/project-task";
import styles from "../../styles/Task.module.css";
import styleUtils from "../../styles/utils.module.css";
import { formatDate } from "../../utils/formatDate";
import StatusCard from "./StatusCard";

interface ProjectTaskProps {
    task: TaskModel,
    onTaskClicked: (task: TaskModel) => void,
    className?: string,
}

const ProjectTask = ({ task, onTaskClicked, className }: ProjectTaskProps) => {
    const {
        title,
        status,
        deadline,
    } = task;

    return (
        <Card 
            className={`${styles.taskCard}`}
            onClick={() => onTaskClicked(task)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSA}>
                    {title}
                </Card.Title>
                <div className={styles.cardBodyStatus}>
                    <div>
                        {"Статус:"}
                    </div>
                    <StatusCard status={status}/>
                </div>
                <Card.Footer>{"Дедлайн: " + formatDate(deadline)}</Card.Footer>
            </Card.Body>
        </Card>
    )
}

export default ProjectTask;