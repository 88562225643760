import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { TeacherCurator } from "../../../models/teacher-curator";
import * as TeacherCuratorsApi from "../../../network/teacher-curator-api";
import * as ObserverCuratorsApi from "../../../network/observer-curator-api";

interface CuratorCardProps {
    curatorId: string,
    updateForm?: () => void,
}

const CuratorCard = ({curatorId, updateForm} : CuratorCardProps) => {
    if (updateForm) {
        updateForm();
    }
    const [curatorData, setCuratorData] = useState<TeacherCurator|null>(null)
    const [curatorLoading, setCuratorLoading] = useState(true);
    const [showCuratorLoadingError, setShowCuratorLoadingError] = useState(false);
    useEffect(() => {
        async function loadCurator(teacherCuratorId: string) {
            try {
                const teacherCurator = await TeacherCuratorsApi.getTeacherCurator(teacherCuratorId);
                if (teacherCurator) {
                    setCuratorData(teacherCurator);
                } else {
                    const observerCurator = await ObserverCuratorsApi.getObserverCurator(teacherCuratorId);
                    setCuratorData(observerCurator);
                }
                
            } catch (error) {
                console.error(error);
                setShowCuratorLoadingError(true);
            } finally {
                setCuratorLoading(false);
            }
        }
        loadCurator(curatorId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    return (
        <>
            {curatorLoading &&
                <Card>
                    <Card.Body>
                        Loading
                    </Card.Body>
                </Card>
            }
            {curatorData &&
                <Card>
                    <Card.Body>
                        {curatorData.fullName}
                    </Card.Body>
                </Card>
            }
            {showCuratorLoadingError &&
                 <Card>
                 <Card.Body>
                     Error
                 </Card.Body>
             </Card>
            }
        </>
    );
}

export default CuratorCard;