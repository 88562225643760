import { Container } from "react-bootstrap";
import { TeacherCurator } from "../models/teacher-curator";
import styles from "../styles/ProjectsPage.module.css";
import NotFoundPage from "./NotFoundPage";
import PersonalTasksPageView from "../components/TeacherCuratorsComponents/PersonalTasksPageView";

interface TeacherCuratorPersonalTasksPageProps {
  loggedInTeacherCurator: TeacherCurator | any| null,
}

const TeacherCuratorPersonalTasksPage = ({ loggedInTeacherCurator }: TeacherCuratorPersonalTasksPageProps) => {
  return (
        <Container className={styles.projectsPage}>
        <>
          { loggedInTeacherCurator
              ? <PersonalTasksPageView role={loggedInTeacherCurator.role}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default TeacherCuratorPersonalTasksPage;