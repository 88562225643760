import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProjectPageView from "../components/ObserverCuratorsComponents/ProjectPageView";
import { ObserverCurator } from "../models/observer-curator";
import styles from "../styles/ProjectPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface ObserverCuratorProjectPageProps {
    loggedInObserverCurator: ObserverCurator | any| null,
}

const ObserverCuratorProjectPage = ({loggedInObserverCurator}: ObserverCuratorProjectPageProps) => {
    const {projectId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
            { (loggedInObserverCurator && projectId)
                ? <ProjectPageView role={loggedInObserverCurator.role} projectId={projectId}/>
                : <NotFoundPage/>
            }
            </>
        </Container>
    );
}

export default ObserverCuratorProjectPage;