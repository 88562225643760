import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ProjectThirdStage } from "../../models/project-third-stage";
import * as ProjectThirdStageApi from "../../network/project-third-stages-api";
import styles from "../../styles/ChangeStageDialog.module.css";
import * as NotificationsApi from "../../network/notifications-api";
import * as ProjectsApi from "../../network/projects-api";

interface AddThirdStageDialogProps {
    projectId: string,
    onDismiss: () => void,
    onStageSaved: (stage: ProjectThirdStage) => void,
}

const AddThirdStageDialog = ({projectId, onDismiss, onStageSaved}: AddThirdStageDialogProps) => {
    interface changeStatusFormProps{
        projectId: string,
        status: string,
        text: string,
        file: File[],
    }
    const { register, handleSubmit, formState : {errors, isSubmitting} } = useForm<changeStatusFormProps>({
        defaultValues: {
            projectId: projectId,
            status: "NeedCheck",
            text: "",
        }
    });

    async function onSubmit(input:changeStatusFormProps) {
        try {
            const updatedStage = await ProjectThirdStageApi.postProjectThirdStage({projectId: input.projectId, status: input.status, text: input.text, file: input.file[0]});
            const projectData = await ProjectsApi.fetchProject({role: "administrator", projectId: projectId});
            if (projectData) {
                const notification = {
                    administratorId: projectData.administratorId, 
                    type: "21",
                    projectId: projectData._id,
                    univerObserverCuratorId: projectData.univerObserverCuratorId,
                    roivObserverCuratorId: projectData.roivObserverCuratorId,
                    regionObserverCuratorId: projectData.regionObserverCuratorId,
                }
                await NotificationsApi.createNotification(notification);
            }
            onStageSaved(updatedStage);
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            onDismiss();
        }
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Этап 3
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.stageFooter}>
                <Form id="addThirdStageStatus" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                    <h1>Отправка на проверку</h1>
                    <Form.Group className="mb-3" controlId="text-input">
                        <Form.Label>Описание</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={3} 
                            placeholder="Описание"
                            {...register("text")} 
                            isInvalid={!!errors.text}
                        />
                    </Form.Group>
                    <Form.Group controlId="file-input">
                        <Form.Label>
                            Файл
                        </Form.Label>
                        <Form.Control 
                            type="file"
                            multiple={false}
                            {...register("file")} 
                            isInvalid={!!errors.file}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                type="submit"
                form="addThirdStageStatus"
                disabled={isSubmitting}>
                    Отправить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddThirdStageDialog;