import { ProjectFifthStage } from "../models/project-fifth-stage";
import { fetchFile, fetchData } from "../utils/fetchData";

interface fetchProjectFifthStageProps{
    projectId: string,
}

export async function fetchProjectFifthStage(requestProps: fetchProjectFifthStageProps): Promise<ProjectFifthStage> {
    const response = await fetchData("/api/project-fifth-stages/"+requestProps.projectId, { method: "GET" });
    return response.json();
}

export async function fetchProjectFifthStageFile(requestProps: fetchProjectFifthStageProps){
    await fetchFile("/api/project-fifth-stages/"+requestProps.projectId+"/file/", { method: "GET" });
}

interface postProjectFifthStageProps{
    projectId: string,
    status: string,
    text: string,
    file: File,
}

export async function postProjectFifthStage(requestProps: postProjectFifthStageProps): Promise<ProjectFifthStage> {
    const formData = new FormData();
    formData.append("projectId", requestProps.projectId);
    formData.append("status", requestProps.status);
    formData.append("text", requestProps.text);
    formData.append("projectfifthstage", requestProps.file);
    const response = await fetchData("/api/project-fifth-stages/",
    {
        method: "POST",
        body: formData,
    });
    return response.json();
}

interface updateProjectFifthStageStatusProps{
    status: string
}

export async function updateProjectFifthStageStatus(requestProps: updateProjectFifthStageStatusProps, projectId: string): Promise<ProjectFifthStage> {
    const response = await fetchData("/api/project-fifth-stages/"+projectId,
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}