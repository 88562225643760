import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { FieldError, RegisterOptions, UseFormRegister, UseFormSetValue } from "react-hook-form";
import styles from "../../../styles/AddEditProject.module.css";
import TeacherCuratorCard from "./TeacherCuratorCard";
import SignUpTeacherCurator from "./SignUpTeacherCurator";
import ProjectTeacherCuratorsGrid from "./ProjectTeacherCuratorsGrid";


interface ChooseTeacherCuratorFieldProps {
    name: string,
    label: string,
    register: UseFormRegister<any>,
    registerOptions?: RegisterOptions,
    error?: FieldError,
    curatorId?: string,
    setValue: UseFormSetValue<any>,
    [x: string]: any,
}

const ChooseTeacherCuratorField = ({name, label, register, registerOptions, error, curatorId, setValue, ...props}: ChooseTeacherCuratorFieldProps) => {
    const [chosenCuratorId, setChosenCuratorId] = useState("")
    const [finishedChoosingCurator, setFinishedChoosingCurator] = useState(false)

    const [showAddedTeacherCuratorCard, setShowAddedTeacherCuratorCard] = useState(false)
    const [showAddTeacherCuratorsDialog, setShowAddTeacherCuratorsDialog] = useState(false);
    const [showChooseTeacherCuratorsDialog, setShowChooseTeacherCuratorsDialog] = useState(false);

    function useSetConstantValue() {
        setValue(name, chosenCuratorId);
        setFinishedChoosingCurator(false);
        setShowAddTeacherCuratorsDialog(false);
        setShowChooseTeacherCuratorsDialog(false);
        setShowAddedTeacherCuratorCard(true);
    }

    const showAddNewTeacherCurator = () => {
        setShowAddTeacherCuratorsDialog(true);
        setShowChooseTeacherCuratorsDialog(false);
        setShowAddedTeacherCuratorCard(false);
    }
    const showChooseNewTeacherCurator = () => {
        setShowAddTeacherCuratorsDialog(false);
        setShowChooseTeacherCuratorsDialog(true);
        setShowAddedTeacherCuratorCard(false);
    }

    return (
        <>
        <Form.Group className="mb-3" controlId={name}>
            <Card className={styles.curatorCard}>
                <Card.Footer className={styles.CuratorFooter}>
                    {label}
                </Card.Footer>
                <Card.Header className={styles.curatorCardHeader}>
                    <Button className={styles.curatorButton} onClick={showAddNewTeacherCurator}>
                        Добавить нового
                    </Button>
                    <Button className={styles.curatorButton} onClick={showChooseNewTeacherCurator}>
                        Выбрать существующего
                    </Button>
                </Card.Header>
                <Card.Body>
                    { (curatorId && !showAddedTeacherCuratorCard && !showAddTeacherCuratorsDialog && !showChooseTeacherCuratorsDialog) &&
                        <TeacherCuratorCard teacherCuratorId={curatorId}/>
                    }
                    {showAddedTeacherCuratorCard &&
                        <TeacherCuratorCard teacherCuratorId={chosenCuratorId}/>
                    }
                    { showChooseTeacherCuratorsDialog &&
                        <ProjectTeacherCuratorsGrid
                            setChosenCuratorId={setChosenCuratorId}
                            setOnFinish={setFinishedChoosingCurator}
                        />
                    }
                </Card.Body>
            </Card>
        </Form.Group>
        { showAddTeacherCuratorsDialog &&
            <Card className="mb-3">
                <Card.Header>
                    Добавление нового педагога-куратора
                </Card.Header>
                <Card.Body>
                    <SignUpTeacherCurator 
                        setChosenCuratorId={setChosenCuratorId}
                        setOnFinish={setFinishedChoosingCurator}
                    />
                </Card.Body>
            </Card>
        }
        {
            finishedChoosingCurator &&
            <TeacherCuratorCard 
                teacherCuratorId={chosenCuratorId}
                updateForm={useSetConstantValue}
            />
        }

        </>
    );
}

export default ChooseTeacherCuratorField;