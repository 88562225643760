import { Card } from "react-bootstrap";
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import { Region } from "../../../models/region";

interface RegionCardProps{
    region: Region,
    chosenRegionsId: string[],
    onRegionClicked: (region: Region) => void,
    className?: string,
}

const RegionCard = ({region, chosenRegionsId, onRegionClicked, className}: RegionCardProps) => {
    return (
        <Card onClick={() => {onRegionClicked(region)}}>
            <Card.Body className={className}>
                {region.title}
                {(chosenRegionsId.includes(region._id))? 
                    <FaRegCheckSquare/>
                    :
                    <FaRegSquare/>}
            </Card.Body>
        </Card>
    );
}

export default RegionCard