import { Button, Card, Modal } from "react-bootstrap";
import { ProjectSecondStage } from "../../models/project-second-stage";
import * as ProjectSecondStageApi from "../../network/project-second-stages-api";
import styles from "../../styles/ChangeStageDialog.module.css";

interface SecondStageDialogProps {
    stage: ProjectSecondStage,
    onDismiss: () => void,
}

const SecondStageDialog = ({stage, onDismiss}: SecondStageDialogProps) => {
    const downloadFile = () => {
        ProjectSecondStageApi.fetchProjectSecondStageFile({projectId: stage.projectId});
    }

    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Этап 2
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card className={`mb-3 ${styles.descriptionField}`}>
                    <Card.Header>
                        Описание
                    </Card.Header>
                    <Card.Body>
                        {stage.text}
                    </Card.Body>
                </Card>
                <Button onClick={downloadFile} className={styles.downloadFileButton}>
                    Скачать закреплённый документ
                </Button>
            </Modal.Body>
        </Modal>
    );
}

export default SecondStageDialog;