import { ConflictError, UnauthorizedError } from "../errors/http_errors";
import { saveAs } from "file-saver";

export async function fetchData(input: RequestInfo, init?: RequestInit) {
    const response = await fetch(input, init);
    if (response.ok) {
        return response
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error;
        if(response.status === 401) {
            throw new UnauthorizedError(errorMessage);
        } else if (response.status === 409) {
            throw new ConflictError(errorMessage);
        } else {
            throw Error("Request failed with status: " + response.status + ". message: " + errorMessage);
        }
    }
}

export async function fetchFile(input: RequestInfo, init?: RequestInit) {
    await fetch(input, init)
    .then((response) => response.json())
    .then((json) => {
        const byteArray = json.file.data.data;
        const contentType = json.file.contentType;
        const uintArray = new Uint8Array(byteArray);
        const blob = new Blob([uintArray], {type: contentType});
        const fileName = JSON.stringify(json.fileName).slice(1, -1);
        saveAs(blob, fileName)
    });
}