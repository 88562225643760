import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import StatisticsRegionPageView from "../components/AdministratorsComponents/StatisticsRegionPageView";
import { Administrator } from "../models/administrator";
import styles from "../styles/StatisticsPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface AdministratorStatisticsRegionPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorStatisticsRegionPage = ({ loggedInAdministrator }: AdministratorStatisticsRegionPageProps) => {
  const {regionId} = useParams();
  return (
        <Container className={styles.statisticsPage}>
        <>
          { loggedInAdministrator && regionId 
              ? <StatisticsRegionPageView role={loggedInAdministrator.role} regionId={regionId}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default AdministratorStatisticsRegionPage;