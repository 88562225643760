import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginModal from "../components/LoginModal";
import { UnauthorizedError } from "../errors/http_errors";
import { LoginCredentials } from "../models/credentials";
import { TeacherCurator } from "../models/teacher-curator";
import styleUtils from "../styles/utils.module.css";
import * as TeacherCuratorsApi from "../network/teacher-curator-api"

interface TeacherCuratorAuthPageProps {
    onLoginSuccessful: (teacherCurator: TeacherCurator) => void,
}

const TeacherCuratorAuthPage = ({onLoginSuccessful}: TeacherCuratorAuthPageProps) => {
    const [errorText, setErrorText] = useState<string | null>(null);
    let navigate = useNavigate();
    const routeChange = () =>{
        let path = `cabinet`;
        navigate(path);
    }

    async function onSubmit(credentials: LoginCredentials) {
        try {
            const user = await TeacherCuratorsApi.login(credentials);
            onLoginSuccessful(user);
            routeChange();
        } catch (error) {
            if (error instanceof UnauthorizedError) {
                setErrorText(error.message);
            } else {
                alert(error);
            }
            console.error(error);
        }
    }

    return (
        <>
            <h1 className={styleUtils.flexCenter}>Авторизация педагога-куратора</h1>
            <LoginModal errorText={errorText} onSubmit={onSubmit}/>
        </>
    );
}

export default TeacherCuratorAuthPage;