import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProjectStagesPageView from "../components/TeacherCuratorsComponents/ProjectStagesPageView";
import { TeacherCurator } from "../models/teacher-curator";
import styles from "../styles/ProjectStagesPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface TeacherCuratorProjectStagesPageProps {
  loggedInTeacherCurator: TeacherCurator | any,
}

const TeacherCuratorProjectStagesPage = ({ loggedInTeacherCurator }: TeacherCuratorProjectStagesPageProps) => {
  const {projectId} = useParams();
  return (
        <Container className={styles.projectStagesPage}>
        <>
          { (loggedInTeacherCurator && projectId)
              ? <ProjectStagesPageView role={loggedInTeacherCurator.role} projectId={projectId}/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default TeacherCuratorProjectStagesPage;