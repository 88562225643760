import { Administrator } from "../models/administrator";
import { ObserverCurator } from "../models/observer-curator";
import { TeacherCurator } from "../models/teacher-curator";

interface AdministratorProtectedRouteProps {
    user: Administrator | TeacherCurator | ObserverCurator | null,
    children: JSX.Element,
}

//TODO setup check if right type of user
export function AdministratorProtectedRoute({ user, children }: AdministratorProtectedRouteProps) {
    // if ( isAdministrator(user)) {
    //     return <Navigate to="/" replace />;
    // }
    return children;
}

//TODO setup check if right type of user
export function TeacherCuratorProtectedRoute({ user, children }: AdministratorProtectedRouteProps) {
    // if ( isAdministrator(user)) {
    //     return <Navigate to="/" replace />;
    // }
    return children;
}

//TODO setup check if right type of user
export function ObserverCuratorProtectedRoute({ user, children }: AdministratorProtectedRouteProps) {
    // if ( isAdministrator(user)) {
    //     return <Navigate to="/" replace />;
    // }
    return children;
}