import { Container } from "react-bootstrap";
import RegionsPageView from "../components/AdministratorsComponents/RegionsPageView";
import { Administrator } from "../models/administrator";
import styles from "../styles/NotesPage.module.css";
import NotFoundPage from "./NotFoundPage";

interface AdministratorRegionsPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorRegionsPage = ({ loggedInAdministrator }: AdministratorRegionsPageProps) => {
    return (
        <Container className={styles.notesPage}>
        <>
          { loggedInAdministrator
              ? <RegionsPageView/>
              : <NotFoundPage/>
          }
        </>
      </Container>
    )
}

export default AdministratorRegionsPage