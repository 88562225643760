import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { PersonalTask } from "../../models/personal-task";
import * as PersonalTaskFilesApi from "../../network/personal-task-files-api";
import styles from "../../styles/PersonalTaskInfo.module.css";
import { formatDate } from "../../utils/formatDate";
import StatusCard from "./StatusCard";
import PersonalTaskChangeStatusDialog from "./PersonalTaskChangeStatusDialog";

interface PersonalTaskInfoProps {
    personalTask: PersonalTask,
    role: string,
    onDismiss: (updatedTask: PersonalTask) => void,
}

const PersonalTaskInfo = ({personalTask, role, onDismiss}: PersonalTaskInfoProps) => {
    const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
    const [newStatus, setNewStatus] = useState(personalTask.status);
    const [personalTaskData, setPersonalTaskData] = useState(personalTask);
    
    const updateTask = (task: PersonalTask) => {
        setPersonalTaskData(task);
        setShowChangeStatusDialog(false);
    }

    const downloadFile = () => {
        PersonalTaskFilesApi.fetchPersonalTaskFile({taskId: personalTaskData._id});
    }

    return (
        <Modal show onHide={() => {onDismiss(personalTaskData)}}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {personalTaskData?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card className={`mb-3 ${styles.TaskDescription}`}>
                    <p>{personalTaskData?.text}</p>
                </Card>
                <Card className={`mb-3 ${styles.TaskStatus}`}>
                    <div>{"Статус:"}</div>
                    <StatusCard status={personalTaskData.status}/>
                </Card>
                <Card className={`mb-3 ${styles.TaskDeadline}`}>
                    <div>{"Дедлайн:"}</div>
                    <div>{formatDate(personalTaskData.deadline)}</div>
                </Card>
                {(personalTaskData?.statusDescription && !showChangeStatusDialog) &&
                    <>
                        <Card className={`mb-3 ${styles.TaskStatusDescription}`}>
                            <Card.Header>
                                Описание изменения статуса
                            </Card.Header>
                            <Card.Body className={styles.statusDescription}>
                                {personalTaskData.statusDescription}
                            </Card.Body>
                        </Card>
                        <Button onClick={downloadFile}>
                            Скачать закреплённый отчёт
                        </Button>
                    </>
                }
            </Modal.Body>
            {(personalTaskData.status === "В работе" && !showChangeStatusDialog) &&
                <Modal.Footer className={styles.TaskFooter}>
                    <Button onClick={() => {setNewStatus("Нужна помощь"); setShowChangeStatusDialog(true);}} variant="warning" className={styles.NeedHelpButton}>
                        Нужна помощь
                    </Button>
                    <Button onClick={() => {setNewStatus("Готово"); setShowChangeStatusDialog(true);}} variant="success" className={styles.DoneButton}>
                        Готово
                    </Button>
                </Modal.Footer>
            }
            {(personalTaskData.status === "Нужна помощь" && !showChangeStatusDialog) &&
                <Modal.Footer className={styles.TaskFooter}>
                    <Button onClick={() => {setNewStatus("В работе"); setShowChangeStatusDialog(true);}} className={styles.NeedHelpButton}>
                        В работе
                    </Button>
                    <Button onClick={() => {setNewStatus("Готово"); setShowChangeStatusDialog(true);}} variant="success" className={styles.DoneButton}>
                        Готово
                    </Button>
                </Modal.Footer>
            }
            {(personalTaskData.status === "Готово" && !showChangeStatusDialog) &&
                <Modal.Footer className={styles.TaskFooter}>
                    <Button onClick={() => {setNewStatus("Нужна помощь"); setShowChangeStatusDialog(true);}} variant="warning" className={styles.NeedHelpButton}>
                        Нужна помощь
                    </Button>
                    <Button onClick={() => {setNewStatus("В работе"); setShowChangeStatusDialog(true);}} className={styles.DoneButton}>
                        В работе
                    </Button>
                </Modal.Footer>
            }
            {showChangeStatusDialog&& 
                <PersonalTaskChangeStatusDialog
                    task={personalTaskData}
                    newStatus={newStatus}
                    onStatusUpdated={updateTask}
                />
            }
        </Modal>
    );
}

export default PersonalTaskInfo;