import { useParams } from "react-router-dom";
import { Administrator } from "../models/administrator";
import { Container } from "react-bootstrap";
import NotFoundPage from "./NotFoundPage";
import styles from "../styles/ProjectPage.module.css";
import ProjectPageView from "../components/AdministratorsComponents/ProjectPageView";

interface AdministratorProjectPageProps {
    loggedInAdministrator: Administrator | null,
}

const AdministratorProjectPage = ({loggedInAdministrator}: AdministratorProjectPageProps) => {
    const {projectId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
            { (loggedInAdministrator && projectId)
                ? <ProjectPageView role={loggedInAdministrator.role} projectId={projectId}/>
                : <NotFoundPage/>
            }
            </>
        </Container>
    );
}

export default AdministratorProjectPage