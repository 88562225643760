import { useEffect, useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PersonalTask } from "../../models/personal-task";
import * as TasksApi from "../../network/personal-tasks-api";
import { PersonalTaskInput } from "../../network/personal-tasks-api";
import CalendarInputField from "./form/CalendarInputField";
import TextInputField from "./form/TextInputField";

interface EditPersonalTaskDialogProps {
    role: string,
    taskToEdit: PersonalTask,
    onDismiss: () => void,
    onTaskSaved: (Task: PersonalTask) => void,
}


const EditPersonalTaskDialog = ({role, taskToEdit, onDismiss, onTaskSaved}: EditPersonalTaskDialogProps) => {
    const [userLoading, setUserLoading] = useState(true);
    const [showUserLoadingError, setShowUserLoadingError] = useState(false);
    
    const { register, handleSubmit, setValue, formState : {errors, isSubmitting} } = useForm<PersonalTaskInput>({
        defaultValues: {
            userId: taskToEdit?.userId,
            status: taskToEdit?.status,
            title: taskToEdit?.title,
            text: taskToEdit?.text,
            deadline: taskToEdit?.deadline,
        }
    });

    useEffect(() => {
        async function loadUser() {
          try {
            setShowUserLoadingError(false);
            setUserLoading(true);

          } catch (error) {
            console.error(error);
            setShowUserLoadingError(true);
          } finally {
            setUserLoading(false);
          }
        }
        loadUser();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    async function onSubmit(input:PersonalTaskInput) {
        try {
            let TaskResponse: PersonalTask;
            if (taskToEdit) {
                TaskResponse = await TasksApi.updatePersonalTask(taskToEdit._id, input);
            } else {
                TaskResponse = await TasksApi.createPersonalTask(input);
            }
            onTaskSaved(TaskResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return ( 
        <Modal show onHide={onDismiss}>
            {userLoading && <Spinner animation='border' variant='primary' />}
            {showUserLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!userLoading && !showUserLoadingError && 
                <>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Редактировать задачу
                    </Modal.Title>
                </Modal.Header>

                <ModalBody>
                    <Form id="addEditTaskForm" onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField 
                            name="title"
                            label="Название"
                            type="text"
                            placeholder="Название"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.title}
                        />

                        <TextInputField 
                            name="text"
                            label="Описание"
                            as="textarea"
                            rows={3}
                            placeholder="Описание"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.title}
                        />

                        <CalendarInputField
                            name="deadline"
                            label="Дедлайн"
                            placeholder=""
                            register={register}
                            setValue={setValue}
                            registerOptions={{required: "Required"}}
                            error={errors.title}
                            date={taskToEdit?.deadline}
                        />
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button
                    type="submit"
                    form="addEditTaskForm"
                    disabled={isSubmitting}
                    >
                        Сохранить
                    </Button>
                </ModalFooter>
                </>
            }
        </Modal>
     );
}
 
export default EditPersonalTaskDialog;