import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { Region as RegionModel } from "../../../models/region";
import * as RegionsApi from "../../../network/regions-api";
import RegionCard from "./RegionCard";
import styles from "../../../styles/ChooseRegions.module.css";

interface ChooseRegionsProps {
    onDismiss: (array: string[]) => void,
}

const ChooseRegions = ({onDismiss}: ChooseRegionsProps) => {
    const [regions, setRegions] = useState<RegionModel[]>([]);
    const [regionsLoading, setRegionsLoading] = useState(true);
    const [showRegionsLoadingError, setShowRegionsLoadingError] = useState(false);

    const [regionsId, setRegionsId] = useState<string[]>([]);

    useEffect(() => {
        async function loadRegions() {
        try {
            setShowRegionsLoadingError(false);
            setRegionsLoading(true);
            const regions = await RegionsApi.fetchRegions();
            setRegions(regions);
        } catch (error) {
            console.error(error);
            setShowRegionsLoadingError(true);
        } finally {
            setRegionsLoading(false);
        }
        }
        loadRegions();
    }, []);

    const AddPopRegionId = (region: RegionModel) => {
        if (regionsId.includes(region._id)) {
            setRegionsId(regionsId.filter(regionId =>regionId !== region._id));
        } else {
            setRegionsId([...regionsId, region._id]);
        }
    }

    const regionsGrid = 
        <Row xs={1} md={1} xl={1} className={`g-4`}>
          {regions.map(region => (
            <Col key={region._id}>
              <RegionCard
                region={region}
                chosenRegionsId={regionsId}
                className={styles.region}
                onRegionClicked={AddPopRegionId}
                />
            </Col>
          ))}
        </Row>

    return(
        <Card className={styles.chooseRegions}>
            <Card.Header>
                Выбор регионов
            </Card.Header>
            <Card.Body className={styles.chooseRegionsBody}>
            {regionsLoading && <Spinner animation='border' variant='primary' />}
            {showRegionsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!regionsLoading && !showRegionsLoadingError &&
                <>
                    <Button className="mb-3" onClick={() => {setRegionsId(regions.map((region): string => region._id))}}>
                        Выбрать все
                    </Button>
                    <div className="mb-3">
                        {regions.length > 0 ?
                        regionsGrid
                        : "Вы не добавили регионы"}
                    </div>
                    <Button onClick={() => onDismiss(regionsId)}>Подтвердить выбор</Button>
                </>
            }
            </Card.Body>
        </Card>
    )
}

export default ChooseRegions;