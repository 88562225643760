import { ProjectFourthStage } from "../models/project-fourth-stage";
import { fetchFile, fetchData } from "../utils/fetchData";

interface fetchProjectFourthStageProps{
    projectId: string,
}

export async function fetchProjectFourthStage(requestProps: fetchProjectFourthStageProps): Promise<ProjectFourthStage> {
    const response = await fetchData("/api/project-fourth-stages/"+requestProps.projectId, { method: "GET" });
    return response.json();
}

export async function fetchProjectFourthStageFile(requestProps: fetchProjectFourthStageProps){
    await fetchFile("/api/project-fourth-stages/"+requestProps.projectId+"/file/", { method: "GET" });
}

interface postProjectFourthStageProps{
    projectId: string,
    status: string,
    text: string,
    file: File,
}

export async function postProjectFourthStage(requestProps: postProjectFourthStageProps): Promise<ProjectFourthStage> {
    const formData = new FormData();
    formData.append("projectId", requestProps.projectId);
    formData.append("status", requestProps.status);
    formData.append("text", requestProps.text);
    formData.append("projectfourthstage", requestProps.file);
    const response = await fetchData("/api/project-fourth-stages/",
    {
        method: "POST",
        body: formData,
    });
    return response.json();
}

interface updateProjectFourthStageStatusProps{
    status: string
}

export async function updateProjectFourthStageStatus(requestProps: updateProjectFourthStageStatusProps, projectId: string): Promise<ProjectFourthStage> {
    const response = await fetchData("/api/project-fourth-stages/"+projectId,
    {
        method: "PATCH",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(requestProps),
    });
    return response.json();
}